import { useAuthContext } from 'context/AuthContext'
import Axios from 'axios'
import { useAxiosContext } from 'context/AxiosContext'
import QueryBuilder from './QueryBuilder'
import RequestCancelError from './RequestCancelError'
import useCancelation from './useCancelRequests'

type SearchParams = {
    question: string
    page: number
    order: string
    expiration: string
    pollType: string
}

export default function usePollsApi() {
    const http = useAxiosContext()
    const searchRequests = useCancelation()

    const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(
        navigator.userAgent
    )
    const authCtx = useAuthContext()

    const search = async (params: SearchParams) => {
        try {
            const builder = new QueryBuilder()

            searchRequests.cancelAll()

            builder.addSimple('question', params.question)
            builder.addSimple('page', params.page)
            builder.addSimple('order', params.order)
            builder.addSimple('expiration', params.expiration)
            builder.addSimple('type', params.pollType)

            const source = searchRequests.addSource()

            if (authCtx.getAuthenticated()) {
                const res = await http.client.get(
                    `/search/authenticated${builder.query.value}`,
                    {
                        cancelToken: source.token
                    }
                )

                return res.data
            } else {
                if (!isBot) {
                    const res = await http.client.get(
                        `/search${builder.query.value}`,
                        {
                            cancelToken: source.token
                        }
                    )

                    return res.data
                } else {
                    return {
                        entities: [],
                        totalCount: 0
                    }
                }
            }
        } catch (e) {
            if (Axios.isCancel(e)) {
                throw new RequestCancelError('search')
            } else {
                throw e
            }
        }
    }

    return {
        search,
        cancelAllSearchRequests: searchRequests.cancelAll
    }
}
