import Paths from 'pages/Paths'
import { PageMetaDataProps } from 'shared/components/PageMetaData'

export default function useMetaData(): PageMetaDataProps {
    return {
        h1: `Начало`,
        title: `Начало | Anketi.bg`,
        metas: [
            {
                name: 'description',
                content: `Сайт за отворени анкетни проучвания по важни обществени въпроси. Гласувай! Твоето мнение има значение!`
            },
            {
                name: 'og:url',
                content: `https://anketi.bg${Paths.home()}`
            },
            {
                name: 'og:title',
                content: `Начало | Anketi.bg`
            }
        ],
        links: [
            {
                rel: 'canonical',
                href: `https://anketi.bg${Paths.home()}`
            }
        ]
    }
}
