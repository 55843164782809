import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Rating } from '@material-ui/lab'
import { useStateIfMounted } from 'use-state-if-mounted'
import LoadingIndicator from '../LoadingIndicator'
import { Divider, Typography } from '@material-ui/core'
import { useThemeContext } from 'context/ThemeContext'
import { Star } from '@material-ui/icons'

type Props = {
    votesCount: number
    votersCount: number
    hasVoted: boolean
    voting: boolean
    selectionType: string
    hiddenResult: boolean
    isActive: boolean
    rating: number
    ownRating: number | undefined
    detailedRating: number[]
    isPublished: boolean
    vote: (rating: number) => void
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative'
    },
    ratingRoot: {
        fontSize: '50px'
    },
    starIconDetailed: {
        color: theme.palette.type === 'dark' ? '#FFDF00' : '#D4AF37'
    },
    starIconOverral: {
        color: theme.palette.type === 'dark' ? '#FFDF00' : '#D4AF37',
        fontSize: '30px'
    },
    typoOverral: {
        fontSize: '2rem'
    },
    typoArrow: {
        fontSize: '1.2rem'
    },
    votePromptLabel: {
        color:
            theme.palette.type === 'dark'
                ? theme.palette.info.light
                : theme.palette.info.dark
    }
}))

export default function RatingVoteControl(props: Props) {
    const disabled =
        props.voting || !props.isActive || props.hasVoted || !props.isPublished

    const { theme } = useThemeContext()
    const [votingRating, setVotingRating] = useStateIfMounted<number | null>(
        null
    )

    useEffect(() => {
        if (!props.voting) {
            setVotingRating(null)
        }
    }, [props.voting])

    const classes = useStyles()

    const onChangeRatingInput = (e: any, rating: number | null) => {
        setVotingRating(rating)
        if (rating !== null) {
            props.vote(rating)
        }
    }

    return (
        <Box className={classes.wrapper}>
            <LoadingIndicator loading={props.voting} top={1} />
            <Box mt={1} />
            <Box
                my={1}
                display='flex'
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems='center'
            >
                <Rating
                    classes={{
                        root: classes.ratingRoot
                    }}
                    name='rating'
                    disabled={disabled}
                    size='large'
                    value={votingRating || props.ownRating || 0}
                    onChange={onChangeRatingInput}
                />
                <div>
                    <Typography
                        display='inline'
                        color='textSecondary'
                        className={classes.typoArrow}
                    >
                        &#8593;&nbsp;
                    </Typography>
                    <Typography
                        variant='body2'
                        color='textSecondary'
                        display='inline'
                    >
                        {props.hasVoted
                            ? `Гласувахте с ${props.ownRating} от 5`
                            : 'Гласувай тук'}
                    </Typography>
                    <Typography
                        display='inline'
                        color='textSecondary'
                        className={classes.typoArrow}
                    >
                        &nbsp;&#8593;
                    </Typography>
                </div>
            </Box>
            <Divider />
            <Box
                display='flex'
                justifyContent={'center'}
                alignItems='center'
                mt={1}
            >
                <Box
                    display='flex'
                    justifyContent={'center'}
                    alignItems='center'
                >
                    <Box
                        display={props.hiddenResult ? 'none' : 'inline'}
                        mr={'2px'}
                        style={{ fontSize: 0 }}
                    >
                        <Star
                            fontSize='small'
                            className={classes.starIconOverral}
                        />
                    </Box>
                    {!props.hiddenResult && (
                        <Typography className={classes.typoOverral}>
                            {props.rating.toFixed(1)} / 5
                        </Typography>
                    )}
                    {props.hiddenResult && (
                        <Box height='48px'>
                            <Typography
                                variant='h6'
                                className={classes.votePromptLabel}
                            >
                                Гласувай, за да видиш оценката!
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box my={1}>
                {props.detailedRating.map((value, index) => {
                    const starsCount = index + 1
                    const tmp = new Array(starsCount).fill(1)

                    const starIcons = tmp.map((x, i) => <StarIcon key={i} />)
                    return (
                        <Box
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            key={index}
                        >
                            <Box>{starIcons}</Box>
                            {!props.hiddenResult && (
                                <Typography color='textSecondary'>
                                    {value} гласа
                                </Typography>
                            )}
                            {props.hiddenResult && (
                                <Typography color='textSecondary'>
                                    Х гласа
                                </Typography>
                            )}
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

const StarIcon = () => {
    const classes = useStyles()
    return <Star className={classes.starIconDetailed} />
}
