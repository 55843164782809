import React, {
    PropsWithChildren,
    useContext,
    useEffect,
    useState
} from 'react'
import { useHistory } from 'react-router-dom'
import { useAxiosContext } from 'context/AxiosContext'
import Paths from 'pages/Paths'
import { getCookie, removeCookie, setCookie } from 'typescript-cookie'
import useAuthenticationApi from 'api/useAuthenticationApi'

type AuthContext = {
    isLoading: boolean
    login: () => void
    logout: () => Promise<any>
    init: (fbAccessToken?: string) => void
    getAuthenticated: () => boolean
    getAdmin: () => boolean
    isAuthenticated: boolean
}

export function useAuthContext(): AuthContext {
    const context = useContext(AuthContext)
    if (context === undefined) {
        throw new Error('useAuth must be within AuthProvider')
    }

    return context
}

const AuthContext = React.createContext<AuthContext | undefined>(undefined)

export { AuthContext }

export const AuthProvider = (props: PropsWithChildren<{}>) => {
    const [isLoading, setLoading] = useState(true)
    const { axios, client } = useAxiosContext()
    const history = useHistory()
    const authenticationApi = useAuthenticationApi()
    const [isAuthenticated, setAuthenticated] = useState<boolean>(
        getCookie('Authenticated') === '+'
    )

    const logoutFb = () => {
        return new Promise((resolve, reject) => {
            FB.getLoginStatus((res) => {
                setAuthenticated(false)
                if (res.status === 'connected') {
                    FB.logout((res: any) => {
                        resolve(true)
                    })
                } else {
                    resolve(true)
                }
            })
        })
    }

    const logout = () => {
        return new Promise((resolve, reject) => {
            const finishLogout = () => {
                setAuthenticated(false)
                removeCookie('Admin')
                removeCookie('Authenticated')
                removeCookie('UserName')
                removeCookie('UserId')
                removeCookie('X-Access-Token')
                // removeCookie('LocalConnectRequested')
                removeCookie('FbIntegration')
                resolve(undefined)
            }

            Promise.all([logoutFb(), authenticationApi.logout()]).then(() =>
                finishLogout()
            )
        })
    }

    const login = () => {
        setAuthenticated(true)
    }

    const getAdmin = () => {
        return getCookie('Admin') === '+'
    }

    const init = async (fbAccessToken?: string) => {
        const cookieAuthenticated = getCookie('Authenticated')

        if (
            /bot|googlebot|crawler|spider|robot|crawling/i.test(
                navigator.userAgent
            )
        ) {
            setLoading(false)
        } else if (cookieAuthenticated === '+') {
            login()
            setLoading(false)
        } else {
            logout()
            setLoading(false)
        }
    }

    const errorResponseInterceptor = async (error: any) => {
        if (
            error?.response?.status === 401 &&
            error?.response?.data?.code === 'InvalidActionKey'
        ) {
            history.push(Paths.invalidActionKey())
            return Promise.reject(error)
        }

        if (
            error?.response?.status === 401 ||
            error?.response?.status === 500
        ) {
            // If status is 500 it could happens when user is loggen-in in 2 or more browsers,
            // then delete account from 1 browser and in the other browsers is still signed-in.
            // Fix it in the future.
            return logout().then(() => {
                history.push(Paths.login())
                return Promise.reject(error)
            })
        } else {
            return Promise.reject(error)
        }
    }

    useEffect(() => {
        axios.interceptors.response.use((res) => res, errorResponseInterceptor)
    }, [])

    const getAuthenticated = () => {
        return getCookie('Authenticated') === '+'
    }

    return (
        <AuthContext.Provider
            value={{
                isLoading,
                login,
                logout,
                init,
                getAuthenticated,
                getAdmin,
                isAuthenticated
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}
