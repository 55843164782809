import React from 'react'
import PollOptionDto from 'shared/types/PollOptionDto'
import OptionsVoteControl from './OptionsVoteControl'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PollDto from 'shared/types/PollDto'
import { Container } from '@material-ui/core'
import RatingVoteControl from './RatingVoteControl'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        margin: '0'
    },
    sortButton: {},
    buttons: {},
    divider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    buttonWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

export type PollControlProps = {
    voting: boolean
    poll: PollDto
    savingOption: boolean
    optionError: string
    disabledMaxWidth?: boolean
    onVoteForOption: (pollId: string, optionId: string) => void
    onVoteForRating: (pollId: string, rating: number) => void
    onSaveOption: (pollId: string, optionValue: string) => void
    onClearOptionError: () => void
}

export default function PollControl(props: PollControlProps) {
    const classes = useStyles()

    const onSaveOption = (option: string) => {
        props.onSaveOption(props.poll.id, option)
    }

    const onVoteOption = (option: PollOptionDto) => {
        props.onVoteForOption(props.poll.id, option.id)
    }
    const onVoteRating = (rating: number) => {
        props.onVoteForRating(props.poll.id, rating)
    }

    return (
        <Container disableGutters className={classes.wrapper}>
            {props.poll.type === 'rating' ? (
                <RatingVoteControl
                    hasVoted={props.poll.hasVoted}
                    hiddenResult={props.poll.hiddenResult}
                    isActive={props.poll.isActive}
                    selectionType='rating'
                    vote={onVoteRating}
                    votersCount={props.poll.votersCount}
                    votesCount={props.poll.votesCount}
                    voting={props.voting}
                    rating={props.poll.rating}
                    isPublished={props.poll.status === 'published'}
                    ownRating={props.poll.ownVotes[0]?.rating}
                    detailedRating={props.poll.detailedRating}
                />
            ) : (
                <OptionsVoteControl
                    hiddenResult={props.poll.hiddenResult}
                    pollType={props.poll.type}
                    dynamicOptionsEnabled={props.poll.dynamicOptionsEnabled}
                    optionError={props.optionError}
                    clearOptionError={props.onClearOptionError}
                    savingOption={props.savingOption}
                    saveOption={onSaveOption}
                    options={props.poll.options}
                    voting={props.voting}
                    isActive={props.poll.isActive}
                    isPublished={props.poll.status === 'published'}
                    votesCount={props.poll.votesCount}
                    votersCount={props.poll.votersCount}
                    vote={onVoteOption}
                    hasVoted={props.poll.hasVoted}
                />
            )}
        </Container>
    )
}
