import React from 'react'
import PeopleIcon from '@material-ui/icons/People'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useThemeContext } from 'context/ThemeContext'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        justifyContent: (props: any) => getJustify(props.align),
        alignItems: 'center',
        color: theme.palette.text.secondary,
        zIndex: 1,
        position: 'relative'
    },
    label: {
        fontSize: '1.2rem'
        // fontWeight: 900
    }
}))

const getJustify = (align: string) => {
    switch (align) {
        case 'left':
            return 'flex-start'
        case 'right':
            return 'flex-end'
        default:
            return 'center'
    }
}

type Props = {
    votersCount: number
    align?: 'left' | 'right' | 'center'
}

export default function PollVotersCount(props: Props) {
    const classes = useStyles({
        align: props.align
    })

    const { theme } = useThemeContext()
    const value = props.votersCount

    return (
        <Box className={classes.wrapper}>
            <Box
                color={
                    theme.palette.type === 'dark'
                        ? theme.palette.success.dark
                        : theme.palette.success.light
                }
                lineHeight='0px'
            >
                <PeopleIcon fontSize='small' color='inherit' />
            </Box>
            &nbsp;
            <Typography
                className={classes.label}
                variant='body1'
                color='textPrimary'
            >
                {value}
            </Typography>
        </Box>
    )
}
