import { useAxiosContext } from 'context/AxiosContext'

export default function useProfileApi() {
    const http = useAxiosContext()

    const get = async () => {
        return http.client.get(`/Profile`).then((res) => res.data)
    }

    return {
        get
    }
}
