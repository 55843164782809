import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import FacebookIcon from '@material-ui/icons/Facebook'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useHistory, useLocation } from 'react-router-dom'
import Paths from 'pages/Paths'
import Environment from 'utils/environment/Environment'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        minWidth: '100%',
        margin: '0 auto',
        backgroundColor: '#4267b1',
        height: '40px',
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingTop: '25px',
        paddingBottom: '25px',
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // color: theme.palette.primary.main,
        // backgroundColor: 'transparent',
        boxShadow: 'none',
        outline: 'none',
        '&:hover': {
            // backgroundColor: 'transparent',
            boxShadow: 'none',
            cursor: 'pointer',
            outline: 'none'
        },
        '&:active': {
            // backgroundColor: 'transparent',
            boxShadow: 'none',
            outline: 'none'
        },
        '&:focus': {
            boxShadow: 'none',
            outline: 'none'
        }
    }
}))

type Props = {
    onClick: () => void
    disabled: boolean
    fullWidth?: boolean
}

export default function FacebookLoginButton(props: Props) {
    const classes = useStyles()

    const onClick = () => {
        if (!props.disabled) {
            props.onClick()
        }
    }

    return (
        <button
            onClick={onClick}
            className={classes.wrapper}
            // width={props.fullWidth ? '100%' : '225px'}
        >
            <Box
                mr='10px'
                display='inline'
                fontSize='35px'
                lineHeight='0px'
                color='white'
            >
                <FacebookIcon fontSize='inherit' />
            </Box>
            <Box fontSize='16px' fontWeight='600' color='white'>
                Влез чрез Facebook
            </Box>
        </button>
    )
}
