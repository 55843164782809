import React, { useState } from 'react'
import { useDevice } from 'shared/hooks/useDevice'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Order from '../containers/Order'
import Expiration from '../containers/Expiration'
import { Button, Container } from '@material-ui/core'

export default function Filters() {
    const device = useDevice()

    if (device.largeScreen) {
        return (
            <Box mt={'8px'}>
                <Grid container spacing={0} justifyContent='center'>
                    <Grid item xs={6}>
                        <Box display='flex' justifyContent='flex-start'>
                            <Order />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display='flex' justifyContent='flex-end'>
                            <Expiration />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    } else if (device.mediumScreen) {
        return (
            <Box width='600px' margin='0 auto' mt={'5px'}>
                <Grid container spacing={0} justifyContent='center'>
                    <Grid item xs={6}>
                        <Order />
                    </Grid>
                    <Grid item xs={6}>
                        <Expiration />
                    </Grid>
                </Grid>
            </Box>
        )
    } else {
        return (
            <Container maxWidth='xs' disableGutters>
                <Box display='flex' justifyContent='space-between' mb='3px'>
                    <Box width='35%' display='flex' justifyContent='flex-start'>
                        <Order />
                    </Box>
                    <Box width='35%' display='flex' justifyContent='flex-end'>
                        <Expiration />
                    </Box>
                </Box>
            </Container>
        )
    }
}
