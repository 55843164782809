import usePollOptionsApi from 'api/usePollOptionsApi'
import { useAuthContext } from 'context/AuthContext'
import Paths from 'pages/Paths'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useAddOptionService from 'shared/hooks/useAddOption'
import usePollModal from 'shared/hooks/usePollModal'
import useVoting from 'shared/hooks/useVoting'
import PollDto from 'shared/types/PollDto'
import { useStateIfMounted } from 'use-state-if-mounted'
import PageContext from './PageContextType'
import usePollsService, { PollsState } from './services/usePollsServices'
import useQueryService, { PollSearchParams } from './services/useQueryService'
import MathF from 'utils/MathF'
import ReactGA from 'react-ga4'

export default function usePage(): PageContext {
    const history = useHistory()
    const authCtx = useAuthContext()
    const pollModal = usePollModal()
    const pollOptionsApi = usePollOptionsApi()
    // We need another flag 'voting' because votingService track only vote request.
    const [voting, setVoting] = useStateIfMounted(false)
    const [savingOption, setSavingOption] = useStateIfMounted(false)
    const votingService = useVoting()
    const addOptionService = useAddOptionService(pollModal.poll?.options || [])

    const queryService = useQueryService(
        // on change search params
        (searchParams: PollSearchParams) => {
            pollsService.download(
                searchParams.question,
                searchParams.order,
                searchParams.expiration,
                searchParams.page,
                searchParams.type
            )
        },
        // on clear search params
        () => {
            pollsService.clearState()
        }
    )

    const pollsService = usePollsService()

    const voteOption = useCallback(
        async (pollId: string, optionId: string) => {
            if (!authCtx.getAuthenticated()) {
                return history.push(Paths.login())
            }
            setVoting(true)
            if (pollModal.poll) {
                await votingService.voteOption(pollModal.poll.id, optionId)
                ReactGA.event('search poll vote')
                const entitiesData = await pollsService.download(
                    queryService.query.question,
                    queryService.query.order,
                    queryService.query.expiration,
                    queryService.query.page,
                    queryService.query.type
                )
                let modalPoll = entitiesData.entities.find((x: any) => {
                    return x.id === pollModal.poll?.id
                })

                if (modalPoll) {
                    pollModal.open(modalPoll)
                } else {
                    // Sometimes when vote for poll and then download the page again the might be not in the page.
                    const modalPoll = await pollsService.downloadSingle(
                        pollModal.poll.id
                    )
                    pollModal.open(modalPoll)
                }

                setVoting(false)
            }
        },
        [
            authCtx,
            history,
            setVoting,
            pollModal,
            votingService,
            pollsService,
            queryService
        ]
    )

    const voteRating = useCallback(
        async (pollId: string, rating: number) => {
            if (!authCtx.getAuthenticated()) {
                return history.push(Paths.login())
            }
            setVoting(true)
            if (pollModal.poll) {
                await votingService.voteRating(pollModal.poll.id, rating)
                ReactGA.event('search rating vote')
                const entitiesData = await pollsService.download(
                    queryService.query.question,
                    queryService.query.order,
                    queryService.query.expiration,
                    queryService.query.page,
                    queryService.query.type
                )
                let modalPoll = entitiesData.entities.find((x: any) => {
                    return x.id === pollModal.poll?.id
                })

                if (modalPoll) {
                    pollModal.open(modalPoll)
                } else {
                    // Sometimes when vote for poll and then download the page again the might be not in the page.
                    const modalPoll = await pollsService.downloadSingle(
                        pollModal.poll.id
                    )
                    pollModal.open(modalPoll)
                }

                setVoting(false)
            }
        },
        [
            authCtx,
            pollModal,
            votingService,
            pollsService,
            queryService,
            setVoting
        ]
    )

    const savePollOption = useCallback(
        (pollId: string, optionValue: string) => {
            if (pollModal.poll) {
                setSavingOption(true)
                addOptionService
                    .save(pollModal.poll?.id, optionValue)
                    .then((optionId) => {
                        if (optionId) {
                            ReactGA.event('search page - save poll option')
                            pollOptionsApi.getById(optionId).then((data) => {
                                setSavingOption(false)
                                const pollFromEntities = pollsService.state.polls?.find(
                                    (x) => x.id === pollModal.poll?.id
                                )
                                pollFromEntities?.options.push(data)
                                pollModal.open({
                                    ...(pollFromEntities as PollDto)
                                })
                            })
                        }
                    })
                    .catch((e) => {
                        if (e.clientAddOptionError) {
                            setSavingOption(false)
                        }
                    })
            }
        },
        [
            pollModal,
            setSavingOption,
            addOptionService,
            pollOptionsApi,
            pollsService
        ]
    )

    const initialSearch = useCallback(() => {
        pollsService.download(
            queryService.query.question,
            queryService.query.order,
            queryService.query.expiration,
            queryService.query.page,
            queryService.query.type
        )
    }, [pollsService, queryService])

    const changeType = useCallback(
        (value: string) => {
            queryService.changeType(value)
        },
        [queryService]
    )

    useEffect(() => {
        initialSearch()
    }, [])

    return {
        pollTypes: {
            disabled: pollsService.state.loading,
            value: queryService.query.type,
            changePollType: changeType
        },
        layout: {
            anyLoaded: pollsService.state.polls !== null,
            loading: pollsService.state.loading
        },
        order: {
            value: queryService.query.order,
            disabled: pollsService.state.loading,
            change: queryService.changeOrder
        },
        expiration: {
            value: queryService.query.expiration,
            disabled: pollsService.state.loading,
            change: queryService.changeExpiration
        },
        searchField: {
            disabled: pollsService.state.loading,
            search: queryService.changeQuestion
        },
        pollModal: {
            opened: pollModal.active,
            voting: voting,
            poll: pollModal.poll || (null as any),
            optionError: addOptionService.error,
            savingOption: savingOption,
            onClearOptionError: addOptionService.clearError,
            onSaveOption: savePollOption,
            onVoteForOption: voteOption,
            open: pollModal.open,
            close: pollModal.close,
            onVoteForRating: voteRating
        },
        pollsList: {
            polls: pollsService.state.polls || [],
            pollsLoading: pollsService.state.polls === null,
            shortcutActionHandler: pollModal.open
        },
        paginator: {
            page: queryService.query.page,
            pagesCount: pollsService.state.totalCount
                ? MathF.getPagesCount(pollsService.state.totalCount, 12)
                : 0,
            polls: pollsService.state.polls,
            hide: pollsService.state.loading,
            onChangePage: queryService.changePage
        }
    }
}
