export default function useMetaData() {
    return {
        h1: `Анкета`,
        title: `Анкета | Anketi.bg`,
        metas: [
            {
                name: 'description',
                content: `Гласувай анонимно и виж резултатите. Разгледай и други подобни анкети.`
            },
            {
                name: 'og:url',
                content: `https://anketi.bg`
            },
            {
                name: 'og:title',
                content: `Анкета | Anketi.bg`
            }
        ],
        links: [
            {
                rel: 'canonical',
                href: `https://anketi.bg`
            }
        ]
    }
}
