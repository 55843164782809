import React, { useEffect, lazy, Suspense } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import PrivateRoute from './shared/components/PrivateRoute'
import Search from './pages/Search'
import Login from './pages/Login'
import Register from './pages/Register'
import Footer from './Footer'
import RestrictedRoute from './shared/components/RestrictedRoute'
import Paths from './pages/Paths'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Container from '@material-ui/core/Container'
import useGaTracker from 'shared/hooks/useGaTracker'
import Navigation from 'Navigation'
import Drawer from 'Drawer'
import Home from 'pages/Home'
import Profile from 'pages/Profile'
import Poll from 'pages/Poll'
import Snowfall from 'react-snowfall'
import { useThemeContext } from 'context/ThemeContext'
import { useDevice } from 'shared/hooks/useDevice'
import LoadingIndicator from 'shared/components/LoadingIndicator'

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        maxWidth: '100%'
    }
}))

export default function AppRouter() {
    useGaTracker()

    const EmbeddedPoll = React.lazy(() => import('./pages/EmbeddedPoll'))
    // const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(
    //     navigator.userAgent
    // )
    return (
        <Switch>
            <Route path={Paths.embeddedPoll()} exact>
                <Suspense fallback=''>
                    <EmbeddedPoll />
                </Suspense>
            </Route>
            <Route>
                {/* {!isBot && (
                    <CookieConsent
                        style={{
                            textAlign: 'left',
                            zIndex: themeCtx.theme.zIndex.drawer + 1
                        }}
                        buttonText='Разбирам'
                    >
                        Този сайт използва бисквитки. Използвайки този сайт, вие
                        се съгласявате да използвате бисквитки в съответствие с
                        текущите настройки на браузъра.
                    </CookieConsent>
                )} */}
                <MainAppRouting />
            </Route>
        </Switch>
    )
}

const MainAppRouting = () => {
    const classes = useStyles()

    const themeCtx = useThemeContext()

    const AdminDiagnostic = React.lazy(() =>
        import('./pages/admin/AdminMonitoring')
    )
    const Admin = React.lazy(() => import('./pages/admin/Admin'))
    const Privacy = React.lazy(() => import('./pages/Privacy'))
    const About = React.lazy(() => import('./pages/About'))
    const CreatePoll = React.lazy(() => import('./pages/CreatePoll'))
    const AdminEditor = React.lazy(() => import('./pages/admin/AdminEditor'))
    const AdminPolls = React.lazy(() => import('./pages/admin/AdminPolls'))
    const AdminUsers = React.lazy(() => import('./pages/admin/AdminUsers'))
    const ResetPassword = React.lazy(() => import('./pages/ResetPassword'))
    const ConfirmEmail = React.lazy(() => import('./pages/ConfirmEmail'))
    const EmailConfirmationSent = React.lazy(() =>
        import('./pages/EmailConfirmationSent')
    )
    const Activity = React.lazy(() => import('./pages/Activity'))
    const Policy = React.lazy(() => import('./pages/Terms'))
    const EditPoll = React.lazy(() => import('./pages/EditPoll'))
    const EmbedEditor = React.lazy(() => import('./pages/EmbedEditor'))
    const ForgottenPassword = React.lazy(() =>
        import('./pages/ForgottenPassword')
    )
    const TMPAnketiRoute = React.lazy(() => import('./pages/TMPAnketiRoute'))
    const InvalidActionKey = React.lazy(() =>
        import('./pages/InvalidActionKey')
    )
    const Error404 = React.lazy(() => import('./pages/404'))
    const Error500 = React.lazy(() => import('./pages/500'))
    const device = useDevice()
    return (
        <>
            {/* {themeCtx.isSeasonalTheme && (
                <Snowfall
                    snowflakeCount={device.smallScreen ? 13 : 30}
                    style={{
                        position: 'fixed',
                        width: '100vw',
                        height: '100vh',
                        zIndex: 999999
                    }}
                />
            )} */}
            <Navigation />
            <Drawer />
            <Suspense fallback={<div></div>}>
                <Container
                    // maxWidth='lg'
                    className={classes.container}
                    component='main'
                >
                    <Switch>
                        <Route path={Paths.poll()} exact>
                            <Poll />
                        </Route>
                        <Route path={Paths.admin.admin()} exact>
                            <Admin />
                        </Route>
                        <Route path={Paths.admin.monitoring()} exact>
                            <AdminDiagnostic />
                        </Route>
                        <Route path={Paths.admin.editor('')} exact>
                            <AdminEditor />
                        </Route>
                        <Route path={Paths.admin.editor()} exact>
                            <AdminEditor />
                        </Route>
                        <Route path={Paths.admin.polls()} exact>
                            <AdminPolls />
                        </Route>
                        <Route path={Paths.admin.users()} exact>
                            <AdminUsers />
                        </Route>
                        <Route path={Paths.home()} exact>
                            <Home />
                        </Route>
                        <Route path={Paths.about()} exact>
                            <About />
                        </Route>
                        <Route path={'/anketi'} exact>
                            <TMPAnketiRoute />
                        </Route>
                        <Route path={Paths.embedEditor()} exact>
                            <EmbedEditor />
                        </Route>
                        <Route path={Paths.randomPollRedirection()} exact>
                            <Redirect
                                to={{
                                    pathname: Paths.poll(),
                                    state: {
                                        randomPoll: true
                                    }
                                }}
                            />
                        </Route>
                        <Route path={Paths.search()} exact>
                            <Search />
                        </Route>
                        <Route path={Paths.invalidActionKey()} exact>
                            <InvalidActionKey />
                        </Route>
                        <Route path={'/anketi/:id'} exact>
                            <Poll />
                        </Route>
                        <Route path={Paths.error500()} exact>
                            <Error500 />
                        </Route>
                        <Route path={Paths.privacy()} exact>
                            <Privacy />
                        </Route>
                        <Route path={Paths.terms()} exact>
                            <Policy />
                        </Route>
                        <Route path={Paths.create()} exact>
                            <CreatePoll />
                        </Route>
                        {['voted', 'created'].map(
                            (value: string, index: number) => (
                                <PrivateRoute
                                    key={value}
                                    path={
                                        value === 'voted'
                                            ? Paths.votedPolls()
                                            : Paths.createdPolls()
                                    }
                                    exact
                                >
                                    <Activity type={value} />
                                </PrivateRoute>
                            )
                        )}
                        <Route path={Paths.confirmEmail()} exact>
                            <ConfirmEmail />
                        </Route>
                        <PrivateRoute path={Paths.profile()} exact>
                            <Profile />
                        </PrivateRoute>
                        <PrivateRoute path={Paths.edit()} exact>
                            <EditPoll />
                        </PrivateRoute>
                        <RestrictedRoute path={Paths.login()} exact>
                            <Login />
                        </RestrictedRoute>
                        <RestrictedRoute path={Paths.register()} exact>
                            <Register />
                        </RestrictedRoute>
                        <RestrictedRoute path={Paths.forgottenPassword()} exact>
                            <ForgottenPassword />
                        </RestrictedRoute>
                        <RestrictedRoute path={Paths.passwordRecovery()} exact>
                            <ResetPassword />
                        </RestrictedRoute>
                        <RestrictedRoute
                            path={Paths.emailConfirmationSent()}
                            exact
                        >
                            <EmailConfirmationSent />
                        </RestrictedRoute>
                        <Route path={Paths.error404()}>
                            <Error404 />
                        </Route>
                        <Redirect to={Paths.error404()} />
                    </Switch>
                </Container>
                <Footer />
            </Suspense>
        </>
    )
}
