import { Theme } from '@material-ui/core/styles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { PuffLoader } from 'react-spinners'
import { css } from '@emotion/react'
import { useThemeContext } from 'context/ThemeContext'

type Props = {
    loading: boolean
    size?: number
    top?: number
    zIndex?: number
}

export default function LoadingIndicator(props: Props) {
    const themeCtx = useThemeContext()
    const override = css`
        position: absolute;
        top: ${props.top || 150}px;
        left: 50%;
        transform: translateX(-50%);
        z-index: ${props.zIndex || 999999};
    `

    return (
        <PuffLoader
            loading={props.loading}
            size={props.size || 80}
            color={themeCtx.theme.palette.primary.main}
            css={override}
        />
    )
}
