import useVotesApi from 'api/useVotesApi'
import { useStateIfMounted } from 'use-state-if-mounted'

export default function useVoting() {
    const votesApi = useVotesApi()
    const [voting, setVoting] = useStateIfMounted(false)

    const voteOption = async (pollId: string, optionId: string) => {
        setVoting(true)
        return await votesApi.voteOption(pollId, optionId).then((res) => {
            setVoting(false)
            return res
        })
    }

    const voteRating = async (pollId: string, rating: number) => {
        setVoting(true)
        return await votesApi.voteRating(pollId, rating).then((res) => {
            setVoting(false)
            return res
        })
    }

    return {
        voting,
        voteOption,
        voteRating
    }
}
