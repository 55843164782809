import React from 'react'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Button from '@material-ui/core/Button'

type Props = {
    onClick: () => void
    disabled: boolean
}

export default function AddButton(props: Props) {
    return (
        <Button
            disabled={props.disabled}
            onClick={props.onClick}
            size='small'
            startIcon={<AddCircleOutlineIcon fontSize='large' />}
        >
            Добави опция
        </Button>
    )
}
