import { Button, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Paths from 'pages/Paths'
import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

type Props = {
    entities: any[]
    renderItem: (itemProps: any, index?: number) => React.ReactElement
    noResultsText?: string
}

function MainPollsList({ entities, renderItem, noResultsText }: Props) {
    const history = useHistory()

    const onClickRandomPoll = useCallback(() => {
        history.push({
            pathname: Paths.poll(''),
            state: {
                randomPoll: true
            }
        })
    }, [])

    if (entities.length > 0) {
        const items = new Array<React.ReactElement>()
        for (let i = 0; i < entities.length; i++) {
            items.push(
                <Box width='100%' key={i} mb={1}>
                    {renderItem(entities[i], i)}
                </Box>
            )
        }

        return (
            <Box display='flex' flexDirection='column' alignItems='center'>
                {items}
            </Box>
        )
    } else {
        if (noResultsText) {
            return (
                <Box mb={2}>
                    <Typography color='textSecondary'>
                        {noResultsText}
                    </Typography>
                </Box>
            )
        } else {
            return (
                <Box mb={2}>
                    <Box my={2}>
                        <Typography color='textSecondary'>
                            Няма намерени анкети
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Button
                            size='large'
                            variant='outlined'
                            onClick={onClickRandomPoll}
                        >
                            Произволна анкета
                        </Button>
                    </Box>
                </Box>
            )
        }
    }
}

export default MainPollsList
