import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useThemeContext } from 'context/ThemeContext'
import { useDevice } from 'shared/hooks/useDevice'
import Box from '@material-ui/core/Box'
import Paths from 'pages/Paths'
import LogoImageDark from 'images/full-logo-dark-theme.png'
import LogoImageLight from 'images/full-logo-light-theme.png'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: (theme.palette.background as any)['drawer']
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: (props: any) => 'none',
            textDecorationColor: (props: any) => props.color
        }
    },
    typo: {
        fontFamily: 'audiowide',
        textDecoration: 'none',
        color: (props: any) => props.color,
        fontWeight: 900,
        fontSize: '2rem',
        position: 'relative'
    },
    icon: {},
    iconWrapper: {
        fontSize: 0,
        marginRight: '0.3px',
        marginTop: '5px'
    },
    imgTypo: {
        display: 'flex',
        alignItems: 'center'
    }
}))

type Props = {}

export default function LogoLink(props: Props) {
    const device = useDevice()
    const themeCtx = useThemeContext()

    const largeDisplay = device.largeScreen

    const color = themeCtx.isDarkTheme
        ? themeCtx.lightTheme.palette.background.paper
        : themeCtx.darkTheme.palette.background.paper

    const classes = useStyles({ color, largeDisplay })

    return (
        <Box display='span' marginRight={'8px'} position={'relative'}>
            <NavLink to={Paths.home()} className={classes.link}>
                <div className={classes.imgTypo}>
                    <Box className={classes.iconWrapper} zIndex={6}>
                        <img
                            className={classes.icon}
                            src={
                                themeCtx.isDarkTheme
                                    ? LogoImageDark
                                    : LogoImageLight
                            }
                            width='200px'
                            height='60px'
                            alt={'Logo'}
                        />
                    </Box>
                </div>
            </NavLink>
        </Box>
    )
}
