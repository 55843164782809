import { useAuthContext } from 'context/AuthContext'
import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useState
} from 'react'

type LoginModalContext = {
    open: (text: string, severity?: string) => void
    close: () => void
    text: string
    opened: boolean
    severity: string
}

const Context = createContext<LoginModalContext | undefined>(undefined)

export function useSnackbarContext(): LoginModalContext {
    const context = useContext(Context)
    if (context === undefined) {
        throw new Error('Context must be within provider')
    }

    return context
}

export function SnackbarProvider(props: PropsWithChildren<{}>) {
    const [state, setState] = useState({
        text: '',
        opened: false,
        severity: 'success'
    })

    const open = (text: string, severity?: string) => {
        setState({
            text,
            opened: true,
            severity: severity || 'success'
        })
    }

    const close = () => {
        setState({
            text: '',
            opened: false,
            severity: 'success'
        })
    }

    return (
        <Context.Provider
            value={{
                open: open,
                close,
                text: state.text,
                opened: state.opened,
                severity: state.severity
            }}
        >
            {props.children}
        </Context.Provider>
    )
}
