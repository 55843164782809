import { Paper, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import {
    LineChart,
    Line,
    XAxis,
    CartesianGrid,
    Tooltip as RechartsTooltip,
    ResponsiveContainer
} from 'recharts'
import Tooltip from './Tooltip'
import { demographyLabel, transform } from '../transform'
import VotePrompt from './VotePrompt'
import TypeYouDemographics from './TypeYouDemographics'

type Props = {
    data: any
    label: string
    hasVoted: boolean
    demographicsUnspecified: boolean
    xLabels: string[]
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(1),
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        border: () => {
            let color
            if (theme.palette.type === 'dark') {
                color = '#228B22'
            } else {
                color = '#00FF7F'
            }

            return `1px solid ${color}`
        },
        position: 'relative'
    }
}))

export default function Chart(props: Props) {
    const classes = useStyles()

    let data = []
    let options: any[] = []

    if (props.data) {
        data = transform(props.data)
        options = Object.keys(data[0])
        options = options.filter((x: any) => x !== 'name')
    } else {
        data = props.xLabels.map((label) => {
            return {
                name: demographyLabel(label)
            }
        })
    }

    const colors = options.reduce((acc, curr, i) => {
        acc[curr] = colorsArr[i]
        return acc
    }, {})

    return (
        <Paper className={classes.wrapper} elevation={0}>
            <Typography variant='h6' component='h2' gutterBottom>
                <strong>Графика на {props.label}</strong>
            </Typography>
            {!props.hasVoted && <VotePrompt />}
            {props.hasVoted && !props.demographicsUnspecified && (
                <TypeYouDemographics label={props.label} />
            )}
            <ResponsiveContainer width={'100%'} height={300}>
                <LineChart
                    data={data}
                    margin={{
                        top: 10,
                        right: 10,
                        left: 10,
                        bottom: 10
                    }}
                >
                    <CartesianGrid />
                    <XAxis dataKey='name' interval='preserveStartEnd' />
                    <RechartsTooltip
                        content={(itemsProps: any) => {
                            return <Tooltip {...itemsProps} colors={colors} />
                        }}
                    />
                    {options.map((option: any, i) => {
                        return (
                            <Line
                                key={i}
                                type='monotone'
                                dataKey={option}
                                activeDot={{ r: 8 }}
                                stroke={colors[option]}
                                strokeWidth={2}
                            />
                        )
                    })}
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    )
}

const colorsArr: string[] = [
    '#FF0000', // Red
    '#0000FF', // Blue
    '#00FF00', // Lime Green
    '#800080', // Purple
    '#FF00FF', // Magenta
    '#FFFF00', // Yellow
    '#00FFFF', // Cyan
    '#FF8000' // Orange
]
