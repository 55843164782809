import React from 'react'
import PollDto from 'shared/types/PollDto'
import LoadingIndicator from 'shared/components/LoadingIndicator'
import { useDevice } from 'shared/hooks/useDevice'
import PollWarningLabel from 'shared/components/PollWarningLabel'
import Poll, { PollProps } from './components/Poll'
import PollBackground from './components/PollBackground'
import { PollState } from './components/Poll'

export type ViewState = Omit<PollState, 'poll'> & { poll: PollDto | null }
type PollViewProps = Omit<PollProps, 'poll' | 'addBottomDivider'> & {
    poll: PollDto | null
}

export default function View(props: PollViewProps) {
    const device = useDevice()

    const poll = props.poll

    if (poll) {
        return (
            <>
                <PollWarningLabel
                    isExpired={!poll.isActive}
                    status={poll.status}
                />
                {!device.smallScreen ? (
                    <PollBackground>
                        <Poll {...props} poll={poll} />
                    </PollBackground>
                ) : (
                    <Poll {...props} poll={poll} addBottomDivider={true} />
                )}
            </>
        )
    } else {
        return <LoadingIndicator loading={true} top={100} />
    }
}
