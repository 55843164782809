import React from 'react'
import PaginatorCmp, {
    PaginatorProps as PaginatorPropsCmp
} from 'shared/components/Paginator'
import PollDto from 'shared/types/PollDto'

export type PaginatorProps = {
    hide: boolean
    polls: PollDto[] | null
} & PaginatorPropsCmp

export default function Paginator(props: PaginatorProps) {
    if (!props.hide) {
        return <PaginatorCmp {...props} size='medium' />
    }
    return null
}
