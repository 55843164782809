import { useAxiosContext } from 'context/AxiosContext'

export default function useDemographicsApi() {
    const http = useAxiosContext()

    const getDemographics = async (pollId: string) => {
        const res = await http.client.get(`/demographics/${pollId}`)
        return res.data
    }

    const setMyDemographics = async (
        age: string,
        location: string,
        education: string
    ) => {
        const res = await http.client.post(`/demographics`, {
            age,
            location,
            education
        })
        return res.data
    }

    return { getDemographics, setMyDemographics }
}
