import React, { useEffect } from 'react'
import 'styles/App.css'
import AppRouter from './AppRouter'
import { useAuthContext } from 'context/AuthContext'
import { useDevice } from 'shared/hooks/useDevice'
import Box from '@material-ui/core/Box'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import LoginModal from 'shared/components/LoginModal'
import AppSnackbar from 'shared/components/AppSnackbar'
import useUpdateAuthenticationApi from 'api/useUpdateAuthenticationApi'

import * as Sentry from '@sentry/react'

Sentry.init({
    dsn:
        'https://461819647ef1af8092e66c76d6030177@o4506840677482496.ingest.us.sentry.io/4506894062518272',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
        })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://anketi.bg'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

type Props = {
    fbAccessToken?: string
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh'
    }
}))

// All answers are here!
function App(props: Props) {
    const authCtx = useAuthContext()
    const device = useDevice()
    const appLoading = authCtx.isLoading
    const classes = useStyles()
    const tokensApi = useUpdateAuthenticationApi()
    const location = useLocation()

    useEffect(() => {
        window.scroll(0, 0)
    }, [location.pathname])

    useEffect(() => {
        authCtx.init(props.fbAccessToken)
        console.log('REACT_APP_ENV_NAME', process.env.REACT_APP_ENV_NAME)
        // if (
        //     getCookie('LocalConnectRequested') &&
        //     getCookie('LoginType') === 'tmp' &&
        //     !location.pathname.includes(Paths.confirmEmail())
        // ) {
        //     tokensApi.updateAuthentication().then(() => {
        //         removeCookie('LocalConnectRequested')
        //     })
        // }
    }, [])

    if (!appLoading) {
        return (
            <div className='App'>
                <Box className={classes.container}>
                    <AppRouter />
                    <LoginModal />
                    <AppSnackbar />
                </Box>
            </div>
        )
    } else return null

    // FONT: AUDIOWIDE
}

export default App

// const particlesInit = useCallback(async (engine: Engine) => {
//     await loadFull(engine)
// }, [])

// const particlesLoaded = useCallback(
//     async (container: Container | undefined) => {
//         // await console.log(container)
//     },
//     []
// )

{
    /* <ParticlesBg type='circle' bg={true} /> */
}
{
    /* <Particles
                    id='tsparticles'
                    options={{
                        background: {
                            color: {
                                value: themeCtx.theme.palette.background.default
                            }
                        },
                        fpsLimit: 120,
                        particles: {
                            color: {
                                value: themeCtx.theme.palette.primary.main
                            },
                            links: {
                                triangles: {
                                    enable: false,
                                    color: themeCtx.theme.palette.primary.main
                                },
                                color: themeCtx.theme.palette.primary.main,
                                distance: device.smallScreen ? 500 : 220,
                                enable: true,
                                opacity: 0.2,
                                width: 3
                            },
                            collisions: {
                                enable: false
                            },
                            move: {
                                direction: 'none',
                                enable: true,
                                outModes: {
                                    default: 'bounce'
                                },
                                random: false,
                                speed: 0.25,
                                straight: true
                            },
                            number: {
                                density: {
                                    enable: false,
                                    area: 800
                                },
                                value: device.smallScreen ? 10 : 40
                            },
                            opacity: {
                                value: 1
                            },
                            shape: {
                                type: 'bubble'
                            },
                            size: {
                                value: { min: 2, max: 3 }
                            }
                        },
                        detectRetina: true
                    }}
                    init={particlesInit}
                    loaded={particlesLoaded}
                /> */
}
