import useAuthenticationApi from 'api/useAuthenticationApi'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { getCookie, removeCookie, setCookie } from 'typescript-cookie'
import { useStateIfMounted } from 'use-state-if-mounted'
import Environment from 'utils/environment/Environment'

type Props = {
    onComplete: (redirectionData?: any) => void
    onError: (err: any) => void
    redirectPath: string
    state: string
}

export default function useFacebook(props: Props) {
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const authenticationApi = useAuthenticationApi()
    const [submiting, setSubmiting] = useStateIfMounted(false)

    const onComplete = () => {
        setSubmiting(false)
        props.onComplete()
    }

    const onError = (err: any) => {
        setSubmiting(false)
        props.onError(err)
    }

    const getSubmiting = () => {
        return (
            getCookie('FbIntegration') === 'Connecting' ||
            getCookie('FbIntegration') === 'Loging'
        )
    }

    useEffect(() => {
        const code = query.get('code')
        const state = query.get('state')
        const fbIntegration = getCookie('FbIntegration')
        if (
            code !== null &&
            (fbIntegration === 'Connecting' || fbIntegration === 'Loging')
        ) {
            setSubmiting(true)

            const method =
                fbIntegration === 'Connecting'
                    ? authenticationApi.facebookConnectWithCode
                    : authenticationApi.facebookLoginWithCode

            method(code, props.redirectPath)
                .then(() => {
                    setCookie('FbIntegration', undefined)
                    removeCookie('FbIntegration')
                    return onComplete()
                })
                .catch((err) => {
                    setCookie('FbIntegration', undefined)
                    removeCookie('FbIntegration')

                    return onError(err)
                })
        }
    }, [])

    const connect = () => {
        setCookie('FbIntegration', 'Connecting')

        let redirectUri = `${Environment.getSiteUrl()}${props.redirectPath}`

        if (Environment.isDevelopment()) {
            redirectUri = 'https://' + redirectUri
        }

        const url = `https://www.facebook.com/v14.0/dialog/oauth?client_id=${process.env.REACT_APP_FB_ID}&redirect_uri=${redirectUri}&scope=email&state=${props.state}`

        window.location.href = url
    }

    const login = () => {
        const now = new Date()
        let milliseconds = 8 * 1000
        setCookie('FbIntegration', 'Loging', {
            expires: new Date(now.getTime() + milliseconds)
        })

        let redirectUri = `${Environment.getSiteUrl()}${props.redirectPath}`

        if (Environment.isDevelopment()) {
            redirectUri = 'https://' + redirectUri
        }

        const url = `https://www.facebook.com/v14.0/dialog/oauth?client_id=${process.env.REACT_APP_FB_ID}&redirect_uri=${redirectUri}&scope=email&state=${props.state}`

        window.location.href = url
    }

    return {
        login,
        connect,
        getSubmiting,
        submiting
    }
}
