import Paths from 'pages/Paths'
import { PageMetaDataProps } from 'shared/components/PageMetaData'

export default function useMetaData(): PageMetaDataProps {
    return {
        h1: `Профил`,
        title: `Профил | Anketi.bg`,
        metas: [
            {
                name: 'description',
                content: `Потребителски профил`
            },
            {
                name: 'og:url',
                content: `https://anketi.bg${Paths.profile()}`
            },
            {
                name: 'og:title',
                content: `Потребител | Anketi.bg`
            },
            {
                name: 'keywords',
                content: 'anketi.bg,потребител'
            }
        ],
        links: [
            {
                rel: 'canonical',
                href: `https://anketi.bg${Paths.profile()}`
            }
        ]
    }
}
