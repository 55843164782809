import Axios, { CancelTokenSource } from 'axios'
import { useStateIfMounted } from 'use-state-if-mounted'

export default function useCancelation() {
    const [sources, setSources] = useStateIfMounted<CancelTokenSource[]>([])

    const addRequest = () => {
        const source = Axios.CancelToken.source()
        setSources([...sources, source])
        return source
    }

    const cancelRequests = () => {
        for (const source of sources) {
            source.cancel()
        }

        setSources([])
    }

    return {
        addSource: addRequest,
        cancelAll: cancelRequests
    }
}
