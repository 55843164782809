import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Api } from 'shared/hooks/useApi'
import { ViewState } from './View'
import { AppDispatch, GetState } from 'redux/store'

const initialState: ViewState = {
    myAge: null,
    myEducation: null,
    myLocation: null,
    loading: false,
    chartAge: null,
    chartLocation: null,
    chartEducation: null,
    ageLastModification: null,
    locationLastModification: null,
    educationLastModification: null,
    ageChangeAllowed: false,
    locationChangeAllowed: false,
    educationChangeAllowed: false,
    hasVoted: false
}

const slice = createSlice({
    name: 'DemographicsReducer',
    initialState,
    reducers: {
        reset: () => initialState,
        setState: (state, action: PayloadAction<ViewState>) => action.payload
    }
})


export default slice
