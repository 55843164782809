import { Button, CardActionArea, Chip, Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Paths from 'pages/Paths'
import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PollDto from 'shared/types/PollDto'
import PollVotersCount from '../PollVotersCount'
import DoneIcon from '@material-ui/icons/Done'

const useStyles = makeStyles((theme) => ({
    card: {
        position: 'relative',
        overflow: 'visible',
        backgroundColor: 'transparent',
        border: () => {
            let color
            if (theme.palette.type === 'dark') {
                color = '#228B22'
            } else {
                color = '#00FF7F'
            }

            return `1px solid ${color}`
        }
    },
    cardBackground: {
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        opacity: '0.6',
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        zIndex: 0
    },
    cardActionArea: {
        padding: theme.spacing(2),
        paddingTop: (props: any) => {
            return props.hasAdditional ? theme.spacing(1) : theme.spacing(1)
        },
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0)
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
        },
        borderRadius: theme.shape.borderRadius,
        zIndex: 4
    },
    question: {
        textAlign: 'center',
        fontSize: '1.14rem',
        lineHeight: '1.3'
    },
    totalVotesWrapper: {
        position: 'absolute',
        bottom: 8,
        right: 16,
        zIndex: 4
    },
    expiredLabel: {
        color: theme.palette.error.main
    },
    expirationLabel: {
        whiteSpace: 'nowrap',
        color: theme.palette.success.main
    },
    checkIcon: {
        position: 'absolute',
        bottom: 4,
        left: '50%',
        marginLeft: '-20px',
        color: theme.palette.success.main
    },
    additional: {
        marginBottom: '5px'
    }
}))

type Props = {
    poll: PollDto
    shortcutAction?: {
        handler: (poll: PollDto) => void
        icon: React.ReactNode
        label: string
    }
    topAdditional?: React.ReactNode
    midAdditional?: React.ReactNode
}

export default function PollCard(props: Props) {
    const classes = useStyles({
        voted: props.poll.hasVoted,
        hasAdditional: !!props.topAdditional
    })

    const history = useHistory()

    const onClickCard = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        history.push(Paths.poll(props.poll.id))
    }, [])

    const onClickShortcut = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (props.shortcutAction) {
            props.shortcutAction.handler(props.poll as any)
        }
    }, [])

    return (
        <Card className={classes.card} elevation={0}>
            <Box className={classes.cardBackground} />
            <CardActionArea
                className={classes.cardActionArea}
                component='a'
                href={Paths.poll(props.poll.id)}
                onClick={onClickCard}
            >
                {props.topAdditional && (
                    <Box className={classes.additional}>
                        {props.topAdditional}
                    </Box>
                )}
                <Typography
                    className={classes.question}
                    variant='h6'
                    component='h2'
                >
                    {props.poll.question}
                    {/* <strong>
                        While not normally known for his musical talent, Elon
                        Musk is releasing a debut album this Friday as part of a
                        promotional effort for his rockets. Musk has been one of
                        the key figures in the private space launch company
                        SpaceX. He is also the founder and CEO of Tesla Motors,
                        a
                    </strong> */}
                </Typography>
                <Box
                    mt={1}
                    display='flex'
                    justifyContent={'space-between'}
                    alignItems='flex-end'
                >
                    <PollVotersCount votersCount={props.poll.votersCount} />
                    {props.shortcutAction && (
                        <Button
                            onTouchStart={(event) => event.stopPropagation()}
                            onMouseDown={(event) => event.stopPropagation()}
                            onMouseOver={(event) => event.stopPropagation()}
                            onClick={onClickShortcut}
                            size='small'
                            variant='outlined'
                            disableElevation
                            startIcon={props.shortcutAction.icon}
                        >
                            {props.shortcutAction.label}
                        </Button>
                    )}
                </Box>
                {props.poll.hasVoted && (
                    <Box className={classes.checkIcon}>
                        <DoneIcon />
                    </Box>
                )}
            </CardActionArea>
        </Card>
    )
}
