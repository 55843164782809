import React from 'react'
import { makeStyles } from '@material-ui/core'
import DemographicsForm from './components/DemographicsForm'
import LoadingIndicator from 'shared/components/LoadingIndicator'
import Chart from './components/Chart'
import PollDto from 'shared/types/PollDto'
import { ages, educations, locations } from './constants'

type Props = ViewState & {
    onSubmit: (age: string, location: string, education: string) => void
    poll: PollDto | null
}

export type ViewState = {
    myAge: any
    myLocation: any
    myEducation: any
    loading: boolean
    chartAge: string | null
    chartLocation: string | null
    chartEducation: string | null
    isAuthenticated?: boolean
    ageLastModification: Date | null
    locationLastModification: Date | null
    educationLastModification: Date | null
    ageChangeAllowed: boolean
    locationChangeAllowed: boolean
    educationChangeAllowed: boolean
    hasVoted: boolean
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
        minHeight: '400px'
    }
}))

export default function View(props: Props) {
    const classes = useStyles()

    return (
        <div className={classes.wrapper}>
            {!props.loading && (
                <>
                    <DemographicsForm
                        onSave={props.onSubmit}
                        age={props.myAge}
                        location={props.myLocation}
                        education={props.myEducation}
                        authenticated={props.isAuthenticated || false}
                        ageChangeAllowed={props.ageChangeAllowed}
                        locationChangeAllowed={props.locationChangeAllowed}
                        educationChangeAllowed={props.educationChangeAllowed}
                        hint={
                            (props.isAuthenticated && props.hasVoted) ||
                            (props.myAge !== 'unspecified' &&
                                props.myAge !== null)
                                ? 'Всяко поле може да се променя през 30 мин. най-малко'
                                : ''
                        }
                        hintColor={'primary'}
                    />
                    <Chart
                        data={props.chartAge}
                        label='Възраст'
                        xLabels={ages}
                        hasVoted={props.hasVoted}
                        demographicsUnspecified={
                            props.myAge !== 'unspecified' &&
                            props.myAge !== null
                        }
                    />
                    <Chart
                        data={props.chartLocation}
                        label='Местоживеене'
                        xLabels={locations}
                        hasVoted={props.hasVoted}
                        demographicsUnspecified={
                            props.myLocation !== 'unspecified' &&
                            props.myLocation !== null
                        }
                    />
                    <Chart
                        data={props.chartEducation}
                        label='Образование'
                        xLabels={educations}
                        hasVoted={props.hasVoted}
                        demographicsUnspecified={
                            props.myEducation !== 'unspecified' &&
                            props.myEducation !== null
                        }
                    />
                </>
            )}
            <LoadingIndicator loading={props.loading} top={40} size={40} />
        </div>
    )
}
