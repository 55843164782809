function initFbSdk() {
    return new Promise<string | undefined>((resolve) => {
        const windowAsAny = window as any

        windowAsAny.fbAsyncInit = function () {
            window.FB.init({
                appId: process.env.REACT_APP_FB_ID,
                xfbml: false,
                version: 'v14.0'
            })

            FB.getLoginStatus((res) => {
                if (res.authResponse) {
                    resolve(res.authResponse.accessToken)
                } else {
                    resolve(undefined)
                }
            })
        }

        // load facebook sdk script
        const f = function (d: any, s: any, id: any) {
            var js: any,
                fjs: any = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) {
                return
            }
            js = d.createElement(s)
            js.id = id
            js.src = `https://connect.facebook.net/bg_BG/sdk.js#xfbml=1&version=v19.0&appId=${process.env.REACT_APP_FB_ID}&autoLogAppEvents=1`
            fjs.parentNode.insertBefore(js, fjs)
        }

        f(document, 'script', 'facebook-jssdk')
    })
}

export default initFbSdk
