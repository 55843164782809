import React from 'react'
import PollDto from 'shared/types/PollDto'
import MainPollsList from 'shared/components/MainPollsList'
import { usePageContext } from '../containers/usePageContext'
import PollCard from 'shared/components/PollCard'
import PollIconCustom from 'shared/components/PollIcon'
import StarIconCustom from 'shared/components/StarIcon'

export type PollsListProps = {
    polls: PollDto[]
    pollsLoading: boolean
    shortcutActionHandler: (poll: PollDto) => void
}

export default function PollsList(props: PollsListProps) {
    if (props.pollsLoading) {
        return null
    }

    return (
        <MainPollsList
            data-test='main_grid'
            entities={props.polls}
            renderItem={(poll: PollDto) => (
                <PollCard
                    shortcutAction={{
                        label: poll.hasVoted ? 'Резултати' : 'Гласувай',
                        icon:
                            poll.type === 'rating' ? (
                                <StarIconCustom voted={poll.hasVoted} />
                            ) : (
                                <PollIconCustom voted={poll.hasVoted} />
                            ),
                        handler: props.shortcutActionHandler
                    }}
                    key={poll.id}
                    poll={poll}
                />
            )}
        />
    )
}
