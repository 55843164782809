import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useState
} from 'react'

type DrawerContext = {
    open: boolean
    toggle: () => void
}

const Context = createContext<DrawerContext | undefined>(undefined)

export function useDrawerContext(): DrawerContext {
    const context = useContext(Context)
    if (context === undefined) {
        throw new Error('Context must be within provider')
    }

    return context
}

export function DrawerProvider(props: PropsWithChildren<{}>) {
    const [open, setOpen] = useState(false)

    const toggle = () => {
        setOpen((val) => !val)
    }

    return (
        <Context.Provider value={{ open, toggle }}>
            {props.children}
        </Context.Provider>
    )
}
