import React from 'react'
import Layout from './components/Layout'
import useMetaData from './useMetaData'
import Page from 'shared/components/Page'

export default function Home() {
    const metaData = useMetaData()

    return (
        <>
            <Page
                mt={{
                    smallScreen: '47px',
                    mediumScreen: '62px',
                    largeScreen: '0'
                }}
                px={{
                    smallScreen: '0px'
                }}
                metaData={metaData}
            >
                <Layout />
            </Page>
        </>
    )
}
