import { Paper, Typography, makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'

type Props = {
    label: string
}

const useStyles = makeStyles((theme) => ({
    warningLabelSentence: {
        width: '100%',
        paddingLeft: '10px',
        fontSize: '1.2rem',
        color: () => {
            return 'white'
        }
    },
    warningLabelPaper: {
        backgroundColor: () => {
            if (theme.palette.type === 'dark') {
                return theme.palette.error.dark
            } else {
                return theme.palette.error.light
            }
        },
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 998,
        padding: theme.spacing(2),
        border: () => {
            let color
            if (theme.palette.type === 'dark') {
                color = '#228B22'
            } else {
                color = '#00FF7F'
            }

            return `1px solid ${color}`
        }
    },
    warningLabel: {
        fontSize: '1.2rem'
    }
}))

export default function TypeYouDemographics(props: Props) {
    const classes = useStyles()

    return (
        <Paper className={classes.warningLabelPaper} elevation={8}>
            <Typography
                color='error'
                align='center'
                component='span'
                className={classes.warningLabelSentence}
            >
                Попълни{' '}
                <Typography
                    display='inline'
                    className={classes.warningLabel}
                    color='textSecondary'
                    component='span'
                >
                    <strong>{props.label}</strong>
                </Typography>
                , за да видиш графиката!
            </Typography>
        </Paper>
    )
}
