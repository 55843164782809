import React from 'react'
import {
    Box,
    Link as MuiLink,
    makeStyles,
    Typography,
    TypographyVariant
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'inline'
    },
    typo: {
        display: 'inline',
        fontWeight: (props: any) => props.fontWeight
    }
}))

type Props = {
    label: string
    to: string
    variant?: TypographyVariant
}

function Link(props: Props) {
    const history = useHistory()
    const classes = useStyles({ fontWeight: 500 })
    const variant = props.variant || 'body1'
    const onClick = (e: React.MouseEvent) => {
        e.preventDefault()
        history.push(props.to)
    }

    return (
        <MuiLink href={props.to} onClick={onClick} className={classes.link}>
            <Typography className={classes.typo}>{props.label}</Typography>
        </MuiLink>
    )
}

export default Link
