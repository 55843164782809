import { Container } from '@material-ui/core'
import React from 'react'
import SearchFieldView from '../views/SearchField'
import { usePageContext } from './usePageContext'

export default function SearchField() {
    const ctx = usePageContext()
    return (
        <Container disableGutters maxWidth='sm'>
            <SearchFieldView {...ctx.searchField} />
        </Container>
    )
}
