import { useAxiosContext } from 'context/AxiosContext'

export default function useUpdateAuthenticationApi() {
    const http = useAxiosContext()

    const updateAuthentication = async () => {
        const res = await http.client.post(`/update-authentication`)
        return res.data
    }

    return { updateAuthentication }
}
