import React, { useCallback, useEffect } from 'react'
import {
    Box,
    IconButton,
    ListItemIcon,
    ListSubheader,
    Typography,
    makeStyles
} from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import { useHistory } from 'react-router-dom'
import Paths from 'pages/Paths'
import { useDevice } from 'shared/hooks/useDevice'
import { useDrawerContext } from 'context/DrawerContext'
import { useThemeContext } from 'context/ThemeContext'
import { useAuthContext } from 'context/AuthContext'
import Brightness2Icon from '@material-ui/icons/Brightness2'
import WbSunnyIcon from '@material-ui/icons/WbSunny'
import {
    Poll,
    AccountCircle,
    Create,
    CloseOutlined,
    ArrowForwardRounded
} from '@material-ui/icons'
import { getCookie } from 'typescript-cookie'
import SearchButtons from './components/SearchButtons'
import LogoLink from 'Navigation/components/LogoLink'

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: '250px',
        flexShrink: 0,
        position: 'relative'
    },
    drawerPaper: {
        borderRight: () => {
            let color
            if (theme.palette.type === 'dark') {
                color = '#228B22'
            } else {
                color = '#00FF7F'
            }

            return `1px solid ${color}`
        },
        width: '250px',
        backgroundColor: (theme.palette.background as any)['drawer'],
        zIndex: 998
    },
    onlyMobileItems: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    loginText: {
        fontWeight: 900,
        fontSize: '20px'
    },
    registerText: {
        fontWeight: 900,
        fontSize: '20px'
    },
    list: {
        padding: 0
    },
    listItem: {
        borderRadius: 0
    },
    toolbar: theme.mixins.toolbar,
    iconRandom: {
        color:
            theme.palette.type === 'dark'
                ? theme.palette.info.light
                : theme.palette.info.dark
    },
    iconMyPolls: {
        color:
            theme.palette.type === 'dark'
                ? theme.palette.success.light
                : theme.palette.success.dark
    },
    iconMyVotes: {
        color:
            theme.palette.type === 'dark'
                ? theme.palette.success.light
                : theme.palette.success.dark
    },
    iconSearch: {
        color:
            theme.palette.type === 'dark'
                ? theme.palette.primary.light
                : theme.palette.primary.dark
    },
    iconCreate: {
        color:
            theme.palette.type === 'dark'
                ? theme.palette.secondary.light
                : theme.palette.secondary.dark
    }
}))

type Props = {}

export default function AppDrawer(props: Props) {
    const classes = useStyles()
    const history = useHistory()
    const drawerCtx = useDrawerContext()
    const themeCtx = useThemeContext()
    const device = useDevice()
    const authCtx = useAuthContext()

    useEffect(() => {
        if (drawerCtx.open) {
            drawerCtx.toggle()
        }
    }, [history.location])

    const onClickCreatedPolls = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault()
            drawerCtx.toggle()
            history.push(`${Paths.createdPolls()}`)
        },
        [drawerCtx]
    )

    const onClickVotedPolls = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault()
            drawerCtx.toggle()
            history.push(`${Paths.votedPolls()}`)
        },
        [drawerCtx]
    )

    const onClickCloseDrawer = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault()
            if (drawerCtx.open) {
                drawerCtx.toggle()
            }
        },
        [drawerCtx]
    )

    const onClickLogin = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        history.push(Paths.login())
    }, [])

    const onClickRegister = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        history.push(Paths.register())
    }, [])

    const onClickCreate = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        history.push(Paths.create())
    }, [])

    const onClickProfile = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        history.push(Paths.profile())
    }, [])

    const onClickRandomPoll = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        history.push(Paths.randomPollRedirection())
    }, [])

    const onChangeTheme = useCallback(
        (e: React.MouseEvent, theme: string) => {
            e.preventDefault()
            if (theme === 'light') {
                themeCtx.setDarkThemeActive(false)
            } else if (theme === 'dark') {
                themeCtx.setDarkThemeActive(true)
            } else if (theme === 'seasonal') {
                themeCtx.setSeasonalThemeActive()
            }
        },
        [themeCtx]
    )

    return (
        <>
            <Drawer
                open={drawerCtx.open}
                classes={{ root: classes.drawer, paper: classes.drawerPaper }}
                variant={device.largeScreen ? 'permanent' : 'temporary'}
                anchor='left'
                elevation={16}
                onClose={onClickCloseDrawer}
            >
                <Box
                    width='100%'
                    display='flex'
                    justifyContent={'center'}
                    alignItems={'center'}
                    padding={1}
                >
                    <LogoLink />
                </Box>
                {!device.largeScreen && (
                    <Box width='100%' textAlign='center'>
                        <IconButton size='medium' onClick={onClickCloseDrawer}>
                            <CloseOutlined fontSize='large' />
                        </IconButton>
                    </Box>
                )}
                <List className={classes.list}>
                    {!authCtx.isAuthenticated && (
                        <>
                            <ListItem
                                className={classes.listItem}
                                button
                                onClick={onClickLogin}
                                component='a'
                                href={Paths.login()}
                            >
                                <ListItemIcon>
                                    <ArrowForwardRounded />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            className={classes.loginText}
                                        >
                                            Вход
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem
                                className={classes.listItem}
                                button
                                onClick={onClickRegister}
                                component='a'
                                href={Paths.register()}
                            >
                                <ListItemIcon>
                                    <ArrowForwardRounded />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            className={classes.registerText}
                                        >
                                            Регистрация
                                        </Typography>
                                    }
                                />
                            </ListItem>

                            <Divider />
                        </>
                    )}

                    <List className={classes.list}>
                        <SearchButtons />
                        <ListItem
                            className={classes.listItem}
                            button
                            onClick={onClickRandomPoll}
                            component='a'
                            href={Paths.randomPollRedirection()}
                        >
                            <ListItemIcon>
                                <Poll className={classes.iconRandom} />
                            </ListItemIcon>
                            <ListItemText primary={'Произволна'} />
                        </ListItem>
                        <ListItem
                            className={classes.listItem}
                            disabled={!authCtx.getAuthenticated()}
                            button
                            onClick={onClickVotedPolls}
                            component='a'
                            href={Paths.votedPolls()}
                        >
                            <ListItemIcon>
                                <Poll className={classes.iconMyVotes} />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Моите гласове'}
                                secondary={
                                    !authCtx.getAuthenticated()
                                        ? 'Изисква се акаунт'
                                        : undefined
                                }
                            />
                        </ListItem>
                    </List>
                    <Divider />
                    <ListItem
                        className={classes.listItem}
                        button
                        onClick={onClickCreate}
                        component='a'
                        href={Paths.create()}
                    >
                        <ListItemIcon>
                            <Create className={classes.iconCreate} />
                        </ListItemIcon>
                        <ListItemText primary={'Създай'} />
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        disabled={!authCtx.getAuthenticated()}
                        button
                        onClick={onClickCreatedPolls}
                        component='a'
                        href={Paths.votedPolls()}
                    >
                        <ListItemIcon>
                            <Poll className={classes.iconMyPolls} />
                        </ListItemIcon>
                        <ListItemText
                            primary={'Моите анкети'}
                            secondary={
                                !authCtx.getAuthenticated()
                                    ? 'Изисква се акаунт'
                                    : undefined
                            }
                        />
                    </ListItem>
                    <Divider />
                    {authCtx.getAuthenticated() && (
                        <ListItem
                            className={classes.listItem}
                            button
                            onClick={onClickProfile}
                            component='a'
                            href={Paths.profile()}
                        >
                            <ListItemIcon>
                                <AccountCircle />
                            </ListItemIcon>
                            <ListItemText primary={'Профил'} />
                        </ListItem>
                    )}
                    <Divider />
                    <ListItem
                        className={classes.listItem}
                        button
                        onClick={(e: React.MouseEvent) => {
                            onChangeTheme(e, 'light')
                        }}
                    >
                        <ListItemIcon>
                            <Box
                                color={
                                    themeCtx.isDarkTheme ? 'gold' : 'goldenrod'
                                }
                            >
                                <WbSunnyIcon color='inherit' />
                            </Box>
                        </ListItemIcon>
                        <ListItemText primary={'Светла тема'} />
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        button
                        onClick={(e: React.MouseEvent) => {
                            onChangeTheme(e, 'dark')
                        }}
                    >
                        <ListItemIcon>
                            <Box color={'blue'}>
                                <Brightness2Icon color='inherit' />
                            </Box>
                        </ListItemIcon>
                        <ListItemText primary={'Тъмна тема'} />
                    </ListItem>
                    {/* <ListItem
                        className={classes.listItem}
                        button
                        onClick={(e: React.MouseEvent) => {
                            onChangeTheme(e, 'seasonal')
                        }}
                    >
                        <ListItemIcon>
                            <Box color={'darkgrey'}>
                                <AcUnitIcon color='inherit' />
                            </Box>
                        </ListItemIcon>
                        <ListItemText primary={'Сезонна тема'} />
                    </ListItem> */}
                    {getCookie('Admin') === '+' && (
                        <ListItem
                            className={classes.listItem}
                            button
                            onClick={(e: React.MouseEvent) => {
                                history.push(Paths.admin.admin())
                            }}
                        >
                            <ListItemText primary={'Админ'} />
                        </ListItem>
                    )}
                </List>
            </Drawer>
        </>
    )
}
