import React, { useCallback } from 'react'
import Box from '@material-ui/core/Box'
import {
    Button,
    Divider,
    makeStyles,
    Paper,
    Typography
} from '@material-ui/core'
import Paths from 'pages/Paths'
import { useHistory } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ActivityField from '../components/ActivityField'

export type ActivityProps = {
    votedPollsCount: number
    createdPollsCount: number
    disabled: boolean
}

const useStyles = makeStyles((theme) => {
    return {
        paper: {
            padding: theme.spacing(1),
            border: () => {
                let color
                if (theme.palette.type === 'dark') {
                    color = '#228B22'
                } else {
                    color = '#00FF7F'
                }

                return `1px solid ${color}`
            },
            borderRadius: '8px'
        }
    }
})

export default function Activity(props: ActivityProps) {
    const classes = useStyles()
    const history = useHistory()

    const onClickCreated = useCallback(() => {
        history.push(Paths.createdPolls())
    }, [])

    const onClickVoted = useCallback(() => {
        history.push(Paths.votedPolls())
    }, [])

    return (
        <Paper className={classes.paper} elevation={0}>
            <Typography variant='h5'>
                <Box component='span' fontWeight='500'>
                    Дейност
                </Box>
            </Typography>
            <Divider />
            <Box mt={0}>
                <Box>
                    <Box display='flex' justifyContent='space-between'>
                        <Box>
                            <Box>
                                <Typography variant='h6' align='left'>
                                    Анкети
                                </Typography>
                                <ActivityField
                                    label={'Гласувани'}
                                    value={props.votedPollsCount}
                                />
                                <ActivityField
                                    label={'Създадени'}
                                    value={props.createdPollsCount}
                                />
                            </Box>
                        </Box>
                        <Box
                            display='flex'
                            flexDirection='column'
                            justifyContent='flex-start'
                            mt={1}
                        >
                            <Button
                                variant='text'
                                size='small'
                                onClick={onClickVoted}
                                endIcon={<ChevronRightIcon />}
                                disabled={props.disabled}
                            >
                                Гласувани
                            </Button>
                            <Box mt={0} />
                            <Button
                                variant='text'
                                size='small'
                                onClick={onClickCreated}
                                endIcon={<ChevronRightIcon />}
                                disabled={props.disabled}
                            >
                                Създадени
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}
