import { createTheme } from '@material-ui/core'
import { TypographyOptions } from '@material-ui/core/styles/createTypography'

const typography: TypographyOptions = {
    fontFamily: ['Ariel'].join(',')
    // h1: {
    //     fontSize: '2rem',
    //     textAlign: 'center',
    //     fontWeight: 500
    // },
    // h2: {
    //     fontSize: '1.5rem',
    //     textAlign: 'center',
    //     fontWeight: 500
    // },
    // h3: {
    //     fontSize: '1.17rem',
    //     textAlign: 'center',
    //     fontWeight: 500
    // },
    // h4: {
    //     fontSize: '1rem',
    //     textAlign: 'center',
    //     fontWeight: 500
    // },
    // h5: {
    //     fontSize: '0.83rem',
    //     textAlign: 'center',
    //     fontWeight: 500
    // },
    // h6: {
    //     fontSize: '0.67rem',
    //     textAlign: 'center',
    //     fontWeight: 500
    // }
}

const lightTheme = createTheme({
    palette: {
        background: {
            default: '#d2d3db',
            paper: '#fafafa',
            drawer: '#e4e5f1'
        },
        type: 'light'
    },
    typography
} as any)

const darkTheme = createTheme({
    palette: {
        type: 'dark',
        background: {
            default: '#1e1e1e',
            paper: '#3e3e42',
            drawer: '#2d2d30'
        },
        text: {
            primary: '#F8E8EE',
            secondary: 'rgba(255, 255, 255, 0.6)',
            disabled: 'rgba(255, 255, 255, 0.4)'
        },
        action: {
            active: '#fff',
            hover: 'rgba(255, 255, 255, 0.08)',
            selected: 'rgba(255, 255, 255, 0.16)',
            disabled: 'rgba(255, 255, 255, 0.3)',
            disabledBackground: 'rgba(255, 255, 255, 0.12)'
        },
        divider: 'rgba(255, 255, 255, 0.12)'
    },
    typography
} as any)

const seasonalTheme = createTheme({
    palette: {
        type: 'dark',
        background: {
            default: '#42687C ',
            drawer: '#84A5B8 ',
            paper: '#B3DAF1'
        },
        text: {
            primary: '#fff',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(255, 255, 255, 0.5)'
        },
        action: {
            active: '#fff',
            hover: 'rgba(255, 255, 255, 0.08)',
            selected: 'rgba(255, 255, 255, 0.16)',
            disabled: 'rgba(255, 255, 255, 0.3)',
            disabledBackground: 'rgba(255, 255, 255, 0.12)'
        },
        divider: 'rgba(255, 255, 255, 0.12)'
    },
    typography
} as any)

export { lightTheme, darkTheme, seasonalTheme }
