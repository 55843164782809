import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import LinearProgress from '@material-ui/core/LinearProgress'
import Radio from '@material-ui/core/Radio'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import PollOptionDto from 'shared/types/PollOptionDto'
import { useThemeContext } from 'context/ThemeContext'
import MathF from 'utils/MathF'
import { CheckBox } from '@material-ui/icons'
import Checkbox from '@material-ui/core/Checkbox'
import LoadingIndicator from '../LoadingIndicator'

const useStyles = makeStyles((theme) => ({
    progressRoot: {
        zIndex: 5,
        height: 15,
        borderRadius: theme.shape.borderRadius
    },
    progressBar: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.dark
    },
    progressColorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === 'light' ? 300 : 700]
    },
    resultTypo: {
        color:
            theme.palette.type === 'dark'
                ? theme.palette.info.light
                : theme.palette.info.dark
    },
    titleTypo: {
        marginLeft: '10px'
    },
    wrapper: {
        position: 'relative',
        textAlign: 'left'
    },
    radio: {
        '&&:hover': {
            backgroundColor: 'unset'
        }
    },
    checkbox: {
        '&&:hover': {
            backgroundColor: 'unset'
        }
    },
    formControlLabel: {
        width: '100%'
    }
}))

type Props = {
    option: PollOptionDto
    votesCount: number
    votersCount: number
    disabled: boolean
    loading: boolean
    selectionType: string
    hiddenResult: boolean
    onVote: (option: PollOptionDto) => void
}

export default function Option(props: Props) {
    const themeCtx = useThemeContext()

    const classes = useStyles({
        voted: props.option.hasVoted
    })

    const onClickControlLabel = (e: React.ChangeEvent<{}>) => {
        if (props.disabled) {
            return
        }

        props.onVote(props.option)
    }

    return (
        <>
            <Box position='relative' className={classes.wrapper}>
                <LoadingIndicator loading={props.loading} top={1} />
                <Box
                    display='flex'
                    justifyContent='space-between'
                    position='relative'
                >
                    <FormControlLabel
                        data-test={'option_' + props.option.id}
                        className={classes.formControlLabel}
                        disabled={props.disabled}
                        checked={props.option.hasVoted}
                        control={
                            props.selectionType === 'single-selection' ? (
                                <Radio
                                    className={classes.radio}
                                    disableFocusRipple
                                    disableRipple
                                    disableTouchRipple
                                    size='small'
                                    color='default'
                                />
                            ) : (
                                <Checkbox
                                    className={classes.checkbox}
                                    disableFocusRipple
                                    disableRipple
                                    disableTouchRipple
                                    size='small'
                                    color='default'
                                />
                            )
                        }
                        label={
                            <Typography
                                variant='body1'
                                className={classes.titleTypo}
                            >
                                <strong>{props.option.value}</strong>
                            </Typography>
                        }
                        onClick={onClickControlLabel}
                    />
                </Box>
                <Box>
                    <LinearProgress
                        classes={{
                            root: classes.progressRoot,
                            colorPrimary: classes.progressColorPrimary,
                            bar: classes.progressBar
                        }}
                        variant='buffer'
                        valueBuffer={100}
                        value={
                            props.option.votesCount
                                ? MathF.calcPercentage(
                                      props.option.votesCount,
                                      props.votesCount
                                  )
                                : 0
                        }
                    />
                </Box>
            </Box>
            {!props.hiddenResult && (
                <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Typography variant='body1' className={classes.resultTypo}>
                        <strong>
                            {props.option.votesCount &&
                                (
                                    (props.option.votesCount /
                                        props.votesCount) *
                                    100
                                ).toFixed(1)}
                            %
                        </strong>
                    </Typography>
                    {props.selectionType === 'single-selection' && (
                        <Typography
                            variant='body2'
                            className={classes.resultTypo}
                        >
                            {props.option.votesCount}
                            &nbsp;от&nbsp;
                            {props.votesCount}&nbsp;гласа&nbsp;
                            {/* {props.selectionType === 'multi-selection' &&
                                `| ${props.option.votesCount} от ${props.votersCount} гласували`} */}
                        </Typography>
                    )}
                </Box>
            )}
            {props.hiddenResult && (
                <Box visibility={'hidden'}>
                    <Typography
                        variant='body1'
                        className={`${classes.resultTypo}`}
                    >
                        <strong>Spacer</strong>
                    </Typography>
                </Box>
            )}
        </>
    )
}
