import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthContext } from 'context/AuthContext'
import Paths from 'pages/Paths'
import useAccountApi from 'api/useAccountApi'
import { useStateIfMounted } from 'use-state-if-mounted'
import UserRules from 'server-rules/UserRules'
import DateF from 'utils/DateF'
import useProfileApi from 'api/useProfileApi'
import PageContext from './PageContextType'
import useFacebook from 'shared/hooks/useFacebook'
import { useSnackbarContext } from 'context/SnackbarContext'
import { Cookies } from 'typescript-cookie'

export type ViewState = {
    formPassword: boolean
}

export type Profile = {
    socialLogin: boolean
    createdPollsCount: number
    votedPollsCount: number
    email: string
    registration: Date
    temporaryAccount: boolean
    userId: string
}

export default function usePage(): PageContext {
    const history = useHistory()
    const accountApi = useAccountApi()
    const profileApi = useProfileApi()
    const [profileLoaded, setProfileLoaded] = useStateIfMounted(false)
    const snackbarCtx = useSnackbarContext()
    const [profile, setProfile] = useStateIfMounted<Profile>({
        socialLogin: false,
        votedPollsCount: 0,
        createdPollsCount: 0,
        email: '',
        registration: new Date(),
        temporaryAccount: false,
        userId: ''
    })
    const authCtx = useAuthContext()
    const [viewState, setViewState] = useState<ViewState>({
        formPassword: false
    })
    const [formError, setFormError] = useState<string>('')

    const [deletingUser, setDeletingUser] = useStateIfMounted(false)
    const [saving, setSaving] = useStateIfMounted(false)
    const [loggingOut, setLogginOut] = useStateIfMounted(false)
    const facebookApi = useFacebook({
        state: '',
        onComplete: () => {
            authCtx.login()
            snackbarCtx.open('Успешно се свързахте с Facebook')
            downloadProfile().then((res) => {
                setProfileLoaded(true)
            })
        },
        onError: (err) => {
            if (err?.response?.data?.code === 'EmailExists') {
                // setError('Имейлът е зает')
                snackbarCtx.open('Имейлът е зает', 'error')
            } else if (err?.response?.data?.code === 'EmailMandatory') {
                snackbarCtx.open('Имейлът е задължителен', 'error')
            }
            downloadProfile().then((res) => {
                setProfileLoaded(true)
            })
        },
        redirectPath: Paths.profile()
    })

    useEffect(() => {
        if (!facebookApi.getSubmiting()) {
            downloadProfile().then((res) => {
                setProfileLoaded(true)
            })
        }
    }, [])

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const closeDeleteModal = useCallback(() => {
        setDeleteModalOpen(false)
    }, [])

    const openDeleteModal = useCallback(() => {
        setDeleteModalOpen(true)
    }, [])

    const connectFacebook = useCallback(() => {
        setProfileLoaded(false)
        facebookApi.connect()
    }, [facebookApi])

    const downloadProfile = useCallback(async () => {
        return profileApi.get().then((data) => {
            setProfile({
                ...data,
                temporaryAccount: data.onlyClient
            })
        })
    }, [profileApi, setProfile])

    const logout = useCallback(async () => {
        setLogginOut(true)
        authCtx.logout().then(() => {
            setLogginOut(true)
            history.push(Paths.login())
        })
    }, [setLogginOut, authCtx])

    const deleteUser = useCallback(() => {
        setDeletingUser(true)
        accountApi.deleteMyAccount().then((data) => {
            setDeletingUser(true)
            logout()
        })
    }, [accountApi, setDeletingUser, logout, accountApi])

    const openPassword = useCallback(() => {
        setViewState({
            ...viewState,
            formPassword: true
        })
    }, [])

    const clearFormError = useCallback(() => {
        setFormError('')
    }, [])

    const closeForm = useCallback(() => {
        clearFormError()
        setViewState({
            formPassword: false
        })
    }, [])

    const savePassword = useCallback(
        (
            currentPassword: string,
            newPassword: string,
            newPassword2: string
        ) => {
            if (newPassword !== newPassword2) {
                setFormError('Полетата с нова парола не съвпадат')
                return
            }

            if (
                newPassword.length < UserRules.PasswordMinLength ||
                newPassword2.length < UserRules.PasswordMinLength ||
                currentPassword.length < UserRules.PasswordMinLength
            ) {
                setFormError(
                    `Паролата трябва да бъде минимум ${UserRules.PasswordMinLength} символа`
                )
                return
            } else if (
                newPassword.length > UserRules.PasswordMaxLength ||
                newPassword2.length > UserRules.PasswordMaxLength ||
                currentPassword.length > UserRules.PasswordMaxLength
            ) {
                setFormError(
                    `Паролата трябва да бъде максимум ${UserRules.PasswordMaxLength} символа`
                )
                return
            }

            setSaving(true)

            accountApi
                .changePassword(currentPassword, newPassword, newPassword2)
                .then((data) => {
                    setSaving(false)
                    setViewState({
                        ...viewState,
                        formPassword: false
                    })
                    clearFormError()
                    closeForm()
                })
                .catch((err) => {
                    setSaving(false)
                    if (err.response?.data?.code === 'PasswordMismatch') {
                        setFormError('Грешна текуща парола')
                    }
                })
        },
        [accountApi]
    )

    return {
        modalDeleteAccount: {
            deleting: deletingUser,
            opened: deleteModalOpen,
            onClose: closeDeleteModal,
            onDeleteAccount: deleteUser
        },
        completeAccount: {
            buttonDisabled: !profileLoaded,
            onClickConnectFacebook: connectFacebook
        },
        account: {
            isTemporaryAccount: profile.temporaryAccount,
            edit: viewState.formPassword
        },
        activity: {
            createdPollsCount: profile.createdPollsCount,
            votedPollsCount: profile.votedPollsCount,
            disabled: false
        },
        layout: {
            userId: profile.userId,
            pageLoading: !profileLoaded,
            deletingUser,
            loggingOut,
            onLogout: logout,
            onOpenDeleteModal: openDeleteModal,
            temporaryAccount: profile.temporaryAccount
        },
        accountEditMode: {
            saving,
            error: formError,
            onSave: savePassword,
            onClearError: clearFormError,
            onClose: closeForm
        },
        accountViewMode: {
            email: profile.email,
            disabled: false,
            isTemporaryAccount: profile.temporaryAccount,
            registration: profile.registration.toString(),
            isSocialLogin:
                Cookies.get('LoginType') === 'google' ||
                Cookies.get('LoginType') === 'fb',
            onOpenPassword: openPassword
        }
    }
}
