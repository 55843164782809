import useMediaQuery from '@material-ui/core/useMediaQuery'
import { detectIncognito } from 'detectincognitojs'
import { useEffect } from 'react'
import { useStateIfMounted } from 'use-state-if-mounted'

type Browser = {
    browserName: string
    private: boolean
    browserCheckReady: boolean
}

export function useDevice() {
    const [browser, setBrowser] = useStateIfMounted<Browser>({
        browserName: '',
        private: false,
        browserCheckReady: false
    })

    useEffect(() => {
        detectIncognito().then((result) => {
            setBrowser({
                browserCheckReady: true,
                browserName: result.browserName,
                private: result.isPrivate
            })
        })
    }, [])

    let largeScreen: boolean = useMediaQuery('(min-width:960px)', {
        noSsr: true
    })
    let mediumScreen: boolean = useMediaQuery('(min-width:600px)', {
        noSsr: true
    })
    let smallScreen: boolean = useMediaQuery('(min-width:0px)', { noSsr: true })

    const touchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0

    if (largeScreen) {
        mediumScreen = false
        smallScreen = false
    } else if (mediumScreen) {
        smallScreen = false
    }

    let screenSize: 'small' | 'medium' | 'large' | null = null

    if (largeScreen) screenSize = 'large'
    else if (mediumScreen) screenSize = 'medium'
    else if (smallScreen) screenSize = 'small'

    return {
        largeScreen,
        mediumScreen,
        smallScreen,
        screenSize,
        touchScreen,
        browser
    }
}
