import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'
import { Star } from '@material-ui/icons'
import Paths from 'pages/Paths'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex'
        // justifyContent: 'space-between'
    },
    contentWrapper: {
        display: 'inline-flex',
        flexDirection: 'column'
    },
    row: {
        textAlign: 'left',
        paddingBottom: '3px',
        paddingTop: '3px',
        listStyleType: 'none',
        '&:not(:first-child)': {
            // marginTop: '8px'
        },
        display: 'inline-flex',
        alignItems: 'center',

        [theme.breakpoints.up('sm')]: {
            marginRight: '10px'
        }
    },
    rowTypo: {
        fontSize: '1.1rem',
        color: () => {
            if (theme.palette.type === 'dark') {
                return theme.palette.grey[400]
            }
            return theme.palette.grey[600]
        }
    },
    iconStar: {
        marginRight: '7px',
        fontSize: '1.1rem',
        color: theme.palette.type === 'dark' ? '#FFDF00' : '#D4AF37'
    },
    linkLearnMore: {
        color: theme.palette.type === 'dark' ? 'HotPink' : 'blue'
    }
}))

const StarRows = () => {
    const classes = useStyles()
    const history = useHistory()
    const onClickLearnMore = () => {
        history.push(Paths.about())
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.contentWrapper}>
                <div className={classes.row}>
                    <Star className={classes.iconStar} />
                    <Typography className={classes.rowTypo}>
                        Обществени въпроси
                    </Typography>
                </div>
                <div className={classes.row}>
                    <Star className={classes.iconStar} />
                    <Typography className={classes.rowTypo}>
                        Актуални теми
                    </Typography>
                </div>
                <div className={classes.row}>
                    <Star className={classes.iconStar} />
                    <Typography className={classes.rowTypo}>
                        Легитимни резултати
                    </Typography>
                </div>
            </div>
            <div className={classes.contentWrapper}>
                <div className={classes.row}>
                    <Star className={classes.iconStar} />
                    <Typography className={classes.rowTypo}>
                        Анонимен акаунт
                    </Typography>
                </div>
                <div className={classes.row}>
                    <Star className={classes.iconStar} />
                    <Typography className={classes.rowTypo}>
                        Анонимно гласуване
                    </Typography>
                </div>
                <div className={classes.row}>
                    <Star className={classes.iconStar} />
                    <Link
                        href={Paths.about()}
                        onClick={onClickLearnMore}
                        className={classes.linkLearnMore}
                    >
                        <Typography className={classes.rowTypo}>
                            <strong>Научи повече...</strong>
                        </Typography>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default StarRows
