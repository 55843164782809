import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuthContext } from 'context/AuthContext'
import Box from '@material-ui/core/Box'
import useAuthenticationApi from 'api/useAuthenticationApi'
import Paths from 'pages/Paths'
import makeStyles from '@material-ui/core/styles/makeStyles'
import './login.css'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import FacebookLoginButton from 'shared/components/FacebookLoginButton'
import useMetaData from './useMetaData'
import LoadingIndicator from 'shared/components/LoadingIndicator'
import { useStateIfMounted } from 'use-state-if-mounted'
import useFacebook from 'shared/hooks/useFacebook'
import { Paper } from '@material-ui/core'
import { useDevice } from 'shared/hooks/useDevice'
import GoogleLoginButton from 'shared/components/GoogleLoginButton'
import Page from 'shared/components/Page'

const useStyles = makeStyles((theme) => ({
    page: {
        marginTop: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    [theme.breakpoints.down('xs')]: {
        page: {
            marginTop: theme.spacing(0)
        }
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(0)
    },
    submit: {
        margin: theme.spacing(1, 0, 1)
    },
    container: {
        padding: 0
    },
    paper: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            backgroundColor: 'transparent',
            border: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
            border: () => {
                let color
                if (theme.palette.type === 'dark') {
                    color = '#228B22'
                } else {
                    color = '#00FF7F'
                }

                return `1px solid ${color}`
            },
            borderRadius: '8px'
        }
    },
    link: {
        color: theme.palette.type === 'dark' ? 'HotPink' : 'blue'
    }
}))

export default function Login() {
    const classes = useStyles()
    const history = useHistory()
    const authenticationApi = useAuthenticationApi()
    const authCtx = useAuthContext()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useStateIfMounted('')

    const facebookLogin = useFacebook({
        state: '',
        onComplete: () => {
            authCtx.login()
        },
        onError: (err) => {
            if (err?.response?.data?.code === 'EmailExists') {
                setError('Имейлът е зает')
            } else if (err?.response?.data?.code === 'EmailMandatory') {
                setError('Имейлът е задължителен')
            }
            setSubmiting(false)
        },
        redirectPath: Paths.login()
    })

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const [submiting, setSubmiting] = useStateIfMounted(false)
    const anySubmiting = submiting || facebookLogin.submiting

    const onClickFacebookLogin = () => {
        facebookLogin.login()
    }

    const onClickSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        if (email.length === 0 || password.length === 0) {
            return
        }

        setSubmiting(true)

        authenticationApi
            .login(email, password)
            .then((data) => {
                authCtx.login()
                setSubmiting(false)
            })
            .catch((err) => {
                if (err.response?.data?.code === 'WrongCredentials') {
                    setError('Грешен имейл или парола')
                } else if (err?.response?.data?.code === 'EmailNotConfirmed') {
                    setError(
                        'Имейлът не е потвърден. Моля влезте в имейла си и потвърдете акаунта'
                    )
                }
                setSubmiting(false)
            })
    }

    const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
        setError('')
    }

    const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
        setError('')
    }

    const onClickRegister = (e: React.MouseEvent) => {
        e.preventDefault()
        if (!anySubmiting) {
            history.push(Paths.register())
        }
    }
    const onClickForgottenPassword = (e: React.MouseEvent) => {
        e.preventDefault()
        if (!anySubmiting) {
            history.push(Paths.forgottenPassword())
        }
    }

    const metaData = useMetaData()
    const device = useDevice()

    return (
        <>
            <Page
                mt={{
                    smallScreen: '80px',
                    mediumScreen: '120px',
                    largeScreen: '120px'
                }}
                metaData={metaData}
            >
                <LoadingIndicator loading={anySubmiting} />
                <Container maxWidth='xs' disableGutters>
                    <Paper className={classes.paper} elevation={0}>
                        <Box
                            width='100%'
                            display='flex'
                            justifyContent='space-between'
                            flexDirection='row'
                            alignItems='center'
                        >
                            <Box ml='4px'>
                                <Typography variant='h5' component='h2'>
                                    <strong>Вход</strong>
                                </Typography>
                            </Box>

                            <Box mr='4px'>
                                <LockOutlinedIcon
                                    color='action'
                                    fontSize='large'
                                />
                            </Box>
                        </Box>
                        <form onSubmit={onClickSubmit} className={classes.form}>
                            <TextField
                                disabled={anySubmiting}
                                variant='filled'
                                required
                                fullWidth
                                label={'Имейл'}
                                name='email'
                                autoComplete='email'
                                autoFocus
                                onChange={onChangeEmail}
                                helperText={error}
                                error={error.length > 0}
                            />
                            <TextField
                                disabled={anySubmiting}
                                variant='filled'
                                required
                                fullWidth
                                label={'Парола'}
                                type='password'
                                name='password'
                                autoComplete='current-password'
                                onChange={onChangePassword}
                                helperText={''}
                                error={error.length > 0}
                            />
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                className={classes.submit}
                                disabled={anySubmiting}
                            >
                                Влез
                            </Button>
                        </form>
                        <Box display='flex' justifyContent='space-between'>
                            <Link
                                href={Paths.forgottenPassword()}
                                onClick={onClickForgottenPassword}
                                variant='body1'
                                className={classes.link}
                            >
                                Забравена парола
                            </Link>
                            <Link
                                href={Paths.register()}
                                onClick={onClickRegister}
                                variant='body1'
                                className={classes.link}
                            >
                                Регистрация
                            </Link>
                        </Box>
                        <Box my={1} mb={2}>
                            <Divider />
                        </Box>
                        <FacebookLoginButton
                            onClick={onClickFacebookLogin}
                            disabled={anySubmiting}
                        />
                        <Box my={1}></Box>
                        <GoogleLoginButton />
                    </Paper>
                </Container>
            </Page>
        </>
    )
}
