import {
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles
} from '@material-ui/core'
import React, { useRef } from 'react'
import { Poll } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import Paths from 'pages/Paths'

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.type === 'dark' ? 'gold' : '#CFB53B'
    },
    typo: {
        fontWeight: 900
    }
}))

export default function SearchButtons() {
    const classes = useStyles()
    const history = useHistory()

    const onClickTopical = (e: React.MouseEvent) => {
        e.preventDefault()
        history.push({
            pathname: Paths.search(),
            search: `order=topical`
        })
    }

    const onClickMostVoted = (e: React.MouseEvent) => {
        e.preventDefault()
        history.push({
            pathname: Paths.search(),
            search: `order=most-voted`
        })
    }

    const onClickLastVoted = (e: React.MouseEvent) => {
        e.preventDefault()
        history.push({
            pathname: Paths.search(),
            search: `order=last-voted`
        })
    }

    const onClickNewest = (e: React.MouseEvent) => {
        e.preventDefault()
        history.push({
            pathname: Paths.search(),
            search: `order=newest`
        })
    }

    return (
        <div>
            <ListItem
                button
                component='a'
                onClick={onClickTopical}
                href={Paths.search()}
            >
                <ListItemIcon>
                    <Poll fontSize='medium' className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                    classes={{
                        primary: classes.typo
                    }}
                    primary={'Актуални'}
                />
            </ListItem>
            <ListItem
                button
                component='a'
                onClick={onClickMostVoted}
                href={Paths.search()}
            >
                <ListItemIcon>
                    <Poll fontSize='medium' className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                    classes={{
                        primary: classes.typo
                    }}
                    primary={'Най-гласувани'}
                />
            </ListItem>
            <ListItem
                button
                component='a'
                onClick={onClickLastVoted}
                href={Paths.search()}
            >
                <ListItemIcon>
                    <Poll fontSize='medium' className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                    classes={{
                        primary: classes.typo
                    }}
                    primary={'Посл. гласувани'}
                />
            </ListItem>
            <ListItem
                button
                component='a'
                onClick={onClickNewest}
                href={Paths.search()}
            >
                <ListItemIcon>
                    <Poll fontSize='medium' className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                    classes={{
                        primary: classes.typo
                    }}
                    primary={'Най-нови'}
                />
            </ListItem>
        </div>
    )
}
