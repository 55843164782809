import React, { useCallback, useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useHistory, useLocation } from 'react-router-dom'
import View from './View'
import { useAuthContext } from 'context/AuthContext'
import { useDevice } from 'shared/hooks/useDevice'
import { useStateIfMounted } from 'use-state-if-mounted'
import ReCAPTCHA from 'react-google-recaptcha'
import usePollsApi from 'api/usePollsApi'
import useVotesApi from 'api/useVotesApi'
import useAuthenticationApi from 'api/useAuthenticationApi'
import usePollOptionsApi from 'api/usePollOptionsApi'
import useAddOption from 'shared/hooks/useAddOption'
import { AppDispatch, GetState } from 'redux/store'
import slice from './slice'
import ReactGA from 'react-ga4'
import Paths from 'pages/Paths'
import { debug } from 'console'

export default function Container() {
    const state = useAppSelector((state) => state.pollState)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const id = urlParams.get('id') || urlParams.get('state') || ''
    const [recaptchaToken, setRecaptchaToken] = useStateIfMounted<
        string | null
    >(null)
    const ref = useRef(null)
    const [random, setRandom] = useStateIfMounted(false)
    const pollsApi = usePollsApi()
    const votesApi = useVotesApi()
    const history = useHistory()
    const authApi = useAuthenticationApi()
    const pollOptionsApi = usePollOptionsApi()
    const authCtx = useAuthContext()
    const device = useDevice()
    const addOption = useAddOption(state.poll?.options || [])
    const [privateBrowser, setPrivateBrowser] = useStateIfMounted(false)

    useEffect(() => {
        if (location.search === '') {
            dispatch(downloadRandomPoll(id))
            setRandom(true)
        } else {
            if (!random) {
                dispatch(downloadPoll(id))
            } else {
                setRandom(false)
            }
        }
        window.scroll(0, 0)
        executeRecaptcha()
    }, [window.location.search])

    useEffect(() => {
        return () => {
            dispatch(resetPoll())
        }
    }, [])

    useEffect(() => {
        if (device.browser.browserCheckReady) {
            setPrivateBrowser(device.browser.private)
        }
    }, [device.browser])

    const onVoteForOption = useCallback(
        (pollId: string, optionId: string) => {
            if (recaptchaToken) {
                dispatch(voteOption(pollId, optionId))
            }
        },
        [recaptchaToken, dispatch]
    )

    const onVoteForRating = useCallback(
        (pollId: string, rating: number) => {
            if (recaptchaToken) {
                dispatch(voteRating(pollId, rating))
            }
        },
        [recaptchaToken, dispatch]
    )

    const onClearOptionError = useCallback(() => {
        dispatch(clearOptionError())
    }, [])

    const onSaveOption = useCallback((pollId: string, optionValue: string) => {
        dispatch(saveOption(optionValue))
    }, [])

    const onRecaptchaChange = useCallback((token: string | null) => {
        setRecaptchaToken(token)
    }, [])

    const executeRecaptcha = useCallback(async () => {
        const current = ref.current as any
        if (current) {
            await current.executeAsync()
        }
    }, [ref])

    const resetPoll = () => (dispatch: AppDispatch) => {
        dispatch(slice.actions.reset())
    }

    const clearOptionError = () => (dispatch: AppDispatch) => {
        dispatch(slice.actions.setOptionError(''))
    }

    const downloadPoll = useCallback(
        (pollId: string) => async (dispatch: AppDispatch) => {
            dispatch(slice.actions.setPoll(null))
            const pollData = await pollsApi.getById(pollId)
            dispatch(slice.actions.setPoll(pollData))
            ReactGA.event('download poll')
        },
        []
    )

    const downloadRandomPoll = useCallback(
        (pollId: string) => async (dispatch: AppDispatch) => {
            dispatch(slice.actions.setPoll(null))
            const pollData = await pollsApi.getRandomPoll()
            dispatch(slice.actions.setPoll(pollData))
            ReactGA.event('download random poll')
            history.push(Paths.poll(pollData.id))
        },
        []
    )

    const voteOption = useCallback(
        (pollId: string, optionId: string) => async (
            dispatch: AppDispatch,
            getState: GetState
        ) => {
            if (privateBrowser) {
                ReactGA.event('poll vote from private browser')
                return
            }

            if (!recaptchaToken) {
                ReactGA.event('recaptcha is null when vote')
                return
            }

            const state = getState().pollState

            dispatch(slice.actions.setVoting(true))

            if (!authCtx.getAuthenticated()) {
                return authApi.loginClient(recaptchaToken).then(() => {
                    authCtx.login()
                    dispatch(voteOption(pollId, optionId))
                })
            }

            if (state.poll !== null) {
                await votesApi.voteOption(state.poll.id, optionId)
                const pollData = await pollsApi.getById(state.poll.id)
                dispatch(slice.actions.setPoll(pollData))
                dispatch(slice.actions.setVoting(false))
                ReactGA.event('poll vote')
            }
        },
        [privateBrowser, recaptchaToken, authCtx, recaptchaToken, dispatch]
    )

    const voteRating = useCallback(
        (pollId: string, rating: number) => async (
            dispatch: AppDispatch,
            getState: GetState
        ) => {
            if (privateBrowser) {
                ReactGA.event('rating vote from private browser')
                return
            }

            if (!recaptchaToken) {
                ReactGA.event('recaptcha is null when vote')
                return
            }

            const state = getState().pollState

            dispatch(slice.actions.setVoting(true))

            if (!authCtx.getAuthenticated()) {
                return authApi.loginClient(recaptchaToken).then(() => {
                    authCtx.login()
                    dispatch(voteRating(pollId, rating))
                })
            }

            if (state.poll !== null) {
                await votesApi.voteRating(state.poll.id, rating)
                ReactGA.event('rating vote')
                const pollData = await pollsApi.getById(state.poll.id)
                dispatch(slice.actions.setPoll(pollData))
                dispatch(slice.actions.setVoting(false))
            }
        },
        [privateBrowser, recaptchaToken, authCtx, recaptchaToken, dispatch]
    )
    const saveOption = (optionValue: string) => async (
        dispatch: AppDispatch,
        getState: GetState
    ) => {
        if (privateBrowser) {
            ReactGA.event('save option private browser')
            return
        }
        const state = getState().pollState
        dispatch(slice.actions.setSavingOption(true))

        try {
            const optionId = await addOption.save(
                state.poll?.id || '',
                optionValue
            )
            if (optionId) {
                pollOptionsApi
                    .getById(optionId)
                    .then((data) => {
                        ReactGA.event(`save option ${optionValue}`)
                        if (state.poll) {
                            const newPoll = {
                                ...state.poll,
                                options: [...state.poll.options, data]
                            }
                            dispatch(slice.actions.setSavingOption(false))
                            dispatch(slice.actions.setPoll(newPoll))
                        }
                    })
                    .catch((e) => {
                        dispatch(slice.actions.setSavingOption(false))
                    })
            }
        } catch (e) {
            const eAny = e as any
            if (eAny.clientAddOptionError) {
                dispatch(slice.actions.setOptionError(eAny.msg))
            }
            dispatch(slice.actions.setSavingOption(false))
        }
    }

    return (
        <>
            <View
                {...state}
                onClearOptionError={onClearOptionError}
                onVoteForOption={onVoteForOption}
                onVoteForRating={onVoteForRating}
                onSaveOption={onSaveOption}
            />
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ''}
                onChange={onRecaptchaChange}
                size='invisible'
                ref={ref}
                tabIndex={99999}
                hl='bg'
            />
        </>
    )
}
