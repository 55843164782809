import React from 'react'
import SearchField from '../containers/SearchField'
import PollsList from '../containers/PollsList'
import Paginator from '../containers/Paginator'
import PollModal from '../containers/PollModal'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import Filters from './Filters'
import LoadingIndicator from 'shared/components/LoadingIndicator'
import PollTypes from '../containers/PollTypes'
import { Container } from '@material-ui/core'

export type LayoutProps = {
    loading: boolean
    anyLoaded: boolean
}

export default function Layout(props: LayoutProps) {
    return (
        <Container maxWidth='sm' disableGutters>
            <Filters />
            <SearchField />
            <PollTypes />
            <Box mb={'1px'} />
            <LoadingIndicator loading={props.loading} zIndex={20} />
            <Fade in={!props.loading} timeout={200}>
                <Box>
                    <PollsList />
                </Box>
            </Fade>
            <Box my={2}>
                <Paginator />
            </Box>
            <PollModal />
        </Container>
    )
}
