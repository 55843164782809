import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import { useAuthContext } from 'context/AuthContext'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Paths from 'pages/Paths'
import useAuthenticationApi from 'api/useAuthenticationApi'
import UserRules from 'server-rules/UserRules'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import useMetaData from './useMetaData'
import PageMetaData from 'shared/components/PageMetaData'
import LoadingIndicator from 'shared/components/LoadingIndicator'
import { Paper } from '@material-ui/core'
import Page from 'shared/components/Page'

const useStyles = makeStyles((theme) => ({
    page: {
        marginTop: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    [theme.breakpoints.down('xs')]: {
        page: {
            marginTop: theme.spacing(0)
        }
    },
    form: {
        width: '100%',
        textAlign: 'left'
    },
    submit: {
        margin: theme.spacing(1, 0, 0)
    },
    container: {
        padding: 0
    },
    marginY0: {
        marginTop: '0px',
        marginBottom: '0px'
    },
    datePickerInput: {
        color: theme.palette.text.secondary
    },
    paper: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            backgroundColor: 'transparent',
            border: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
            border: () => {
                let color
                if (theme.palette.type === 'dark') {
                    color = '#228B22'
                } else {
                    color = '#00FF7F'
                }

                return `1px solid ${color}`
            },
            borderRadius: '8px'
        }
    }
}))

export default function Register() {
    const classes = useStyles()
    const history = useHistory()
    const authenticationApi = useAuthenticationApi()
    const metaData = useMetaData()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [submiting, setSubmiting] = useState(false)

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        if (password.length < UserRules.PasswordMinLength) {
            setPasswordError(
                `Паролата трябва да бъде минимум ${UserRules.PasswordMinLength} символа`
            )
            return
        } else if (password.length > UserRules.PasswordMaxLength) {
            setPasswordError(
                `Паролата трябва да бъде максимум ${UserRules.PasswordMaxLength} символа`
            )
            return
        }

        setSubmiting(true)

        authenticationApi
            .register(email, password)
            .then((data) => {
                if (process.env.NODE_ENV === 'development') {
                    history.push({
                        pathname: Paths.emailConfirmationSent(),
                        state: 'email-confirmation'
                    })
                } else {
                    history.push({
                        pathname: Paths.emailConfirmationSent(),
                        state: 'email-confirmation'
                    })
                }
            })
            .catch((err) => {
                if (err.response.data?.code === 'EmailExists') {
                    setEmailError('Имейлът е зает')
                } else if (err.response?.data?.code === 'InvalidEmail') {
                    setEmailError('Невалиден имейл')
                } else if (err.response?.data?.code === 'UserNotRegistered') {
                    setEmailError(
                        'Регистрацията не може да бъде извършена в този момент. Може да пробвате влизане чрез Facebook'
                    )
                }
                setSubmiting(false)
            })
    }

    const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
        setPasswordError('')
    }

    return (
        <>
            <Page
                mt={{
                    smallScreen: '80px',
                    mediumScreen: '120px',
                    largeScreen: '120px'
                }}
                metaData={metaData}
            >
                <LoadingIndicator loading={submiting} />
                <Container maxWidth='xs' disableGutters>
                    <Paper className={classes.paper} elevation={0}>
                        <Box
                            width='100%'
                            display='flex'
                            justifyContent='space-between'
                            flexDirection='row'
                            alignItems='center'
                        >
                            <Box ml='4px'>
                                <Typography component='h2' variant='h5'>
                                    <strong>Регистрация</strong>
                                </Typography>
                            </Box>
                            <Box mr='4px'>
                                <LockOutlinedIcon
                                    color='action'
                                    fontSize='large'
                                />
                            </Box>
                        </Box>
                        <form onSubmit={onSubmit} className={classes.form}>
                            <TextField
                                variant='filled'
                                required
                                fullWidth
                                label={'Имейл'}
                                autoComplete='email'
                                autoFocus
                                onChange={onChangeEmail}
                                helperText={emailError}
                                error={emailError.length > 0}
                                disabled={submiting}
                            />
                            <TextField
                                variant='filled'
                                required
                                fullWidth
                                label={'Парола'}
                                type='password'
                                id='password'
                                autoComplete='current-password'
                                onChange={onChangePassword}
                                helperText={passwordError}
                                error={passwordError.length > 0}
                                disabled={submiting}
                            />
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                className={classes.submit}
                                disabled={submiting}
                            >
                                Регистрация
                            </Button>
                        </form>
                    </Paper>
                </Container>
            </Page>
        </>
    )
}
