import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Account from '../containers/Account'
import Activity from '../containers/Activity'
import { Container, Fade } from '@material-ui/core'
import LoadingIndicator from 'shared/components/LoadingIndicator'
import ModalDeleteAccount from '../containers/ModalDeleteAccount'
import Identification from '../components/Identification'

export type LayoutProps = {
    pageLoading: boolean
    deletingUser: boolean
    loggingOut: boolean
    temporaryAccount: boolean
    userId: string
    onLogout: () => void
    onOpenDeleteModal: () => void
}

export default function Layout(props: LayoutProps) {
    if (props.pageLoading) {
        return <LoadingIndicator loading={props.pageLoading} />
    } else {
        return (
            <>
                <Fade in={!props.pageLoading} timeout={400}>
                    <Container maxWidth='xs' disableGutters>
                        <Activity />
                        <Box mt={4} />
                        <Account />
                        <Box mt={4} />
                        <Identification userId={props.userId} />
                        <Box mt={20} />
                        <Divider />
                        <Box mt={1} />
                        <Box display='flex' justifyContent='space-between'>
                            <Button
                                size='small'
                                onClick={props.onOpenDeleteModal}
                                variant='text'
                                color='secondary'
                                disabled={
                                    props.deletingUser || props.loggingOut
                                }
                            >
                                Изтрий акаунт
                            </Button>
                            {!props.temporaryAccount && (
                                <Button
                                    size='small'
                                    variant='text'
                                    onClick={props.onLogout}
                                    disabled={
                                        props.deletingUser || props.loggingOut
                                    }
                                >
                                    Излез
                                </Button>
                            )}
                        </Box>
                    </Container>
                </Fade>
                <ModalDeleteAccount />
            </>
        )
    }
}
