import { configureStore } from '@reduxjs/toolkit'
import demographicsSlice from 'pages/Poll/BottomTabs/Demographics/slice'
import otherPollsSlice from 'pages/Poll/BottomTabs/OtherPolls/slice'
import pollSlice from 'pages/Poll/Poll/slice'

const store = configureStore({
    reducer: {
        pollState: pollSlice.reducer,
        otherPollsState: otherPollsSlice.reducer,
        demographicsState: demographicsSlice.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type GetState = () => RootState
export default store
