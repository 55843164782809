import React from 'react'
import usePage from './context/usePage'
import Context from './context'
import useMetaData from './useMetaData'
import PageMetaData from 'shared/components/PageMetaData'
import Layout from './containers/Layout'
import Page from 'shared/components/Page'

export default function Profile() {
    const page = usePage()
    const metaData = useMetaData()

    return (
        <>
            <Context.Provider value={{ ...page }}>
                <Page
                    mt={{
                        smallScreen: '100px',
                        mediumScreen: '100px',
                        largeScreen: '50px'
                    }}
                    pb={{
                        smallScreen: '20px',
                        mediumScreen: '20px',
                        largeScreen: '20px'
                    }}
                    metaData={metaData}
                >
                    <Layout />
                </Page>
            </Context.Provider>
        </>
    )
}
