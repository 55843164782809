import usePollOptionsApi from 'api/usePollOptionsApi'
import PollRules from 'server-rules/PollRules'
import PollOptionDto from 'shared/types/PollOptionDto'
import { useStateIfMounted } from 'use-state-if-mounted'

export default function useAddOption(options: PollOptionDto[]) {
    const optionsApi = usePollOptionsApi()
    const [saving, setSaving] = useStateIfMounted(false)
    const [error, setError] = useStateIfMounted('')

    const clearError = () => {
        setError('')
    }

    const save = async (pollId: string, value: string) => {
        value = value.trim()

        if (value.length > PollRules.OptionMaxLength) {
            const err = `Опцията трябва да бъде максимум ${PollRules.OptionMaxLength} символа`
            setError(err)
            return Promise.reject({
                clientAddOptionError: true,
                msg: err
            })
        }

        if (value.length < PollRules.OptionMinLength) {
            const err = `Опцията трябва да бъде минимум ${PollRules.OptionMinLength} символа`
            setError(err)
            return Promise.reject({
                clientAddOptionError: true,
                msg: err
            })
        }

        for (const i in options) {
            const opt = options[i].value.trim()
            if (value === opt) {
                const err = 'Опцията се повтаря'
                setError(err)
                return Promise.reject({
                    clientAddOptionError: true,
                    msg: err
                })
            }
        }

        setSaving(true)

        return await optionsApi
            .addOption(pollId, value)
            .then((createdOption: { id: string }) => {
                setSaving(false)
                return createdOption.id
            })
    }

    return {
        saving,
        error,
        clearError,
        save
    }
}
