import React from 'react'
import BottomTabs from './BottomTabs'
import useMetaData from './useMetaData'
import Poll from './Poll'
import Page from 'shared/components/Page'
import { Box } from '@material-ui/core'

export default function PollPage() {
    const metaData = useMetaData()

    return (
        <>
            <Page
                mt={{
                    smallScreen: '68px',
                    mediumScreen: '82px',
                    largeScreen: '40px'
                }}
                px={{
                    smallScreen: '10px'
                }}
                pb={{
                    smallScreen: '20px',
                    mediumScreen: '20px',
                    largeScreen: '20px'
                }}
                metaData={metaData}
            >
                <Poll />
                <BottomTabs />
            </Page>
        </>
    )
}
