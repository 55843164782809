import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react'
import Environment from 'utils/environment/Environment'
import { darkTheme, lightTheme, seasonalTheme } from '../themes'
import LocalStorage from 'utils/LocalStorage'
import { useHistory, useLocation } from 'react-router-dom'

type ThemeContext = {
    isDarkTheme: boolean
    theme: Theme
    darkTheme: Theme
    lightTheme: Theme
    isSeasonalTheme: boolean
    setDarkThemeActive: (value: boolean) => void
    setSeasonalThemeActive: () => void
}

const Context = createContext<ThemeContext | undefined>(undefined)

export function useThemeContext(): ThemeContext {
    const context = useContext(Context)
    if (context === undefined) {
        throw new Error('useTheme must be within ThemeProvider')
    }

    return context
}

export function NagaThemeProvider(props: PropsWithChildren<{}>) {
    const storage_isDark = LocalStorage.get('isDarkTheme')
    const storage_isSeasonal = LocalStorage.get('isSeasonalTheme')

    const location = useLocation()

    let isDarkInitValue = false
    let isSeasonalInitValue = false

    if (location.pathname.includes('embedded-poll')) {
        const params = new URLSearchParams(location.search)
        const bgColor = params.get('bgColor')
        if (bgColor !== null) {
            document.body.style.backgroundColor = bgColor
        }
        if (params.get('darkTheme') === 'true') {
            isDarkInitValue = true
        }
    } else if (storage_isDark === null) {
        isDarkInitValue = false
        isSeasonalInitValue = false
    } else {
        isDarkInitValue = storage_isDark === 'true'
        isSeasonalInitValue = storage_isSeasonal === 'true'
    }
    const [isSeasonalTheme, setSeasonalTheme] = useState(isSeasonalInitValue)
    const [isDarkTheme, setDarkTheme] = useState(isDarkInitValue)

    const getTheme = useCallback(() => {
        if (isSeasonalTheme) {
            return seasonalTheme
        } else {
            return isDarkTheme ? darkTheme : lightTheme
        }
    }, [isSeasonalTheme, isDarkTheme])

    const theme = getTheme()

    const setDarkThemeActive = (value: boolean) => {
        LocalStorage.set('isDarkTheme', value.toString())
        LocalStorage.set('isSeasonalTheme', 'false')
        setSeasonalTheme(false)
        setDarkTheme(value)
    }

    const activateSeasonalTheme = () => {
        LocalStorage.set('isSeasonalTheme', 'true')

        // because current seasonal theme is light
        LocalStorage.set('isDarkTheme', 'true')
        setDarkTheme(true)

        setSeasonalTheme(true)
    }

    return (
        <Context.Provider
            value={{
                isDarkTheme,
                theme,
                darkTheme,
                isSeasonalTheme,
                lightTheme, //: isSeasonalTheme ? christmasTheme : lightTheme,
                setDarkThemeActive,
                setSeasonalThemeActive: activateSeasonalTheme
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {props.children}
            </ThemeProvider>
        </Context.Provider>
    )
}
