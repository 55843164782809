import useSearchApi from 'api/useSearchApi'
import usePollsApi from 'api/usePollsApi'
import EntitiesDto from 'shared/types/EntitiesDto'
import PollDto from 'shared/types/PollDto'
import { useStateIfMounted } from 'use-state-if-mounted'

export type PollsState = {
    polls: PollDto[] | null
    totalCount: number | null
    loading: boolean
}

export default function usePollsService() {
    window.scroll(0, 0)

    const searchApi = useSearchApi()
    const pollsApi = usePollsApi()

    const [state, setState] = useStateIfMounted<PollsState>({
        polls: null,
        totalCount: null,
        loading: true
    })

    const clearState = () => {
        setState({
            polls: null,
            totalCount: null,
            loading: true
        })
    }

    const downloadSingle = async (id: string) => {
        return await pollsApi.getById(id)
    }

    const download = async (
        question: string,
        order: string,
        expiration: string,
        page: number,
        type: string
    ) => {
        window.scroll(0, 0)

        setState({
            polls: null,
            totalCount: null,
            loading: true
        })

        return new Promise<EntitiesDto<PollDto>>(async (resolve) => {
            const entitiesData: EntitiesDto<PollDto> = await searchApi.search({
                question,
                order,
                page,
                expiration,
                pollType: type
            })

            setState({
                loading: false,
                polls: entitiesData.entities,
                totalCount: entitiesData.totalCount
            })

            return resolve(entitiesData)
        })
    }

    return {
        state,
        download,
        clearState,
        downloadSingle
    }
}
