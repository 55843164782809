import { PinDropSharp } from '@material-ui/icons'
import useAuthenticationApi from 'api/useAuthenticationApi'
import { useAuthContext } from 'context/AuthContext'
import UserRules from 'server-rules/UserRules'
import { stringify } from 'querystring'
import { useEffect } from 'react'
import useFacebook from 'shared/hooks/useFacebook'
import { getCookie } from 'typescript-cookie'
import { useStateIfMounted } from 'use-state-if-mounted'

type Props = {
    onSuccess: (integrationType: string) => void
    onError: (integrationType: string, errorCode: string) => void
    fbRedirectPath: string
    fbRedirectState: string
}

export default function useAuthentication(props: Props) {
    const authCtx = useAuthContext()
    const authenticationApi = useAuthenticationApi()
    const [localSubmiting, setLocalSubmiting] = useStateIfMounted(false)

    const onCompleteFacebookLogin = () => {
        authCtx.login()
        props.onSuccess('facebook')
    }

    const onCompleteLocalLogin = () => {
        authCtx.login()
        props.onSuccess('login')
    }

    const onCompleteRegister = () => {
        props.onSuccess('register')
    }

    const facebookApi = useFacebook({
        onComplete: onCompleteFacebookLogin,
        onError: (err) => {
            props.onError('facebook', err?.response?.data?.code)
        },
        redirectPath: props.fbRedirectPath,
        state: props.fbRedirectState
    })

    const loginLocal = (email: string, password: string) => {
        localLogin(email, password)
    }

    const loginFacebook = () => {
        facebookApi.login()
    }

    const connectFacebook = () => {
        facebookApi.connect()
    }

    const register = (email: string, password: string) => {
        if (password.length < UserRules.PasswordMinLength) {
            return props.onError('register', 'ShortPassword')
        } else if (password.length > UserRules.PasswordMaxLength) {
            return props.onError('register', 'LongPassword')
        }

        setLocalSubmiting(true)

        authenticationApi
            .register(email, password)
            .then(() => {
                setLocalSubmiting(false)
                onCompleteRegister()
            })
            .catch((err) => {
                setLocalSubmiting(false)
                props.onError('register', err.response?.data?.code)
            })
    }

    const registerToConnect = (email: string, password: string) => {
        if (password.length < UserRules.PasswordMinLength) {
            return props.onError('register-connect', 'ShortPassword')
        } else if (password.length > UserRules.PasswordMaxLength) {
            return props.onError('register-connect', 'LongPassword')
        }

        setLocalSubmiting(true)

        authenticationApi
            .register(email, password, getCookie('UserId'))
            .then(() => {
                setLocalSubmiting(false)
                onCompleteRegister()
            })
            .catch((err) => {
                setLocalSubmiting(false)
                props.onError('register', err.response?.data?.code)
            })
    }

    const localLogin = (email: string, password: string) => {
        if (password.length < UserRules.PasswordMinLength) {
            return props.onError('login', 'ShortPassword')
        } else if (password.length > UserRules.PasswordMaxLength) {
            return props.onError('login', 'LongPassword')
        }

        setLocalSubmiting(true)

        authenticationApi
            .login(email, password)
            .then(() => {
                setLocalSubmiting(false)
                onCompleteLocalLogin()
            })
            .catch((err) => {
                setLocalSubmiting(false)
                props.onError('login', err.response?.data?.code)
            })
    }

    return {
        loginFacebook: loginFacebook,
        loginLocal: loginLocal,
        fbSubmiting: facebookApi.submiting,
        localSubmiting: localSubmiting,
        register,
        connectFacebook,
        registerToConnect
    }
}
