import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
/**
 * Important!
 * Avoid removing default queries from url. For example page=1.
 * This may lead to path with no queries in url. NoQueries case is automaticlly handled by this hook
 */

interface UpdateHistorySettings {
    method: 'push' | 'replace'
    skipHandler?: boolean
}

let skipHandler: boolean = false

export function useQueryHandler(
    queryChangeHandler?: (params: any) => void,
    defaultQueries?: any
) {
    const history = useHistory()
    const location = useLocation()
    const query = new URLSearchParams(location.search)

    const deleteQueries = (settings: UpdateHistorySettings) => {
        const tmp = new URLSearchParams(query)

        tmp.forEach((value, key, parent) => {
            query.delete(key)
        })

        updateHistory({ method: 'replace', skipHandler: true })
    }

    const getQueryOrDefault = (name: string): string => {
        if (!defaultQueries) {
            new Error('Default queries missing')
        }

        // It is posssible to be '' so we want to check for undefined and null
        if (
            defaultQueries[name] === undefined ||
            defaultQueries[name] === null
        ) {
            throw new Error(`Default query ${name} missing`)
        }

        return query.get(name) || defaultQueries[name].toString()
    }

    const getQuery = (name: string): string | null => {
        return query.get(name)
    }

    const updateHistory = (settings: UpdateHistorySettings, params?: any) => {
        skipHandler = settings.skipHandler || false

        const config: UpdateHistorySettings = {
            method: settings.method
        }

        if (query.keys.length > -1) {
            history[config.method](`${location.pathname}?${query.toString()}`)
        }
    }

    const [mountHandled, setMountHandled] = useState(false)

    useEffect(() => {
        let noSearchParams = false
        let init = false

        if (location.search.length === 0) {
            noSearchParams = true
        }

        if (!mountHandled) {
            setMountHandled(true)
            init = true
        }
        if (!!queryChangeHandler && !skipHandler) {
            queryChangeHandler({ init, home: noSearchParams, noSearchParams })
        }

        skipHandler = false
    }, [location.search])

    return [
        query,
        updateHistory,
        getQueryOrDefault,
        deleteQueries,
        getQuery,
        location
    ] as const
}
