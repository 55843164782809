import Paths from 'pages/Paths'
import { PageMetaDataProps } from 'shared/components/PageMetaData'

export default function useMetaData(): PageMetaDataProps {
    return {
        h1: 'Вход',
        title: 'Вход | Anketi.bg',
        metas: [
            {
                name: 'description',
                content: 'Влез в твоя акаунт или създай нов'
            },
            {
                name: 'og:url',
                content: `https://anketi.bg${Paths.login()}`
            },
            {
                name: 'og:title',
                content: 'Вход | Anketi.bg'
            },
            {
                name: 'keywords',
                content: 'влез,вход'
            }
        ],
        links: [
            {
                rel: 'canonical',
                href: `https://anketi.bg${Paths.login()}`
            }
        ]
    }
}
