import QueryBuilder from './QueryBuilder'
import { useAxiosContext } from 'context/AxiosContext'
import { useSnackbarContext } from 'context/SnackbarContext'
import { useLocation } from 'react-router-dom'

export default function useAuthenticationApi() {
    const http = useAxiosContext()
    const snackbarCtx = useSnackbarContext()
    const location = useLocation()

    const facebookConnectViaCode = async (
        code: string,
        redirectPath: string
    ) => {
        const builder = new QueryBuilder()
        builder.addSimple('code', code)
        builder.addSimple('redirectPath', redirectPath)
        const res = await http.client.post(
            `/authentication/facebook-connect-via-code${builder.query.value}`
        )
        return res.data
    }

    const facebookConnectViaToken = async (accessToken: string) => {
        const res = await http.client.post(`/authentication/facebook-connect`, {
            accessToken
        })
        return res.data
    }

    const loginClient = async (recaptchaToken: string) => {
        const res = await http.client.post(`/authentication/login-client`, {
            recaptchaToken
        })
        if (!location.pathname.includes('embedded-poll')) {
            snackbarCtx.open('Създаден е временен акаунт')
        }
        return res.data
    }

    const login = async (email: string, password: string) => {
        const res = await http.client.post(`/authentication/login`, {
            email,
            password
        })
        snackbarCtx.open('Успешно влезнахте в профила си')
        return res.data
    }

    const logout = async () => {
        const res = await http.client.post(`/authentication/logout`)
        return res.data
    }

    const facebookLoginViaToken = async (accessToken: string) => {
        const res = await http.client.post(
            `/authentication/facebook-login-via-token`,
            {
                accessToken
            }
        )
        snackbarCtx.open('Успешно влезнахте в профила си')
        return res.data
    }

    const googleLoginViaToken = async (accessToken: string) => {
        const res = await http.client.post(
            `/authentication/google-login-via-token`,
            {
                accessToken
            }
        )
        snackbarCtx.open('Успешно влезнахте в профила си')
        return res.data
    }

    const facebookLoginViaCode = async (
        code: string,
        redirectPath?: string
    ): Promise<any> => {
        const builder = new QueryBuilder()
        builder.addSimple('code', code)
        builder.addSimple('redirectPath', redirectPath)
        const res = await http.client.post(
            `/authentication/facebook-login-via-code${builder.query.value}`
        )
        return res.data
    }

    const register = async (
        email: string,
        password: string,
        tmpUserId?: string
    ) => {
        const res = await http.client.post(`/authentication/register`, {
            email,
            password,
            tmpUserId
        })
        return res.data
    }

    const forgottenPassword = async (email: string) => {
        const res = await http.client.post(`/AccountAccess/ForgottenPassword`, {
            email
        })
        return res.data
    }

    const resetPassword = async (
        password: string,
        password2: string,
        email: string,
        token: string
    ) => {
        const builder = new QueryBuilder()

        builder.addSimple('email', email)
        builder.addSimple('token', token)

        const res = await http.client.post(
            `/AccountAccess/ResetPassword${builder.query.value}`,
            {
                password,
                password2
            }
        )
        snackbarCtx.open('Успешно възстановихте паролата си')
        return res.data
    }

    const confirmAccount = async (
        email: string,
        token: string,
        tmpUserId: string | null
    ) => {
        const builder = new QueryBuilder()

        builder.addSimple('email', email)
        builder.addSimple('token', token)
        if (tmpUserId) {
            builder.addSimple('token', tmpUserId)
        }

        const res = await http.client.post(
            `/AccountAccess/ConfirmEmail${builder.query.value}`
        )

        return res.data
    }

    const resendEmailConfirmation = async (email: string) => {
        const builder = new QueryBuilder()

        builder.addSimple('email', email)

        const res = await http.client.post(
            `/authentication/resend-email-confirmation${builder.query.value}`
        )
        return res.data
    }

    const addEmailAndPassword = async (email: string, password: string) => {
        const res = await http.client.post(
            `/authentication/add-email-and-password`,
            {
                email,
                password
            }
        )
        snackbarCtx.open('Успешно добавихте имейл и парола')
        return res.data
    }

    return {
        login,
        facebookLoginWithToken: facebookLoginViaToken,
        register,
        forgottenPassword,
        resetPassword,
        confirmAccount,
        resendEmailConfirmation,
        facebookLoginWithCode: facebookLoginViaCode,
        logout,
        loginClient,
        facebookConnectWithCode: facebookConnectViaCode,
        facebookConnectWithToken: facebookConnectViaToken,
        addEmailAndPassword,
        googleLoginViaToken
    }
}
