import { Box, Divider, Paper, Typography, makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { TooltipProps } from 'recharts'

type Props = {
    colors: any
} & TooltipProps<number, string>

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: 'left',
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1),
        borderRadius: '8px'
    }
}))

export default function Tooltip(props: Props) {
    const classes = useStyles()

    const singlePayload =
        props.payload && props.payload.length > 0 && props.payload[0].payload

    if (!singlePayload) {
        return null
    }

    let data = Object.keys(singlePayload)
        .filter((x) => x !== 'name')
        .map((optionName: string, i: number) => {
            return {
                [optionName]: singlePayload[optionName] as number
            }
        })

    data = data.sort((a, b) => {
        const valueA = Object.values(a)[0]
        const valueB = Object.values(b)[0]
        return valueB - valueA
    })

    data = data.splice(0, 8)

    return (
        <Paper elevation={9} className={classes.wrapper}>
            <Typography align='center'>
                <strong>{singlePayload.name}</strong>
            </Typography>
            <Divider />
            {data.map((obj, i) => {
                const label = Object.keys(obj)[0]
                return (
                    <Typography
                        variant='body1'
                        component='div'
                        color='textSecondary'
                        key={i}
                    >
                        <Box
                            height='10px'
                            width='10px'
                            display={'inline-block'}
                            bgcolor={props.colors[label]}
                            borderRadius={'50%'}
                        ></Box>
                        <strong>
                            {' '}
                            {label.substring(0, 20)}
                            {label.length > 20 && '...'}
                        </strong>{' '}
                        : {obj[label]}
                    </Typography>
                )
            })}
        </Paper>
    )
}
