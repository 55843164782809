import React, { useEffect, useState } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import Modal from './Modal'
import ModalHeader from './ModalHeader'

export type PollWarningLabelProps = {
    isExpired: boolean
    status: string
}

const useStyles = makeStyles((theme) => {
    return {
        expired: {},
        expiredLabel: {
            color: theme.palette.error.main
        },
        statusInitialLabel: {
            color: theme.palette.warning.main
        },
        statusUnpublished: {
            color: theme.palette.error.main
        }
    }
})

const PollWarningLabel = (props: PollWarningLabelProps) => {
    const classes = useStyles()

    useEffect(() => {
        setModalOpened(props.isExpired || props.status !== 'published')
    }, [props])

    const [modalOpened, setModalOpened] = useState(
        props.isExpired || props.status !== 'published'
    )

    const onClose = () => {
        setModalOpened(false)
    }

    return (
        <Modal onClose={onClose} isActive={modalOpened}>
            <Box display='flex' flexDirection='column' p={2}>
                <ModalHeader
                    title='Анкетата е затворена за гласуване'
                    onClose={onClose}
                    titleSafeArea
                    align='center'
                />
                {/* I would prefer to not show this label cause with will change the height of embedded poll */}
                {props.isExpired && (
                    <Box my={0} className={classes.expired}>
                        <Typography
                            variant='h5'
                            component='p'
                            align='center'
                            className={classes.expiredLabel}
                        >
                            <strong>ИЗТЕКЛА</strong>
                        </Typography>
                    </Box>
                )}
                {props.status === 'initial' && (
                    <Box mb={1}>
                        <Typography
                            variant='h5'
                            component='p'
                            align='center'
                            className={classes.statusInitialLabel}
                        >
                            <strong>Чака одобрение</strong>
                        </Typography>
                    </Box>
                )}
                {props.status === 'unpublished' && (
                    <Box mb={1}>
                        <Typography
                            variant='h5'
                            component='p'
                            align='center'
                            className={classes.statusUnpublished}
                        >
                            <strong>Неодобрена</strong>
                        </Typography>
                    </Box>
                )}
            </Box>
        </Modal>
    )
}

export default PollWarningLabel
