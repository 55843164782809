class Paths {
    static admin = {
        admin: () => '/admin',
        monitoring: () => '/admin/monitoring',
        editor: (pollId?: string) => {
            if (pollId !== undefined) {
                return `/admin/editor/${pollId}`
            } else {
                return '/admin/editor/:id'
            }
        },
        polls: () => '/admin/polls',
        users: () => '/admin/users'
    }

    static home = () => '/'
    static search = () => '/search'
    static edit = () => '/redaktirane'
    static poll = (id?: string) => {
        if (id) {
            return `/anketi?id=${id}`
        } else {
            return '/anketi'
        }
    }
    static newPoll = (id?: string) => {
        if (id) {
            return `/poll/${id}`
        } else {
            return '/poll/:id'
        }
    }
    static randomPollRedirection = () => '/random-poll-redirection'
    static profile = () => '/profil'
    static login = () => '/vhod'
    static register = () => '/registraciya'
    static create = () => '/sazdavane-na-anketa'
    static passwordRecovery = () => '/vаzstanovyavane-na-parola'
    static forgottenPassword = () => '/zabravena-parola'
    static confirmEmail = () => '/potvarzhdavane-na-akaunt'
    static emailConfirmationSent = () => '/emailat-e-izpraten'
    static error404 = () => '/404'
    static error500 = () => '/500'
    static errorDev = () => '/error-dev'
    static votedPolls = () => '/glasuvani-ot-men'
    static createdPolls = () => '/sazdadeni-ot-men'
    static invalidActionKey = () => '/nevaliden-kliuch'
    static mostVoted = (segment: string) => {
        switch (segment) {
            case 'day':
                return `/nai-glasuvani-za-den`
            case 'week':
                return `/nai-glasuvani-za-sedmica`
            case 'month':
                return `/nai-glasuvani-za-mesec`
        }
        throw new Error('Invalid segment')
    }
    static about = () => '/about'
    static privacy = () => '/poveritelnost'
    static terms = () => '/usloviya'
    static embeddedPoll = () => '/embedded-poll/:id'
    static embedEditor = (id?: string) => {
        if (id) {
            return `/embed-editor/${id}`
        } else {
            return '/embed-editor/:id'
        }
    }
}

export default Paths
