import React from 'react'
import useMetaData from './useMetaData'
import usePage from './context/usePage'
import Context from './context'
import Layout from './containers/Layout'
import PageMetaData from 'shared/components/PageMetaData'
import { useDevice } from 'shared/hooks/useDevice'
import Page from 'shared/components/Page'

export default function Search() {
    const page = usePage()
    const metaData = useMetaData()

    return (
        <Context.Provider value={{ ...page }}>
            <Page
                mt={{
                    smallScreen: '65px',
                    mediumScreen: '80px',
                    largeScreen: '10px'
                }}
                px={
                    {
                        // smallScreen: '2px'
                    }
                }
                pb={{
                    smallScreen: '20px',
                    mediumScreen: '20px',
                    largeScreen: '20px'
                }}
                metaData={metaData}
            >
                <Layout />
            </Page>
        </Context.Provider>
    )
}
