export default class LocalStorage {
    static get(key: 'isDarkTheme' | 'isSeasonalTheme') {
        let storageSupported = false

        try {
            storageSupported = window.localStorage && true
        } catch (e) {
            storageSupported = false
        }

        if (storageSupported) {
            return localStorage.getItem(key)
        } else {
            return false
        }
    }

    static set(key: string, value: string) {
        let storageSupported = false

        try {
            storageSupported = window.localStorage && true
        } catch (e) {
            storageSupported = false
        }

        if (storageSupported) {
            localStorage.setItem(key, value)
        } else {
            privateBrowserLocalStorage[key] = value
        }
    }
}

const privateBrowserLocalStorage: any = {
    isDarkTheme: 'false',
    isSeasonalTheme: 'false'
}
