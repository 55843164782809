import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Modal from 'shared/components/Modal'
import PollControl from './PollControl'
import PollDto from 'shared/types/PollDto'
import ModalHeader from './ModalHeader'
import { Box } from '@material-ui/core'
import { PollControlProps } from './PollControl/PollControl'

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        overflowY: 'auto',
        width: '500px',
        position: 'relative',
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(1),
            width: '100%',
            height: '100%',
            margin: 'auto',
            minWidth: '300px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
            minWidth: '300px',
            margin: 'auto'
        }
    }
}))

export type PollModalProps = PollControlProps & {
    opened: boolean
    open: (poll: PollDto) => void
    close: () => void
}

export default function PollModal(props: PollModalProps) {
    const classes = useStyles()
    const { poll } = props

    return (
        <Modal onClose={props.close} isActive={props.opened} >
            {poll && (
                <Box className={classes.content}>
                    <ModalHeader
                        title={poll.question}
                        onClose={props.close}
                        align='center'
                        titleSafeArea={true}
                    />
                    <PollControl {...props} />
                </Box>
            )}
        </Modal>
    )
}
