import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'

const useStyles = makeStyles((theme) => ({
    pollIcon: {
        color: (props: any) =>
            !props.voted ? getNotVotedColor(theme) : getVotedColor(theme)
    }
}))

const getVotedColor = (theme: Theme) => {
    return theme.palette.type === 'dark' ? '#FFDF00' : '#D4AF37'
}

const getNotVotedColor = (theme: Theme) => {
    if (theme.palette.type === 'dark') {
        return 'rgba(255, 255, 255, 0.3)'
    } else {
        return 'rgba(0, 0, 0, 0.26)'
    }
}

type Props = {
    voted: boolean
}

export default function StarIconCustom(props: Props) {
    const classes = useStyles({
        voted: props.voted
    })

    return <StarIcon className={classes.pollIcon} fontSize='small' />
}
