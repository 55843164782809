import { useAxiosContext } from 'context/AxiosContext'

export type PollOptionsApi = ReturnType<typeof useOptionsApi>

export default function useOptionsApi() {
    const http = useAxiosContext()

    const addOption = async (pollId: string, value: string) => {
        const res = await http.client.post(`/PollOptions/Add`, {
            pollId,
            value
        })
        return res.data
    }

    const getById = async (id: string) => {
        const res = await http.client.get(`/PollOptions/${id}`)
        return res.data
    }

    return { addOption, getById }
}
