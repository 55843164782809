export default class RequestCancelError extends Error {
    private _requestName: string

    constructor(requestName: string) {
        super(`Request ${requestName} canceled`)

        this._requestName = requestName
    }

    get requestName() {
        return this._requestName
    }
}