import React from 'react'
import SelectMenu from 'shared/components/SelectMenu'

export type ExpirationProps = {
    value: string
    disabled: boolean
    change: (value: string) => void
}

export default function Expiration(props: ExpirationProps) {
    const active = 'Активни'
    const expired = 'Изтекли'
    const all = 'Всички'

    const options: { value: string; label: string }[] = [
        { value: 'active', label: active },
        { value: 'expired', label: expired },
        { value: 'all', label: all }
    ]

    return (
        <SelectMenu
            id='select-research'
            fullWidth={false}
            onChange={props.change}
            options={options}
            value={props.value}
            variant='standard'
            disabled={props.disabled}
        />
    )
}
