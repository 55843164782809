import React, { useEffect } from 'react'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import { useDrawerContext } from 'context/DrawerContext'
import MenuIcon from '@material-ui/icons/Menu'
import Paths from 'pages/Paths'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import { useAuthContext } from 'context/AuthContext'
import { useDevice } from 'shared/hooks/useDevice'
import NavLink from 'Navigation/components/NavLink'
import {
    Home,
    AccountCircle,
    Create,
    CloseOutlined,
    ArrowForwardRounded,
    Forward10Outlined,
    Search
} from '@material-ui/icons'
import SeasonalImage from 'images/seasonal-image.png'
import ThemeButton from 'shared/components/ThemeButton'
import { Box } from '@material-ui/core'
import Poll from '@material-ui/icons/Poll'

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: 999,
        borderBottom: () => {
            let color
            if (theme.palette.type === 'dark') {
                color = '#228B22'
            } else {
                color = '#00FF7F'
            }

            return `1px solid ${color}`
        },
        backgroundColor: (theme.palette.background as any)['drawer']
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    listItemIcon: {
        minWidth: '35px'
    },
    icon: {
        fontSize: '30px',
        color: () => {
            let color
            if (theme.palette.type === 'dark') {
                color = '#00FF7F'
            } else {
                color = '#228B22'
            }

            return color
        }
    },
    seasonalImage: {},
    seasonalImageWrapper: {
        pointerEvents: 'none',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            top: 6,
            left: 17
        },
        [theme.breakpoints.up('sm')]: {
            top: 12,
            left: 26
        },
        zIndex: 10,
        opacity: '65%'
    }
}))

type Props = {}

export default function MobileNav(props: Props) {
    const classes = useStyles()
    const drawerContext = useDrawerContext()
    const history = useHistory()
    const device = useDevice()
    const authCtx = useAuthContext()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    useEffect(() => {
        setAnchorEl(null)
    }, [history.location])

    const onClickMenu = () => {
        drawerContext.toggle()
    }

    if (device.largeScreen) {
        return null
    }

    return (
        <>
            <AppBar elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    {/* <div className={classes.seasonalImageWrapper}>
                        <img
                            className={classes.seasonalImage}
                            src={SeasonalImage}
                            width={'30px'}
                            height={'30px'}
                        />
                    </div> */}
                    <IconButton
                        color='default'
                        aria-label='open drawer'
                        onClick={onClickMenu}
                        edge='start'
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <NavLink
                        label='Създай'
                        path={Paths.search() + '?order=topical'}
                        variant='outlined'
                        color='primary'
                        startIcon={<Search className={classes.icon} />}
                    /> */}

                    <NavLink
                        path={Paths.home()}
                        icon={<Home className={classes.icon} />}
                    />
                    <NavLink
                        path={Paths.search() + '?order=topical'}
                        icon={<Search className={classes.icon} />}
                    />
                    <NavLink
                        path={Paths.create()}
                        icon={<Create className={classes.icon} />}
                    />
                    {authCtx.isAuthenticated && (
                        <NavLink
                            path={Paths.profile()}
                            icon={<AccountCircle className={classes.icon} />}
                        />
                    )}
                    {!authCtx.isAuthenticated && (
                        <NavLink
                            path={Paths.login()}
                            icon={
                                <ArrowForwardRounded className={classes.icon} />
                            }
                        />
                    )}
                    {/* <NavLink
                        label='Създай'
                        path={Paths.create()}
                        variant='outlined'
                        size={device.smallScreen ? 'medium' : 'large'}
                        startIcon={<Create className={classes.icon} />}
                    /> */}
                    <ThemeButton />
                </Toolbar>
            </AppBar>
        </>
    )
}
