import React from 'react'
import DateF from 'utils/DateF'
import Pair from './components/Pair'
import Grid from '@material-ui/core/Grid'
import { Box } from '@material-ui/core'
type Props = {
    creationDate: Date
    lastVote: Date | null
    isActive: boolean
    hasExpiration: boolean
    expirationDate: Date | null
}

export default function PollInfo(props: Props) {
    return (
        <Box>
            <Grid container spacing={1} alignItems='baseline'>
                <Grid item xs={6}>
                    <Pair
                        label={'Създадено'}
                        value={DateF.toLocaleDateString(props.creationDate)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Pair
                        label={'Посл.глас'}
                        value={
                            props.lastVote
                                ? DateF.toLocaleDateString(props.lastVote)
                                : '--'
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <Pair
                        label={'Статус'}
                        value={props.isActive ? 'Активна' : 'Изтекла'}
                        type={props.isActive ? 'success' : 'error'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Pair
                        label={'Изтича'}
                        value={
                            props.expirationDate
                                ? DateF.toLocaleDateString(props.expirationDate)
                                : '--'
                        }
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
