import React, { useCallback } from 'react'
import Pagination from '@material-ui/lab/Pagination'
import Box from '@material-ui/core/Box'
import PaginationItem from '@material-ui/lab/PaginationItem'
import { Link, useLocation } from 'react-router-dom'

export type PaginatorProps = {
    onChangePage: (page: number) => void
    page: number
    pagesCount: number
    size?: string
}

export default function Paginator(props: PaginatorProps) {
    const onChange = useCallback(
        (e: any, page: number) => {
            props.onChangePage(page)
        },
        [props]
    )

    const location = useLocation()
    const query = new URLSearchParams(location.search)

    const onClickItem = () => {}

    let size = 'medium'
    if (props.size) {
        size = props.size
    }

    return (
        <Box display='flex' justifyContent='center'>
            <Pagination
                renderItem={(item: any) => {
                    if (item.page !== null) {
                        query.set('page', item.page.toString())
                        return (
                            <PaginationItem
                                to={`${location.pathname}?${query.toString()}`}
                                component={Link}
                                {...item}
                                onClick={onClickItem}
                                variant='outlined'
                            />
                        )
                    } else {
                        return (
                            <PaginationItem
                                variant='outlined'
                                type='start-ellipsis'
                            />
                        )
                    }
                }}
                siblingCount={1}
                boundaryCount={1}
                variant='text'
                size={size as any}
                onChange={onChange}
                page={props.page}
                count={props.pagesCount}
            />
        </Box>
    )
}
