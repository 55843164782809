import EnvironmentType from './EnvironmentType'

export default class Environment {
    static isDevelopment() {
        return process.env.REACT_APP_ENV_NAME === EnvironmentType.Development
    }

    static isStaging() {
        return process.env.REACT_APP_ENV_NAME === EnvironmentType.Staging
    }

    static isProduction() {
        return process.env.REACT_APP_ENV_NAME === EnvironmentType.Production
    }

    static get envName() {
        return process.env.REACT_APP_ENV_NAME as string
    }

    static getHost() {
        if (this.isDevelopment()) {
            return 'localhost:5000'
        } else if (this.isProduction()) {
            return 'anketi.bg'
        } else if (this.isStaging()) {
            return 'stage.anketi.bg'
        } else {
            throw new Error('Environment is not recognized: ' + this.envName)
        }
    }

    static getSiteUrl(alwaysHttps: boolean = false) {
        if (this.isDevelopment()) {
            if (alwaysHttps) {
                return 'https://localhost:3000'
            } else {
                return 'localhost:3000'
            }
        } else if (this.isProduction()) {
            return 'https://anketi.bg'
        } else if (this.isStaging()) {
            return 'https://stage.anketi.bg'
        } else {
            throw new Error('Environment is not recognized: ' + this.envName)
        }
    }

    static isFacebookInAppBrowser = () => {
        return true

        const result =
            /FB_IAB/.test(navigator.userAgent) ||
            /FBAN/.test(navigator.userAgent) ||
            /FBAV/.test(navigator.userAgent)

        return result
    }
}
