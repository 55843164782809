import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useState } from 'react'
import PollVotersCount from 'shared/components/PollVotersCount'
import { Collapse, Container, IconButton, Typography } from '@material-ui/core'
import { useThemeContext } from 'context/ThemeContext'
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone'
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone'
import LogoImage from 'images/logo.jpg'
import { useParams } from 'react-router-dom'
import Paths from 'pages/Paths'
import PollInfo from './PollInfo'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        cursor: 'pointer',
        userSelect: 'none',
        position: 'relative',
        marginTop: theme.spacing(1)
    },
    icon: {},
    iconWrapper: {
        fontSize: 0,
        position: 'absolute',
        zIndex: 6,
        top: -3
    }
}))

export type VotesLabelCollapse = {
    votersCount: number
    creationDate: Date
    lastVote: Date | null
    isActive: boolean
    hasExpiration: boolean
    expirationDate: Date | null
    privatePoll: boolean
    showLogo?: boolean
}

export default function PollInfoCollapse(props: VotesLabelCollapse) {
    const classes = useStyles()
    const { theme } = useThemeContext()

    const { id } = useParams() as any

    const [collapsed, setCollapsed] = useState(true)

    const onClickCollapse = () => {
        setCollapsed((val) => !val)
    }

    return (
        <Container
            maxWidth='xs'
            onClick={onClickCollapse}
            className={classes.wrapper}
        >
            {props.showLogo && (
                <a href={`https://anketi.bg${Paths.poll(id)}`} target='_blank'>
                    <div className={classes.iconWrapper}>
                        <img
                            className={classes.icon}
                            src={LogoImage}
                            width='28px'
                            height='28px'
                            style={{ borderRadius: '50%' }}
                        />
                    </div>
                </a>
            )}
            <Box position='absolute' top={2} right={4} zIndex={200}>
                {collapsed && <ExpandMoreTwoToneIcon fontSize='medium' />}
                {!collapsed && <ExpandLessTwoToneIcon fontSize='medium' />}
            </Box>
            <PollVotersCount votersCount={props.votersCount} />
            <Collapse in={!collapsed} timeout='auto' unmountOnExit>
                {props.privatePoll && (
                    <Box
                        textAlign='center'
                        my={1}
                        color={theme.palette.info.main}
                    >
                        <Typography color='inherit'>
                            <strong>Поверителна анкета</strong>
                        </Typography>
                    </Box>
                )}
                <PollInfo
                    creationDate={props.creationDate}
                    lastVote={props.lastVote}
                    isActive={props.isActive}
                    hasExpiration={props.hasExpiration}
                    expirationDate={props.expirationDate}
                />
            </Collapse>
        </Container>
    )
}
