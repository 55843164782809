import { Tab, Tabs } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect } from 'react'
import OtherPolls from './OtherPolls'
import _Demographics from './Demographics'
import { useAppSelector } from 'redux/hooks'
import { useThemeContext } from 'context/ThemeContext'
import { useLocation } from 'react-router-dom'
import Demographics from './Demographics'
import Share from './Share'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%'
    },
    tabsWrapper: {
        display: 'inline-flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2)
    },
    components: {
        marginTop: theme.spacing(1)
    },
    root: {
        backgroundColor: () => {
            let color
            if (theme.palette.type === 'dark') {
                color = '#228B22'
            } else {
                color = '#00FF7F'
            }

            return `1px solid ${color}`
        }
    }
}))

export default function BottomTabs() {
    const classes = useStyles()
    const pollState = useAppSelector((state) => state.pollState)
    const location = useLocation()

    const [value, setValue] = React.useState(0)

    useEffect(() => {
        setValue(0)
    }, [location.search])

    const onChange = (event: any, newValue: number) => {
        setValue(newValue)
    }

    const themeCtx = useThemeContext()

    if (!pollState.poll) {
        return null
    }

    let bgColor
    if (themeCtx.theme.palette.type === 'dark') {
        bgColor = '#228B22'
    } else {
        bgColor = '#00FF7F'
    }

    const showOtherPollsView = value === 0

    const showShareView = value === 1

    const showDemographics = value == 2

    return (
        <div className={classes.wrapper}>
            <div className={classes.tabsWrapper}>
                <Tabs
                    value={value}
                    onChange={onChange}
                    variant='scrollable'
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: bgColor,
                            height: '10px'
                        }
                    }}
                >
                    <Tab label={<Typography>Анкети</Typography>} tabIndex={0} />
                    <Tab
                        label={<Typography>Сподели</Typography>}
                        tabIndex={1}
                    />
                    {pollState.poll.type !== 'rating' && (
                        <Tab
                            label={<Typography>Демография</Typography>}
                            tabIndex={2}
                        />
                    )}
                </Tabs>
            </div>
            <div className={classes.components}>
                {showOtherPollsView && <OtherPolls />}
                {showShareView && <Share id={pollState.poll.id} />}
                {showDemographics && <Demographics />}
            </div>
        </div>
    )
}
