import Box from '@material-ui/core/Box'
import React from 'react'
import ViewModeField from '../components/ViewModeField'
import { Cookies } from 'typescript-cookie'
import dayjs from 'dayjs'

export type AccountViewModeProps = {
    onOpenPassword: () => void
    isSocialLogin: boolean
    email: string
    registration: string
    disabled: boolean
    isTemporaryAccount: boolean
}

export default function AccountViewMode(props: AccountViewModeProps) {
    let loginTypeText: string

    if (props.isSocialLogin) {
        loginTypeText =
            Cookies.get('LoginType') === 'google' ? 'Google' : 'Facebook'
    } else if (props.isTemporaryAccount) {
        loginTypeText = 'Временен акаунт'
    } else {
        loginTypeText = 'Имейл и парола'
    }

    return (
        <Box>
            {!props.isTemporaryAccount && (
                <>
                    <ViewModeField
                        disabled={props.disabled}
                        label={'Имейл'}
                        value={props.email}
                    />{' '}
                    <Box mt={1} />
                </>
            )}
            {!(props.isSocialLogin || props.isTemporaryAccount) && (
                <>
                    <ViewModeField
                        disabled={props.disabled}
                        label={'Парола'}
                        value={'***'}
                        onEdit={props.onOpenPassword}
                    />
                    <Box mt={1} />
                </>
            )}
            <ViewModeField
                disabled={props.disabled}
                label={'Дата на регистрация'}
                value={dayjs(props.registration)
                    .locale('bg')
                    .format('D MMMM YYYY, HH:mm:ss')}
            />

            <Box mt={1} />
            <ViewModeField
                disabled={props.disabled}
                label={'Влезнали сте чрез'}
                value={loginTypeText}
            />
        </Box>
    )
}
