import React, { useState } from 'react'
import { EditControls } from 'shared/components/EditControls'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { Typography } from '@material-ui/core'

export type AccountEditModeProps = {
    error: string
    saving: boolean
    onClose: () => void
    onSave: (currPass: string, newPass: string, newPass2: string) => void
    onClearError: () => void
}

export default function AccountEditMode(props: AccountEditModeProps) {
    const [inputState, setInputState] = useState({
        currentPassword: '',
        newPassword: '',
        newPassword2: ''
    })

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onClearError()

        const fieldName = e.target.name
        const value = e.target.value

        switch (fieldName) {
            case 'current-password':
                setInputState({
                    ...inputState,
                    currentPassword: value
                })
                break

            case 'new-password':
                setInputState({
                    ...inputState,
                    newPassword: value
                })
                break

            case 'new-password2':
                setInputState({
                    ...inputState,
                    newPassword2: value
                })
                break
        }
    }

    const onSave = () => {
        props.onSave(
            inputState.currentPassword,
            inputState.newPassword,
            inputState.newPassword2
        )
    }

    const tfPropsArr = [
        {
            label: 'Текуща парола',
            value: inputState.currentPassword,
            name: 'current-password'
        },
        {
            label: 'Нова парола',
            value: inputState.newPassword,
            name: 'new-password'
        },
        {
            label: 'Нова парола отново',
            value: inputState.newPassword2,
            name: 'new-password2'
        }
    ]

    return (
        <Box
            id='edit-password-mode'
            display='flex'
            flexDirection='column'
            flexGrow={1}
        >
            <Box
                id='edit-password-mode-form'
                flexGrow={1}
                display='flex'
                flexDirection='column'
            >
                <Typography variant='subtitle1' color='textSecondary'>
                    Промяна на парола
                </Typography>
                {tfPropsArr.map((tfSingleProps, i) => (
                    <TextField
                        key={i}
                        {...tfSingleProps}
                        InputLabelProps={{
                            shrink: true
                        }}
                        disabled={props.saving}
                        margin='none'
                        variant='filled'
                        type='password'
                        onChange={onChangeInput}
                        fullWidth
                        error={props.error.length > 0}
                        helperText={i === 0 ? props.error : undefined}
                    />
                ))}
            </Box>
            <br />
            <EditControls
                disabled={props.saving}
                onSave={onSave}
                onClose={props.onClose}
            />
        </Box>
    )
}
