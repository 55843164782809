import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Paths from 'pages/Paths'
import Axios, { AxiosInstance } from 'axios'
import AxiosProxy from 'utils/AxiosProxy'

type AxiosContext = {
    client: AxiosProxy
    axios: AxiosInstance
}

export function useAxiosContext(): AxiosContext {
    const context = useContext(AxiosContext)
    if (context === undefined) {
        throw new Error('useAxios must be within AxiosProvider')
    }

    return context
}

const AxiosContext = React.createContext<AxiosContext | undefined>(undefined)

const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // withCredentials is used to set httpOnly cookie. Without it cannot be set
    // Very detailed information: https://stackoverflow.com/questions/3342140/cross-domain-cookies
    withCredentials: true,
    headers: {
        'content-type': 'application/json; charset=utf-8',
        ActionKey: process.env.REACT_APP_ACTION_KEY
    }
})

axios.defaults.headers.common['content-type'] =
    'application/json; charset=utf-8'

const axiosProxy = new AxiosProxy(axios, 350)

export const AxiosProvider = (props: PropsWithChildren<{}>) => {
    const history = useHistory()

    useEffect(() => {
        axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (
                    error?.message === 'Network Error' ||
                    error?.response?.status === 404
                ) {
                    history.push(Paths.error404())
                } else if (error?.response?.status === 500) {
                    history.push(Paths.error500())
                }

                return Promise.reject(error)
            }
        )
    }, [])

    return (
        <AxiosContext.Provider
            value={{
                client: axiosProxy,
                axios: axios
            }}
        >
            {props.children}
        </AxiosContext.Provider>
    )
}
