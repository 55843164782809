export default class DateF {
    static getYesterday(): Date {
        const yesterday = new Date()
        yesterday.setDate(new Date().getDate() - 1)
        return yesterday
    }

    static getAge(birthdate: Date): number {
        var ageDifMs = Date.now() - birthdate.getTime()
        var ageDate = new Date(ageDifMs) // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970)
    }

    static toShortDateString(date: Date): string {
        try {
            return new Date(date).toISOString().substring(0, 10)
        } catch (e) {
            throw e
        }
    }

    static toLocaleDateString(value: Date) {
        return new Date(value).toLocaleDateString('bg-BG') // en-US
    }

    static toISO(date: Date | null) {
        if (date === null) {
            return undefined
        }

        let d = new Date(date)
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        let year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
    }

    static isValidDate(date: Date) {
        return new Date(date).toString() !== 'Invalid Date'
    }
}
