import React from 'react'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

type Props = {
    label: string
    value: string
    type?: 'success' | 'error'
}

const useStyles = makeStyles((theme) => ({
    valueSuccess: {
        color: 'green'
    },
    valueError: {
        color: 'red'
    },
    valueNormal: {
        color: theme.palette.text.secondary
    },
    label: {
        textAlign: 'center',
        color: theme.palette.text.primary,
        width: '100%',
        whiteSpace: 'nowrap'
    },
    value: {
        textAlign: 'center',
        width: '100%',
        whiteSpace: 'nowrap'
    }
}))

export default function Pair(props: Props) {
    const classes = useStyles()

    const label = 'body2'
    const value = 'body2'

    let colorClass: string = ''

    if (props.type === 'success') {
        colorClass = classes.valueSuccess
    } else if (props.type === 'error') {
        colorClass = classes.valueError
    } else if (!props.type) {
        colorClass = classes.valueNormal
    }

    return (
        <Box display='flex' flexDirection='column' alignItems='flex-start'>
            <Typography variant={label} className={classes.label}>
                {props.label}&nbsp;
            </Typography>
            <Typography
                variant={value}
                className={classes.value + ' ' + colorClass}
            >
                {!props.type && <Box component='span'>{props.value}</Box>}
                {props.type && (
                    <Box component='span' fontWeight='bold'>
                        {props.value}
                    </Box>
                )}
            </Typography>
        </Box>
    )
}
