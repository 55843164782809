import { Paper, makeStyles } from '@material-ui/core'
import React from 'react'
import { PropsWithChildren } from 'react'

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            position: 'relative',
            maxWidth: '500px',
            margin: 'auto'
        },
        pollBackground: {
            backgroundColor: theme.palette.background.paper,
            position: 'absolute',
            opacity: '0.5',
            width: '100%',
            height: '100%',
            borderRadius: '8px'
        },
        paper: {
            position: 'relative',
            backgroundColor: 'transparent',
            border: () => {
                let color
                if (theme.palette.type === 'dark') {
                    color = '#228B22'
                } else {
                    color = '#00FF7F'
                }

                return `1px solid ${color}`
            },
            borderRadius: '8px'
        }
    }
})

const PollBackground = (props: PropsWithChildren<{}>) => {
    const classes = useStyles()
    return (
        <div className={classes.wrapper}>
            <div className={classes.pollBackground} />
            <Paper elevation={0} className={classes.paper}>
                {props.children}
            </Paper>
        </div>
    )
}

export default PollBackground
