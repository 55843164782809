import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import AddButton from './AddButton'
import { EditControls } from 'shared/components/EditControls'
import LoadingIndicator from 'shared/components/LoadingIndicator'

type Props = {
    onSave: (value: string) => void
    onChangeEditMode?: (editMode: boolean) => void
    onClearError: () => void
    editDisabled: boolean
    saving: boolean
    error: string
}

export default function AddOptionControl(props: Props) {
    const [edit, setEdit] = useState(false)
    const [optionValue, setOptionValue] = useState('')

    useEffect(() => {
        if (props.saving === false) {
            if (!(props.error.length > 0)) {
                setEdit(false)
            }
            setOptionValue('')
        }
    }, [props.saving])

    useEffect(() => {
        if (props.onChangeEditMode) {
            props.onChangeEditMode(edit)
        }
    }, [edit])

    const onClickSave = () => {
        props.onSave(optionValue)
    }

    const onChangeTextfield = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onClearError()
        setOptionValue(e.target.value)
    }

    const onAdd = () => {
        setEdit(true)
    }

    const onClose = () => {
        props.onClearError()
        setOptionValue('')
        setEdit(false)
    }

    return (
        <>
            {!edit && (
                <Box>
                    <AddButton disabled={props.editDisabled} onClick={onAdd} />
                </Box>
            )}
            {edit && (
                <Box width='100%' position='relative'>
                    <LoadingIndicator loading={props.saving} top={1} />
                    <TextField
                        error={props.error.length > 0}
                        helperText={props.error}
                        size='small'
                        variant='outlined'
                        fullWidth
                        placeholder={'Въведете опция...'}
                        value={optionValue}
                        onChange={onChangeTextfield}
                        disabled={props.saving}
                    />
                    <Box mt={1}>
                        <EditControls
                            disabled={props.saving}
                            onClose={onClose}
                            onSave={onClickSave}
                        />
                    </Box>
                </Box>
            )}
        </>
    )
}
