import React, { useCallback } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useDevice } from 'shared/hooks/useDevice'
import { Box, Button, Divider, Grid, Paper } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import { useHistory } from 'react-router-dom'
import Paths from 'pages/Paths'
import { Poll, Search } from '@material-ui/icons'
import StarRows from './StarRows'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8),
            paddingTop: theme.spacing(8)
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(2)
        },
        [theme.breakpoints.up('xs')]: {
            paddingBottom: '20px'
        },
        [theme.breakpoints.only('xs')]: {
            paddingBottom: '20px'
        },
        [theme.breakpoints.only('sm')]: {
            paddingBottom: '99px'
        },
        [theme.breakpoints.only('md')]: {
            paddingBottom: '80px'
        },
        paddingBottom: '120px'
        // height: '100%',
        // background: () => {
        //     if (theme.palette.type === 'light') {
        //         return 'linear-gradient(to right, #e4e5f1, #fafafa, #d2d3db)'
        //     }

        //     return 'linear-gradient(to right, #2d2d30, #3e3e42, #1e1e1e)'
        // }
        // position: 'absolute',
        // top: 0,
        // bottom: 0,
        // left: 0,
        // right: 0
    },
    sidesWrapper: {
        display: 'flex',
        // justifyContent: 'space-between',
        flexDirection: 'row',
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column'
        }
    },
    rightSide: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            width: '50%'
        }
    },
    headWrapper: {},
    mainTypo: {
        textAlign: 'left',
        fontSize: '4rem',
        fontWeight: 900,
        [theme.breakpoints.down('sm')]: {
            fontSize: '3.3rem'
        }
    },
    titleTypo: {
        color: theme.palette.text.primary
        // color: '#fff',
        // textShadow:
        //     '-1px -1px 0 #000,  1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000'
    },
    starRowsWrapper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(5),
        maxWidth: '800px'
        // margin: '0 auto'
    },
    anonymWrapper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    anonymTypo: {
        fontSize: '2.0rem',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.7rem'
        },
        color:
            theme.palette.type === 'dark'
                ? theme.palette.info.light
                : theme.palette.info.dark,
        // fontWeight: 700,
        marginTop: theme.spacing(2),
        textAlign: 'left'
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    createButton: {
        padding: theme.spacing(2),
        minWidth: '180px',
        marginTop: (props: any) =>
            !props.isSmallDisplay ? 0 : theme.spacing(1),
        marginLeft: (props: any) =>
            props.isSmallDisplay ? 0 : theme.spacing(1)
    },
    actionButton: {
        borderRadius: '60px',
        padding: theme.spacing(3),
        minWidth: '180px',
        fontSize: '30px',
        fontWeight: 900,
        marginRight: (props: any) =>
            props.isSmallDisplay ? 0 : theme.spacing(1)
    },
    actionButtonIcon: {
        fontSize: '50px !important'
    },
    vpnInfoWrapper: {
        marginTop: '20px',
        width: '100%'
        // margin: '0 auto'
    },
    vpnInfo: {
        fontSize: '2.0rem',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.7rem'
        },
        color:
            theme.palette.type === 'dark'
                ? theme.palette.error.light
                : theme.palette.error.dark,
        marginTop: theme.spacing(2),
        textAlign: 'left'
    },
    vpnInfoPaper: {
        padding: theme.spacing(1)
    }
}))

export default function Layout() {
    const device = useDevice()
    const classes = useStyles({ isSmallDisplay: device.smallScreen })
    const history = useHistory()

    const onClickCreate = useCallback(() => {
        history.push(Paths.create())
    }, [])

    const onClickSearch = useCallback(() => {
        history.push({
            pathname: Paths.search(),
            search: 'order=topical'
        })
    }, [])

    return (
        <div className={classes.wrapper}>
            <div className={classes.sidesWrapper}>
                <div>
                    <div className={classes.headWrapper}>
                        <Typography variant='h2' className={classes.mainTypo}>
                            <span className={classes.titleTypo}>Безплатен</span>
                            <br />
                            <span className={classes.titleTypo}>сайт за</span>
                            <br />
                            <span className={classes.titleTypo}>анкети</span>
                        </Typography>
                    </div>

                    <div className={classes.starRowsWrapper}>
                        <StarRows />
                    </div>
                </div>
                <div className={classes.rightSide}>
                    <Box className={classes.buttonsWrapper}>
                        <Button
                            variant='contained'
                            color='primary'
                            size='large'
                            fullWidth={device.smallScreen}
                            className={classes.actionButton}
                            startIcon={
                                <Poll className={classes.actionButtonIcon} />
                            }
                            onClick={onClickSearch}
                            data-test='search_button'
                        >
                            Анкети
                        </Button>
                        <Box mt={5}></Box>
                        <Button
                            fullWidth={device.smallScreen}
                            variant='outlined'
                            color='primary'
                            size='large'
                            className={classes.actionButton}
                            startIcon={
                                <CreateIcon
                                    className={classes.actionButtonIcon}
                                />
                            }
                            onClick={onClickCreate}
                            data-test='create_button'
                        >
                            Създай
                        </Button>
                    </Box>
                </div>
            </div>
            <Box my={5}>
                <Divider />
            </Box>
            <div className={classes.anonymWrapper}>
                <Typography color='primary' className={classes.anonymTypo}>
                    Гласуването е анонимно.
                </Typography>
            </div>
            <div className={classes.vpnInfoWrapper}>
                <Typography color='error' className={classes.vpnInfo}>
                    Акаунтите и гласовете създадени чрез vpn ще бъдат изтрити!
                </Typography>
            </div>
        </div>
    )
}
