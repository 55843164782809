import React, { PropsWithChildren, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuthContext } from 'context/AuthContext'
import Paths from 'pages/Paths'

type Props = PropsWithChildren<{
    path: string
    exact: boolean
}>

export default function RestrictedRoute(props: Props) {
    const authCtx = useAuthContext()

    return (
        <Route path={props.path} exact={props.exact}>
            {authCtx.isAuthenticated ? (
                <Redirect to={Paths.search()} />
            ) : (
                props.children
            )}
        </Route>
    )
}
