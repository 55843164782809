export class Query {
    value = ''
    count = 0
}

export default class QueryBuilder {
    query = new Query()

    addSimple(
        key: string,
        value: string | number | boolean | undefined | null
    ) {
        if (value === undefined || value === null) return

        if (this.query.value.length === 0) {
            this.query.value += '/?'
        } else {
            this.query.value += '&'
        }

        const curr = key + '=' + value
        this.query.value += curr
        this.query.count++
    }

    addArray(key: string, values: string[]) {
        if (values === undefined || values === null) return

        if (this.query.value.length === 0) {
            this.query.value += '/?'
        } else {
            this.query.value += '&'
        }

        values.forEach((val, i) => {
            const curr = key + '=' + val
            this.query.value += curr
            this.query.count++
            if (i < values.length - 1) {
                this.query.value += '&'
            }
        })
    }
}
