import { useContext } from 'react'
import PageContext from '../context/PageContextType'
import Context from '../context'

export function usePageContext(): PageContext {
    const context = useContext(Context)
    if (context === undefined) {
        throw new Error('Context must be used within provider')
    }
    return context
}
