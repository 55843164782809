import React from 'react'
import SelectMenu from 'shared/components/SelectMenu'

export type OrderProps = {
    value: string
    disabled: boolean
    change: (value: string) => void
}

export default function Order(props: OrderProps) {
    const labels = {
        mostVoted: 'Най-гласувани',
        lastVoted: 'Посл.гласувани',
        newest: 'Най-нови',
        topical: 'Актуални'
    }

    const options: { value: string; label: string }[] = [
        { value: 'most-voted', label: labels.mostVoted },
        { value: 'last-voted', label: labels.lastVoted },
        { value: 'newest', label: labels.newest },
        { value: 'topical', label: labels.topical }
    ]

    return (
        <SelectMenu
            id='select-order'
            fullWidth={false}
            options={options}
            variant='standard'
            onChange={props.change}
            value={props.value}
            disabled={props.disabled}
        />
    )
}
