import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Paths from 'pages/Paths'
import React from 'react'
import { useHistory } from 'react-router'
import { useDevice } from 'shared/hooks/useDevice'
import FacebookIcon from '@material-ui/icons/Facebook'

const useStyles = makeStyles((theme) => ({
    footer: {
        position: 'relative',
        left: 0,
        borderTop: () => {
            let color
            if (theme.palette.type === 'dark') {
                color = '#228B22'
            } else {
                color = '#00FF7F'
            }

            return `1px solid ${color}`
        },
        bottom: 0,
        [theme.breakpoints.down('sm')]: {
            height: 'auto'
        },

        width: (props: any) =>
            props.largeScreen ? `calc(100% - 250px)` : '100%', //
        marginLeft: (props: any) => (props.largeScreen ? '250px' : 0), //
        zIndex: theme.zIndex.drawer - 1,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        backgroundColor: (theme.palette.background as any)['drawer']
    },
    link: {
        color: theme.palette.type === 'dark' ? 'HotPink' : 'blue'
    },
    linkTypo: {
        fontSize: '1.1rem'
    },
    icon: {
        color: theme.palette.type === 'dark' ? 'white' : '#4267b1'
    },
    facebookPage: {
        visibility: 'hidden'
    },
    connectionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left'
    },
    emailWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '1px'
    },
    linkWrapper: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'right'
    },
    termsLink: {
        marginBottom: theme.spacing(1)
    },
    privacyLink: {
        marginBottom: theme.spacing(1)
    }
}))

export default function Footer() {
    const history = useHistory()
    const device = useDevice()

    const classes = useStyles({
        largeScreen: device.largeScreen
    })

    const onClickPrivacy = (e: React.MouseEvent) => {
        e.preventDefault()
        history.push(Paths.privacy())
    }

    const onClickTerms = (e: React.MouseEvent) => {
        e.preventDefault()
        history.push(Paths.terms())
    }

    const onClickFacebook = (e: React.MouseEvent) => {
        e.preventDefault()
        window.open(
            'https://www.facebook.com/Anketibg-103420949030586',
            '_blank'
        )
    }

    return (
        <footer className={classes.footer}>
            <Grid container justifyContent='center' spacing={1}>
                <Grid item xs={6} sm={6}>
                    <div className={classes.connectionWrapper}>
                        <Typography
                            variant='body2'
                            className={classes.linkTypo}
                        >
                            <strong>За връзка</strong>
                        </Typography>
                        <div className={classes.emailWrapper}>
                            <Link
                                href='mailto:mail@anketi.bg'
                                className={classes.link}
                            >
                                <Typography
                                    variant='body2'
                                    className={classes.linkTypo}
                                >
                                    <bdo dir='rtl'>gb.itekna@liam</bdo>
                                </Typography>
                            </Link>
                            &nbsp;&nbsp;
                            <Typography variant='body2'>|</Typography>
                            &nbsp;&nbsp;
                            <a
                                className={classes.link}
                                href='https://www.facebook.com/Anketibg-103420949030586'
                                onClick={onClickFacebook}
                            >
                                <FacebookIcon className={classes.icon} />
                                <span className={classes.facebookPage}>
                                    Facebook страница
                                </span>
                            </a>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <div className={classes.linkWrapper}>
                        <Link
                            href={Paths.terms()}
                            onClick={onClickTerms}
                            className={classes.link + ' ' + classes.termsLink}
                        >
                            <Typography
                                variant='body2'
                                className={classes.linkTypo}
                            >
                                {'Условия'}
                            </Typography>
                        </Link>

                        <Link
                            href={Paths.privacy()}
                            onClick={onClickPrivacy}
                            className={classes.link + ' ' + classes.privacyLink}
                        >
                            <Typography
                                variant='body2'
                                className={classes.linkTypo}
                            >
                                {'Поверителност'}
                            </Typography>
                        </Link>
                    </div>
                </Grid>
            </Grid>
        </footer>
    )
}
