import React, { PropsWithChildren } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuthContext } from 'context/AuthContext'

const PrivateRoute: React.FC<PropsWithChildren<{
    path: string
    exact: boolean
}>> = (props) => {
    const authCtx = useAuthContext()

    return authCtx.getAuthenticated() ? (
        <Route path={props.path} exact={props.exact}>
            {props.children}
        </Route>
    ) : (
        <Redirect to='/login' />
    )
}
export default PrivateRoute
