import { Alert, Color } from '@material-ui/lab'
import React from 'react'
import { makeStyles, Snackbar as MuiSnackbar } from '@material-ui/core'
import { useSnackbarContext } from 'context/SnackbarContext'
import { useDevice } from 'shared/hooks/useDevice'

const useStyles = makeStyles((theme) => ({
    alert: {
        userSelect: 'none',
        color: 'black',
        fontSize: '1.05rem'
    },
    snackbar: {
        zIndex: 9999999999
    }
}))

export default function AppSnackbar() {
    const classes = useStyles()
    const snackbarCtx = useSnackbarContext()
    const device = useDevice()

    const onCloseSnackbar = () => {
        snackbarCtx.close()
    }

    const onClickClose = () => {
        snackbarCtx.close()
    }

    let vertical = 'bottom' as any
    if (device.smallScreen) {
        vertical = 'bottom'
    }

    return (
        <MuiSnackbar
            anchorOrigin={{ vertical: vertical, horizontal: 'center' }}
            className={classes.snackbar}
            open={snackbarCtx.opened}
            autoHideDuration={4000}
            onClose={onCloseSnackbar}
            ClickAwayListenerProps={{
                onClickAway(event) {}
            }}
        >
            <Alert
                className={classes.alert}
                onClose={onClickClose}
                variant='filled'
                severity={snackbarCtx.severity as Color}
            >
                <strong>{snackbarCtx.text}</strong>
            </Alert>
        </MuiSnackbar>
    )
}
