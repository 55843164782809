import { Box, Chip } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useDevice } from 'shared/hooks/useDevice'
import StarIcon from '@material-ui/icons/Star'
import PollIcon from '@material-ui/icons/Poll'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexWrap: 'nowrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
        overflow: 'auto',
        maxWidth: '100%'
    },
    chip: {
        margin: theme.spacing(0.5)
    },
    starIcon: {
        color: theme.palette.type === 'dark' ? '#FFDF00' : '#D4AF37',
        marginLeft: '5px',
        marginBottom: '1px'
    },
    pollIcon: {
        color:
            theme.palette.type === 'dark'
                ? theme.palette.info.light
                : theme.palette.info.dark,
        marginLeft: '5px',
        marginBottom: '1px'
    },
    chipLabel: {
        paddingLeft: '5px'
    }
}))

const getLabel = (type: string) => {
    switch (type) {
        case 'polls':
            return 'Анкети с опции'
        case 'ratings':
            return 'Анкети с рейтинг'
        default:
            return 'whoops'
    }
}

export type PollTypesProps = {
    changePollType: (value: string) => void
    disabled: boolean
    value: string
}

export default function PollTypes(props: PollTypesProps) {
    const classes = useStyles()

    const device = useDevice()

    return (
        <Box
            className={classes.wrapper}
            justifyContent={device.smallScreen ? 'center' : 'center'}
            my={1}
        >
            {['polls', 'ratings'].map((value, i) => {
                const variant = value === props.value ? 'default' : 'outlined'

                return (
                    <Chip
                        key={i}
                        size='medium'
                        variant={variant}
                        disabled={props.disabled}
                        icon={
                            <>
                                {value === 'polls' && <Poll />}
                                {value === 'ratings' && <Star />}
                            </>
                        }
                        label={getLabel(value)}
                        onClick={() => {
                            props.changePollType(value)
                        }}
                        classes={{
                            root: classes.chip,
                            label: classes.chipLabel
                        }}
                    />
                )
            })}
        </Box>
    )
}

const Star = () => {
    const classes = useStyles()
    return <StarIcon fontSize='small' className={classes.starIcon} />
}
const Poll = () => {
    const classes = useStyles()
    return <PollIcon fontSize='small' className={classes.pollIcon} />
}
