import React, { useEffect, useState } from 'react'
import PollOptionDto from 'shared/types/PollOptionDto'
import Option from './Option'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AddOptionControl from './AddOptionControl'
import PollRules from 'server-rules/PollRules'
import { useStateIfMounted } from 'use-state-if-mounted'
import { Typography } from '@material-ui/core'
import { useAuthContext } from 'context/AuthContext'

type Props = {
    options: PollOptionDto[]
    votesCount: number
    votersCount: number
    hasVoted: boolean
    voting: boolean
    savingOption: boolean
    optionError: string
    dynamicOptionsEnabled: boolean
    pollType: string
    hiddenResult: boolean
    isActive: boolean
    isPublished: boolean
    clearOptionError: () => void
    vote: (option: PollOptionDto) => void
    saveOption: (value: string) => void
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        opacity: (props: any) => props.opacity,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    promptBox: {
        //
    },
    promptLabel: {
        color:
            theme.palette.type === 'dark'
                ? theme.palette.info.light
                : theme.palette.info.dark
    },
    hidden: {
        visibility: 'hidden'
    }
}))

export default function OptionsVoteControl(props: Props) {
    const ref = React.createRef<HTMLDivElement>()
    const authCtx = useAuthContext()
    const disabled =
        props.voting ||
        props.savingOption ||
        !props.isActive ||
        !props.isPublished

    const [votingOption, setVotingOption] = useStateIfMounted<
        PollOptionDto | undefined
    >(undefined)
    const classes = useStyles({
        opacity: disabled ? 0.7 : 1
    })
    const boxProps = { ref }

    useEffect(() => {
        if (props.voting === false) {
            setVotingOption(undefined)
        }
    }, [props.voting])

    const onVote = (option: PollOptionDto) => {
        setVotingOption(option)
        props.vote(option)
    }

    return (
        <Box {...boxProps} className={classes.wrapper}>
            {props.options.map((option, i) => {
                const loading = props.voting && votingOption?.id === option.id

                return (
                    <Box key={i}>
                        <Option
                            loading={loading}
                            hiddenResult={props.hiddenResult}
                            selectionType={props.pollType}
                            disabled={
                                disabled ||
                                (props.pollType === 'single-selection' &&
                                    props.hasVoted) ||
                                (props.pollType === 'multi-selection' &&
                                    option.hasVoted)
                            }
                            option={option as PollOptionDto}
                            votesCount={props.votesCount}
                            votersCount={props.votersCount}
                            onVote={onVote}
                        />
                        {i < props.options.length - 1 && <Box mb={2} />}
                    </Box>
                )
            })}
            {props.dynamicOptionsEnabled && (
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                    width='100%'
                    height='95px'
                >
                    <AddOptionControl
                        onClearError={props.clearOptionError}
                        error={props.optionError}
                        editDisabled={
                            PollRules.MaxOptionsCount ===
                                props.options.length ||
                            !authCtx.getAuthenticated()
                        }
                        saving={props.savingOption}
                        onSave={props.saveOption}
                    />
                </Box>
            )}
            {props.hiddenResult && (
                <Box className={classes.promptBox} mb={1}>
                    <Typography
                        variant='h6'
                        className={classes.promptLabel}
                        align='center'
                    >
                        Гласувай, за да видиш резултатите!
                    </Typography>
                </Box>
            )}
        </Box>
    )
}
