import React, { PropsWithChildren, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import MuiModal from '@material-ui/core/Modal'
import makeStyles from '@material-ui/core/styles/makeStyles'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import { Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        overflowY: 'scroll',
        display: 'flex',
        padding: theme.spacing(1)
    },
    box: {
        margin: 'auto',
        maxWidth: (props: any) => props.maxWidth,
        [theme.breakpoints.down('xs')]: {
            margin: 'auto',
            minWidth: 'auto'
        },
        padding: theme.spacing(0),
        position: 'relative',
        backgroundColor: (theme.palette.background as any)['drawer'],
        border: () => {
            let color
            if (theme.palette.type === 'dark') {
                color = theme.palette.success.light
            } else {
                color = theme.palette.success.dark
            }

            return `2px solid ${color}`
        },
        borderRadius: '8px'
    },
    closeButton: {
        position: 'absolute',
        right: 20,
        top: 10
    },
    title: {}
}))

type Props = {
    onClose: () => void
    isActive: boolean
    maxWidth?: string
}

export default function Modal(props: PropsWithChildren<Props>) {
    const classes = useStyles({ maxWidth: props.maxWidth || '500px' })

    return (
        <MuiModal
            className={classes.wrapper}
            open={props.isActive}
            onClose={props.onClose}
            keepMounted={false}
        >
            <Paper elevation={0} className={classes.box}>
                {props.children}
            </Paper>
        </MuiModal>
    )
}
