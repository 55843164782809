import React, { useEffect } from 'react'
import { usePageContext } from './usePageContext'
import AccountEditMode from '../views/AccountEditMode'
import ActivityView from '../views/Activity'

export default function Activity() {
    const pageContext = usePageContext()

    return <ActivityView {...pageContext.activity} />
}
