import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Modal from 'shared/components/Modal'
import { Box, Button, Typography } from '@material-ui/core'
import LoadingIndicator from 'shared/components/LoadingIndicator'
import ModalHeader from 'shared/components/ModalHeader'
import DeleteIcon from '@material-ui/icons/Delete'
import { useStateIfMounted } from 'use-state-if-mounted'

const useStyles = makeStyles((theme) => ({
    screen: {},
    content: {
        position: 'relative',
        padding: theme.spacing(1)
    },
    closeButton: {
        border: `1px solid ${theme.palette.divider}`,
        position: 'absolute',
        right: 20,
        top: 10,
        color: theme.palette.text.hint
    }
}))

export type ModalDeleteAccountProps = {
    opened: boolean
    deleting: boolean
    onClose: () => void
    onDeleteAccount: () => void
}

let intervalId: any

export default function ModalDeleteAccount(props: ModalDeleteAccountProps) {
    const classes = useStyles()

    const [remaining, setRemaing] = useStateIfMounted(3)

    useEffect(() => {
        if (remaining === 0) {
            clearInterval(intervalId)
        }
    }, [remaining])

    useEffect(() => {
        if (props.opened) {
            setRemaing(3)
            intervalId = setInterval(() => {
                setRemaing((val) => val - 1)
            }, 1000)
        } else {
            setRemaing(3)
            clearInterval(intervalId)
        }
    }, [props.opened])

    return (
        <Modal onClose={props.onClose} isActive={props.opened}>
            <Box className={classes.screen}>
                <Box className={classes.content}>
                    <LoadingIndicator loading={props.deleting} top={40} />
                    <ModalHeader
                        title='Сигурни ли сте, че искате да изтриете акаунта си?'
                        onClose={props.onClose}
                        disabled={props.deleting}
                        align='center'
                        titleSafeArea
                    />
                    <Typography paragraph variant='body2' color='textSecondary'>
                        С изтриването на акаунта си ще се изтрият вашите
                        гласове.
                    </Typography>
                    <Box textAlign='center'>
                        <Button
                            onClick={props.onDeleteAccount}
                            disabled={props.deleting || remaining > 0}
                            startIcon={<DeleteIcon />}
                            variant='contained'
                            color='secondary'
                            disableElevation
                        >
                            {remaining > 0 && `(${remaining}) `}Изтрий
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}
