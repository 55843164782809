import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { AppDispatch } from '../../../redux/store'
import { ViewState } from './View'
import PollDto from 'shared/types/PollDto'

const initialState: ViewState = {
    poll: null,
    optionError: '',
    savingOption: false,
    voting: false,
    connectModalOpened: false
}

const slice = createSlice({
    name: 'PollReducer',
    initialState,
    reducers: {
        reset: () => initialState,
        setOptionError(state, action: PayloadAction<string>) {
            state.optionError = action.payload
        },
        setSavingOption(state, action: PayloadAction<boolean>) {
            state.savingOption = action.payload
        },
        setVoting(state, action: PayloadAction<boolean>) {
            state.voting = action.payload
        },
        setPoll(state, action: PayloadAction<PollDto | null>) {
            state.poll = action.payload
        }
    }
})

export default slice
