import { useAxiosContext } from 'context/AxiosContext'
import { useSnackbarContext } from 'context/SnackbarContext'

export default function useAccountApi() {
    const http = useAxiosContext()
    const snackbarCtx = useSnackbarContext()

    const changeName = async (name: string) => {
        const res = await http.client.post(`/Account/ChangeName`, {
            name
        })
        return res.data
    }

    const changeGender = async (gender: string | null) => {
        const res = await http.client.post(`/Account/ChangeGender`, {
            gender
        })
        return res.data
    }

    const changeBirthday = async (birthday?: string) => {
        const res = await http.client.post(`/Account/ChangeBirthday`, {
            birthday
        })

        return res.data
    }

    const changePassword = async (
        currentPassword: string,
        newPassword: string,
        newPassword2: string
    ) => {
        const res = await http.client.post(`/Account/ChangePassword`, {
            currentPassword,
            newPassword,
            newPassword2
        })
        return res.data
    }

    const deleteMyAccount = async () => {
        const res = await http.client.delete(`/Account/DeleteMyAccount`)
        return res.data
    }

    return {
        changeName,
        changeBirthday,
        changePassword,
        changeGender,
        deleteMyAccount
    }
}
