import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Api } from 'shared/hooks/useApi'
import { OtherPollsState } from './View'
import OtherPollDto from 'shared/types/OtherPollDto'

const initialState: OtherPollsState = {
    isPublished: false,
    polls: null,
    privatePoll: false
}

const slice = createSlice({
    name: 'OtherPollsReducer',
    initialState,
    reducers: {
        reset: () => initialState,
        setPolls(state, action: PayloadAction<OtherPollDto[]>) {
            state.polls = action.payload
        },
        setPrivatePoll(state, action: PayloadAction<boolean>) {
            state.privatePoll = action.payload
        },
        setIsPublished(state, action: PayloadAction<boolean>) {
            state.isPublished = action.payload
        }
    }
})

export default slice
