import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Helmet } from 'react-helmet'

export type PageMetaDataProps = {
    h1: string
    title: string
    metas: { name: string; content: string }[]
    links: { rel: string; href: string }[]
}

const useStyles = makeStyles((theme) => ({
    h1: {
        width: '1px',
        height: '1px',
        overflow: 'hidden',
        position: 'absolute'
    }
}))

export default function PageMetaData(props: PageMetaDataProps) {
    const classes = useStyles()

    return (
        <>
            <h1 className={classes.h1}>{props.h1}</h1>
            <Helmet>
                <title>{props.title}</title>
                {props.metas.map((meta) => {
                    return (
                        <meta
                            key={meta.name}
                            name={meta.name}
                            content={meta.content}
                        />
                    )
                })}
                {props.links.map((link) => {
                    return (
                        <link key={link.rel} rel={link.rel} href={link.href} />
                    )
                })}
            </Helmet>
        </>
    )
}
