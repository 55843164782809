/// <reference types="react-scripts" />
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import 'styles/index.css'
import App from './App'
import { AuthProvider } from './context/AuthContext'
import { NagaThemeProvider } from './context/ThemeContext'
import initFbSdk from 'utils/initFbSdk'
import { DrawerProvider } from 'context/DrawerContext'
import { AxiosProvider } from 'context/AxiosContext'
import { LoginModalProvider } from 'context/LoginModalContext'
import { SnackbarProvider } from 'context/SnackbarContext'
import { Provider } from 'react-redux'
import store from './redux/store'
import { GoogleOAuthProvider } from '@react-oauth/google'

const root = document.getElementById('root')
const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(
    navigator.userAgent
)

const renderApp = (fbAccessToken?: string) => {
    ReactDOM.render(
        <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
        >
            <Router>
                <Provider store={store}>
                    <AxiosProvider>
                        <NagaThemeProvider>
                            <SnackbarProvider>
                                <DrawerProvider>
                                    <AuthProvider>
                                        <LoginModalProvider>
                                            <App
                                                fbAccessToken={fbAccessToken}
                                            />
                                        </LoginModalProvider>
                                    </AuthProvider>
                                </DrawerProvider>
                            </SnackbarProvider>
                        </NagaThemeProvider>
                    </AxiosProvider>
                </Provider>
            </Router>
        </GoogleOAuthProvider>,
        root
    )
}

if (!isBot) {
    initFbSdk().then((fbAccessToken?: string) => renderApp(fbAccessToken))
} else {
    renderApp(undefined)
}
