import { useAxiosContext } from 'context/AxiosContext'

export default function useVotesApi() {
    const http = useAxiosContext()

    const voteOption = async (pollId: string, optionId: string) => {
        const res = await http.client.post(
            `/Votes/Option`,
            {
                pollId,
                optionId
            },
            undefined,
            false
        )
        return res.data
    }

    const voteRating = async (pollId: string, rating: number) => {
        const res = await http.client.post(
            `/Votes/rating`,
            {
                pollId,
                rating
            },
            undefined,
            false
        )
        return res.data
    }

    return { voteOption, voteRating }
}
