import React, { PropsWithChildren } from 'react'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import { PropTypes, Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'

const useStyles = makeStyles((theme) => ({
    closeButton: {
        color: theme.palette.text.hint
    },
    title: {
        lineHeight: 1.3
    }
}))

type Props = {
    onClose?: () => void
    title: string
    align?: PropTypes.Alignment
    variant?: 'inherit' | Variant | undefined
    disabled?: boolean
    titleSafeArea?: boolean
}

export default function ModalHeader(props: PropsWithChildren<Props>) {
    const classes = useStyles()

    const align = props.align || 'left'
    const variant = props.variant || 'h6'

    if (props.titleSafeArea) {
        // Put close button and title on their own row
        return (
            <Box mb={1}>
                {props.onClose && (
                    <Box textAlign='right'>
                        <IconButton
                            className={classes.closeButton}
                            size='small'
                            onClick={props.onClose}
                            disabled={props.disabled}
                        >
                            <CancelIcon fontSize='large' />
                        </IconButton>
                    </Box>
                )}
                <Typography
                    color={props.disabled ? 'textSecondary' : 'textPrimary'}
                    variant={variant}
                    align={align}
                    className={classes.title}
                >
                    <strong>{props.title}</strong>
                </Typography>
            </Box>
        )
    } else {
        // Put close button and title on single row
        return (
            <Box
                mb={0}
                mt={0}
                display='flex'
                justifyContent={'space-between'}
                alignItems='center'
            >
                <Box ml='4px'>
                    <Typography
                        color={props.disabled ? 'textSecondary' : 'textPrimary'}
                        variant={variant}
                        align={align}
                        className={classes.title}
                    >
                        <strong>{props.title}</strong>
                    </Typography>
                </Box>
                {props.onClose && (
                    <Box textAlign='right'>
                        <IconButton
                            className={classes.closeButton}
                            size='small'
                            onClick={props.onClose}
                            disabled={props.disabled}
                        >
                            <CancelIcon fontSize='large' />
                        </IconButton>
                    </Box>
                )}
            </Box>
        )
    }
}
