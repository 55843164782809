import { Padding, makeStyles } from '@material-ui/core'
import MuiContainer from '@material-ui/core/Container'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useDevice } from 'shared/hooks/useDevice'
import PageMetaData, { PageMetaDataProps } from './PageMetaData'

type DeviceConfig = {
    smallScreen?: string
    mediumScreen?: string
    largeScreen?: string
}

type Props = {
    mt?: DeviceConfig
    px?: DeviceConfig
    pb?: DeviceConfig
    pt?: DeviceConfig
    metaData?: PageMetaDataProps
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: (props: any) => props.mt,
        minHeight: 'calc(100vh - 87px)', //'73vh',
        boxSizing: 'border-box',
        position: 'relative',
        paddingLeft: (props: any) => props.px,
        paddingRight: (props: any) => props.px,
        paddingTop: (props: any) => props.pt,
        paddingBottom: (props: any) => props.pb
    },
    marginLeft: {
        width: `calc(100% - 250px)`,
        marginLeft: '250px'
    }
}))

export default function Page(props: PropsWithChildren<Props>) {
    return <Container {...props}>{props.children}</Container>
}

const Container = (props: PropsWithChildren<Props>) => {
    const device = useDevice()

    let mt: string
    let px: string
    let pt: string
    let pb: string

    if (device.smallScreen) {
        mt = props.mt?.smallScreen || '50px'
        px = props.px?.smallScreen || '8px'
        pt = props.pt?.smallScreen || '5px'
        pb = props.pb?.smallScreen || '10px'
    } else if (device.mediumScreen) {
        mt = props.mt?.mediumScreen || '50px'
        px = props.px?.mediumScreen || '8px'
        pt = props.pt?.mediumScreen || '0px'
        pb = props.pb?.mediumScreen || '10px'
    } else {
        mt = props.mt?.largeScreen || '50px'
        px = props.px?.largeScreen || '16px'
        pt = props.pt?.largeScreen || '0px'
        pb = props.pb?.largeScreen || '10px'
    }

    const classes = useStyles({ mt, px, pt, pb })

    if (device.largeScreen) {
        return (
            <>
                {props.metaData && <PageMetaData {...props.metaData} />}
                <MuiContainer
                    fixed
                    className={`${classes.container} ${classes.marginLeft}`}
                >
                    <>{props.children}</>
                </MuiContainer>
            </>
        )
    } else {
        return (
            <>
                {props.metaData && <PageMetaData {...props.metaData} />}
                <MuiContainer className={`${classes.container}`}>
                    <>{props.children}</>
                </MuiContainer>
            </>
        )
    }
}
