import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useLocation, useParams } from 'react-router-dom'
import View from './View'
import { useAuthContext } from 'context/AuthContext'
import { AppDispatch } from 'redux/store'
import slice from './slice'
import useDemographicsApi from 'api/useDemographicsApi'

export default function Container() {
    const state = useAppSelector((state) => state.demographicsState)
    const pollState = useAppSelector((state) => state.pollState)
    const authCtx = useAuthContext()
    const dispatch = useAppDispatch()
    const demographicsApi = useDemographicsApi()
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)

    const id = urlParams.get('id') || urlParams.get('state') || ''

    useEffect(() => {
        return () => {
            dispatch(resetDemographics())
        }
    }, [])

    useEffect(() => {
        if (pollState.poll) {
            if (!pollState.voting && authCtx.isAuthenticated) {
                dispatch(downloadDemographics(id))
            }
        }
    }, [pollState])

    const onSubmit = (age: string, location: string, education: string) => {
        dispatch(submitMyDemographics(id, age, location, education))
    }

    const resetDemographics = () => (dispatch: AppDispatch) => {
        dispatch(slice.actions.reset())
    }

    const submitMyDemographics = (
        pollId: string,
        age: string,
        location: string,
        education: string
    ) => async (dispatch: AppDispatch) => {
        dispatch(
            slice.actions.setState({
                ...state,
                loading: true
            })
        )

        await demographicsApi.setMyDemographics(age, location, education)

        dispatch(downloadDemographics(pollId))
    }

    const downloadDemographics = (pollId: string) => async (
        dispatch: AppDispatch
    ) => {
        dispatch(
            slice.actions.setState({
                ...state,
                loading: true
            })
        )

        const data = await demographicsApi.getDemographics(pollId)

        dispatch(
            slice.actions.setState({
                ...state,
                ...data,
                loading: false
            })
        )
    }

    return (
        <View
            {...state}
            onSubmit={onSubmit}
            poll={pollState.poll}
            isAuthenticated={authCtx.isAuthenticated}
        />
    )
}
