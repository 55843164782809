import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Poll from '@material-ui/icons/Poll'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    pollIcon: {
        color: (props: any) =>
            !props.voted ? getNotVotedColor(theme) : getVotedColor(theme)
    }
}))

const getColor = (theme: Theme) => {
    if (theme.palette.type === 'dark') {
        return theme.palette.primary.light
    } else {
        return theme.palette.primary.dark
    }
}

const getVotedColor = (theme: Theme) => {
    if (theme.palette.type === 'dark') {
        return theme.palette.success.light
    } else {
        return theme.palette.success.dark
    }
}

const getNotVotedColor = (theme: Theme) => {
    if (theme.palette.type === 'dark') {
        return 'rgba(255, 255, 255, 0.3)'
    } else {
        return 'rgba(0, 0, 0, 0.26)'
    }
}

type Props = {
    voted: boolean
}

export default function PollCard(props: Props) {
    const classes = useStyles({
        voted: props.voted
    })

    return <Poll className={classes.pollIcon} fontSize='large' />
}
