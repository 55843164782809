class PollRules {
    static readonly QuestionMinLength = 5
    static readonly QuestionMaxLength = 200
    static readonly ExpirationMaxYears = 10
    static readonly MaxOptionsCount = 32
    static readonly MinOptionsCount = 2
    static readonly OptionMaxLength = 100
    static readonly OptionMinLength = 2
}

export default PollRules
