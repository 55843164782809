import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Paper from '@material-ui/core/Paper'
import Modal from 'shared/components/Modal'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { useHistory, useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Paths from 'pages/Paths'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import FacebookLoginButton from 'shared/components/FacebookLoginButton'
import LoadingIndicator from 'shared/components/LoadingIndicator'
import { useStateIfMounted } from 'use-state-if-mounted'
import { useLoginModalContext } from 'context/LoginModalContext'
import ModalHeader from './ModalHeader'
import useAuthentication from 'shared/hooks/useAuthentication'
import { useSnackbarContext } from 'context/SnackbarContext'
import UserRules from 'server-rules/UserRules'

const useStyles = makeStyles((theme) => ({
    screen: {
        padding: theme.spacing(1)
    },
    paper: {
        position: 'relative',
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0)
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(0)
    },
    submit: {
        margin: theme.spacing(1, 0, 1)
    },
    container: {
        padding: theme.spacing(0)
    },
    link: {
        color: theme.palette.type === 'dark' ? 'lightblue' : 'blue'
    },
    linkChangeForm: {
        color: theme.palette.type === 'dark' ? 'HotPink' : 'blue',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}))

export default function LoginModal() {
    const loginModalCtx = useLoginModalContext()
    // This is done because we done want to execute mounted useEffect when the modal is not opened.
    if (loginModalCtx.opened) {
        return <_LoginModal />
    } else {
        return null
    }
}

function _LoginModal() {
    const classes = useStyles()
    const history = useHistory()
    const loginModalCtx = useLoginModalContext()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailError, setEmailError] = useStateIfMounted('')
    const [passwordError, setPasswordError] = useStateIfMounted('')
    const snackbarCtx = useSnackbarContext()
    const [formType, setFormType] = useState('register')

    const loginApi = useAuthentication({
        fbRedirectState: loginModalCtx.fbRedirectState || '',
        fbRedirectPath: loginModalCtx.fbRedirectPath || '',
        onError(integrationType, errorCode) {
            if (integrationType === 'login') {
                if (errorCode === 'EmailExists') {
                    return setEmailError('Потребител с този имейл съществува')
                } else if (errorCode === 'InvalidEmail') {
                    return setEmailError('Невалиден имейл')
                } else if (errorCode === 'ShortPassword') {
                    return setEmailError(
                        `Паролата трябва да бъде минимум ${UserRules.PasswordMinLength} символа`
                    )
                } else if (errorCode === 'LongPassword') {
                    return setEmailError(
                        `Паролата трябва да бъде маскимум ${UserRules.PasswordMaxLength} символа`
                    )
                } else if (errorCode === 'WrongCredentials') {
                    return setEmailError('Грешен имейл или парола')
                } else if (errorCode === 'EmailNotConfirmed') {
                    return setEmailError(
                        'Имейлът не е потвърден. Моля влезте в имейла си и потвърдете акаунта'
                    )
                }
            } else if (integrationType === 'facebook') {
                if (errorCode === 'EmailExists') {
                    return snackbarCtx.open(
                        'Потребител с този имейл съществува',
                        'error'
                    )
                } else if (errorCode === 'EmailMendatory') {
                    return snackbarCtx.open('Имейлът е задължителен', 'error')
                }
            } else if (integrationType === 'register') {
                if (errorCode === 'EmailExists') {
                    return setEmailError('Имейлът е зает')
                } else if (errorCode === 'InvalidEmail') {
                    return setEmailError('Невалиден имейл')
                } else if (errorCode === 'UserNotRegistered') {
                    return setEmailError(
                        'Регистрацията не може да бъде извършена в този момент. Може да пробвате влизане чрез Facebook'
                    )
                } else if (errorCode === 'ShortPassword') {
                    return setPasswordError(
                        `Паролата трябва да бъде минимум ${UserRules.PasswordMinLength} символа`
                    )
                } else if (errorCode === 'LongPassword') {
                    return setPasswordError(
                        `Паролата трябва да бъде маскимум ${UserRules.PasswordMaxLength} символа`
                    )
                }
            }
        },
        onSuccess(integrationType) {
            loginModalCtx.close()
            if (integrationType === 'local') {
                return snackbarCtx.open('Успешно влезнахте в профила си')
            } else if (integrationType === 'facebook') {
                return snackbarCtx.open('Успешно влезнахте с Facebook')
            } else if (integrationType === 'register') {
                return snackbarCtx.open(
                    'Регистрирахте се успешно. До няколко минути ще получите имейл!'
                )
            }
        }
    })

    const anySubmiting = loginApi.localSubmiting || loginApi.fbSubmiting

    const onClickFacebookLogin = () => {
        loginApi.loginFacebook()
    }
    const onClickSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        if (email.length === 0 || password.length === 0) {
            return
        }
        if (formType === 'login') {
            loginApi.loginLocal(email, password)
        } else {
            loginApi.register(email, password)
        }
    }
    const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
        setEmailError('')
    }
    const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
        setPasswordError('')
    }
    const onClickToLoginForm = (e: React.MouseEvent) => {
        e.preventDefault()
        if (!anySubmiting) {
            setFormType('login')
        }
    }
    const onClickToRegisterForm = (e: React.MouseEvent) => {
        e.preventDefault()
        if (!anySubmiting) {
            setFormType('register')
        }
    }
    const onClose = () => {
        loginModalCtx.close()
    }

    return (
        <Modal onClose={onClose} isActive={loginModalCtx.opened}>
            <Box className={classes.screen}>
                <Paper className={classes.paper} elevation={0}>
                    <LoadingIndicator loading={anySubmiting} />
                    <Container
                        maxWidth='xs'
                        disableGutters
                        className={classes.container}
                    >
                        <ModalHeader
                            onClose={onClose}
                            title={
                                formType === 'login' ? 'Вход' : 'Регистрация'
                            }
                            align='center'
                            variant='h5'
                            disabled={anySubmiting}
                        />

                        <Box ml='3px'>
                            {formType === 'register'
                                ? 'Ако имаш акаунт,'
                                : 'Ако нямаш акаунт,'}
                            &nbsp;
                            {formType === 'register' ? (
                                <Link
                                    className={classes.linkChangeForm}
                                    href={Paths.login()}
                                    onClick={onClickToLoginForm}
                                    variant='body1'
                                >
                                    <Typography
                                        variant='body2'
                                        display='inline'
                                    >
                                        <strong>влез</strong>
                                    </Typography>
                                </Link>
                            ) : (
                                <Link
                                    className={classes.linkChangeForm}
                                    href={Paths.register()}
                                    onClick={onClickToRegisterForm}
                                    variant='body1'
                                >
                                    <Typography
                                        variant='body2'
                                        display='inline'
                                    >
                                        <strong>създай</strong>
                                    </Typography>
                                </Link>
                            )}
                        </Box>
                        <form className={classes.form}>
                            <TextField
                                disabled={anySubmiting}
                                variant='filled'
                                required
                                fullWidth
                                label={
                                    formType === 'login'
                                        ? 'Имейл за вход'
                                        : 'Имейл за регистрация'
                                }
                                name='email'
                                autoComplete='email'
                                onChange={onChangeEmail}
                                helperText={emailError}
                                error={emailError.length > 0}
                            />
                            <TextField
                                disabled={anySubmiting}
                                variant='filled'
                                required
                                fullWidth
                                label={'Парола'}
                                type='password'
                                name='password'
                                autoComplete='current-password'
                                onChange={onChangePassword}
                                helperText={passwordError}
                                error={passwordError.length > 0}
                            />
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                className={classes.submit}
                                disabled={anySubmiting}
                                onClick={onClickSubmit}
                            >
                                {formType === 'login' ? 'Влез' : 'Регистрация'}
                            </Button>
                        </form>
                        <Box mb={1}>
                            <Divider />
                        </Box>
                        <FacebookLoginButton
                            onClick={onClickFacebookLogin}
                            disabled={anySubmiting}
                        />
                    </Container>
                </Paper>
            </Box>
        </Modal>
    )
}
