import React, { useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { useDevice } from 'shared/hooks/useDevice'
import { makeStyles } from '@material-ui/core'

export type SearchFieldProps = {
    search: (value: string) => void
    disabled: boolean
}

const useStyles = makeStyles((theme) => ({
    textfield: {},
    button: {}
}))

const SearchField = (props: SearchFieldProps) => {
    const classes = useStyles()

    const [value, setValue] = useState('')

    const device = useDevice()

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
    }

    const onSearch = () => {
        props.search(value)
    }

    const onKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            props.search(value)
        }
    }

    return (
        <TextField
            className={classes.textfield}
            onKeyDown={onKeyDown}
            placeholder={
                device.smallScreen
                    ? 'Търсете по въпрос или опция...'
                    : 'Търсете по въпрос или опция...'
            }
            variant='filled'
            value={value}
            onChange={onInputChange}
            fullWidth
            type='search'
            InputProps={{
                endAdornment: (
                    <InputAdornment position='start'>
                        <IconButton
                            size='medium'
                            onClick={onSearch}
                            disabled={props.disabled}
                        >
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
}

export default SearchField
