import { SelectMenuOption } from 'shared/components/SelectMenu'

const locationOptions = [
    {
        label: 'Местоживеене',
        value: 'unspecified'
    },
    {
        label: 'България',
        value: 'inBulgaria'
    },
    {
        label: 'Извън Българя',
        value: 'notInBulgaria'
    }
]

const educationOptions = [
    {
        label: 'Образование',
        value: 'unspecified'
    },
    {
        label: 'Основно',
        value: 'primary'
    },
    {
        label: 'Средно',
        value: 'secondary'
    },
    {
        label: 'Висше',
        value: 'higher'
    }
]

const ageOptions: SelectMenuOption[] = [
    {
        label: 'Възраст',
        value: 'unspecified'
    },
    {
        label: 'Под 18',
        value: '<18'
    },
    {
        label: '18-25',
        value: '18-25'
    },
    {
        label: '26-35',
        value: '26-35'
    },
    {
        label: '36-45',
        value: '36-45'
    },
    {
        label: '46-55',
        value: '46-55'
    },
    {
        label: 'Над 55',
        value: '55<'
    }
]

const ages = [
    'lessThan18',
    '18-25',
    '26-35',
    '36-45',
    '46-55',
    'moreThan55',
    'unspecified'
]
const locations = ['inBulgaria', 'notInBulgaria', 'unspecified']
const educations = ['primary', 'secondary', 'higher', 'unspecified']

export {
    locationOptions,
    ageOptions,
    educationOptions,
    ages,
    locations,
    educations
}
