import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import useApi from 'shared/hooks/useApi'
import View from './View'
import { useLocation } from 'react-router-dom'
import { useStateIfMounted } from 'use-state-if-mounted'
import slice from './slice'
import { AppDispatch } from 'redux/store'
import usePollsApi from 'api/usePollsApi'

export default function Container() {
    const state = useAppSelector((state) => state.otherPollsState)
    const pollState = useAppSelector((state) => state.pollState)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const id = urlParams.get('id') || urlParams.get('state') || ''
    const pollsApi = usePollsApi()

    const [otherPollsOfId, setOtherPollsOfId] = useStateIfMounted<
        string | undefined
    >(undefined)

    useEffect(() => {
        return () => {
            return dispatch(resetOtherPolls())
        }
    }, [])

    useEffect(() => {
        if (pollState.poll && id && pollState.poll.id !== otherPollsOfId) {
            setOtherPollsOfId(pollState.poll.id)
            dispatch(setPrivatePoll(pollState.poll.privatePoll))
            dispatch(setIsPublished(pollState.poll.status === 'published'))
            dispatch(downloadOtherPolls(id))
        }
    }, [pollState, location.search])

    const resetOtherPolls = () => (dispatch: AppDispatch) => {
        dispatch(slice.actions.reset())
    }

    const setPrivatePoll = (value: boolean) => (dispatch: AppDispatch) => {
        dispatch(slice.actions.setPrivatePoll(value))
    }

    const setIsPublished = (value: boolean) => (dispatch: AppDispatch) => {
        dispatch(slice.actions.setIsPublished(value))
    }

    const downloadOtherPolls = (pollId: string) => async (
        dispatch: AppDispatch
    ) => {
        const pollData = await pollsApi.getOthers(pollId)
        dispatch(slice.actions.setPolls(pollData))
    }

    return <View {...state} />
}
