import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    typo: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
        fontWeight: 900
    }
}))

export type PollTitleProps = {
    text: string
}

export default function PollTitle(props: PollTitleProps) {
    const classes = useStyles()

    return (
        <div>
            <Typography className={classes.typo} variant={'h6'} component='h2'>
                {props.text}
            </Typography>
        </div>
    )
}
