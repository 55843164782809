import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

export default class AxiosProxy {
    private _axios: AxiosInstance
    private _defaultResponseDelay: number //ms
    private _isBot: boolean

    constructor(axios: AxiosInstance, defaultResponseDelay: number) {
        this._axios = axios
        this._defaultResponseDelay = defaultResponseDelay
        this._isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(
            navigator.userAgent
        )
    }

    /**
     *
     * @param url
     * @param config
     * @param delay in ms
     * @returns
     */
    async get(
        url: string,
        config?: AxiosRequestConfig,
        delay: boolean | number = this._defaultResponseDelay
    ) {
        if (delay === false || this._isBot) {
            return await this._axios.get(url, config)
        } else if (delay > 0) {
            return await this.responseWithDelay(
                'GET',
                delay as number,
                url,
                config
            )
        } else {
            throw new Error('Parameter "delay" equal to 0 or less than 0')
        }
    }

    /**
     *
     * @param url
     * @param config
     * @param delay in ms
     * @returns
     */
    async post(
        url: string,
        data?: any,
        config?: AxiosRequestConfig,
        delay: boolean | number = this._defaultResponseDelay
    ) {
        if (delay === false || this._isBot) {
            return await this._axios.post(url, data, config)
        } else if (delay > 0) {
            return await this.responseWithDelay(
                'POST',
                delay as number,
                url,
                config,
                data
            )
        } else {
            throw new Error('Parameter "delay" equal to 0 or less than 0')
        }
    }

    /**
     *
     * @param url
     * @param config
     * @param delay in ms
     * @returns
     */
    async delete(
        url: string,
        config?: AxiosRequestConfig,
        delay: boolean | number = this._defaultResponseDelay
    ) {
        if (delay === false || this._isBot) {
            return await this._axios.delete(url, config)
        } else if (delay > 0) {
            return await this.responseWithDelay(
                'DELETE',
                delay as number,
                url,
                config
            )
        } else {
            throw new Error('Parameter "delay" equal to 0 or less than 0')
        }
    }

    /**
     *
     * @param url
     * @param config
     * @param delay in ms
     * @returns
     */
    async put(
        url: string,
        data?: any,
        config?: AxiosRequestConfig,
        delay: boolean | number = this._defaultResponseDelay
    ) {
        if (delay === false || this._isBot) {
            return await this._axios.put(url, data, config)
        } else if (delay > 0) {
            return await this.responseWithDelay(
                'PUT',
                delay as number,
                url,
                config,
                data
            )
        } else {
            throw new Error('Parameter "delay" equal to 0 or less than 0')
        }
    }

    private async responseWithDelay(
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        delay: number,
        url: string,
        config?: AxiosRequestConfig,
        data?: any
    ) {
        return new Promise<AxiosResponse>(async (resolve, reject) => {
            let timeExpired = false
            let response: AxiosResponse
            setTimeout(() => {
                timeExpired = true
                if (response !== undefined) {
                    return resolve(response)
                }
            }, delay)
            try {
                switch (method) {
                    case 'GET':
                        response = await this._axios.get(url, config)
                        break

                    case 'POST':
                        response = await this._axios.post(url, data, config)
                        break

                    case 'DELETE':
                        response = await this._axios.delete(url, config)
                        break

                    case 'PUT':
                        response = await this._axios.put(url, data, config)
                        break
                }

                if (timeExpired) {
                    return resolve(response)
                }
            } catch (err) {
                return reject(err)
            }
        })
    }
}
