import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

type Props = {
    label: string
    value: string
    onEdit?: () => void
    disabled: boolean
}

const useStyles = makeStyles((theme) => ({
    value: {
        color: theme.palette.text.secondary
    },
    iconButton: {
        marginTop: '-8px'
    }
}))

export default function ViewModeField(props: Props) {
    const classes = useStyles()

    return (
        <Box textAlign='left' display='flex' justifyContent='space-between'>
            <Box>
                <Typography variant='subtitle1'>{props.label}</Typography>
                <Typography variant='body2' className={classes.value}>
                    {props.value}
                </Typography>
            </Box>
            {props.onEdit && (
                <Box mt={1}>
                    <Button
                        onClick={props.onEdit}
                        size='small'
                        disabled={props.disabled}
                    >
                        Редактирай
                    </Button>
                </Box>
            )}
        </Box>
    )
}
