import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useState
} from 'react'

type LoginModalContext = {
    open: (fbRedirectPath: string, fbRedirectState?: string) => void
    close: () => void
    opened: boolean
    fbRedirectPath: string | undefined
    fbRedirectState?: string | undefined
}

const Context = createContext<LoginModalContext | undefined>(undefined)

export function useLoginModalContext(): LoginModalContext {
    const context = useContext(Context)
    if (context === undefined) {
        throw new Error('Context must be within provider')
    }

    return context
}

export function LoginModalProvider(props: PropsWithChildren<{}>) {
    const [opened, setOpened] = useState(false)
    const [params, setParams] = useState<
        | {
              fbRedirectPath: string
              fbRedirectState?: string
          }
        | undefined
    >()

    const open = (fbRedirectPath: string, fbRedirectState?: string) => {
        setOpened(true)
        setParams({
            fbRedirectPath,
            fbRedirectState
        })
    }

    const close = () => {
        setOpened(false)
        setParams(undefined)
    }

    return (
        <Context.Provider
            value={{
                open,
                close,
                opened,
                fbRedirectPath: params?.fbRedirectPath,
                fbRedirectState: params?.fbRedirectState
            }}
        >
            {props.children}
        </Context.Provider>
    )
}
