import React, { useEffect } from 'react'
import DateF from 'utils/DateF'
import Box from '@material-ui/core/Box'
import { Divider, makeStyles, Paper, Typography } from '@material-ui/core'
import AccountViewMode from '../containers/AccountViewMode'
import AccountEditMode from '../containers/AccountEditMode'
import CompleteAccount from '../containers/CompleteAccount'

export type AccountProps = {
    edit: boolean
    isTemporaryAccount: boolean
}

const useStyles = makeStyles((theme) => {
    return {
        paper: {
            padding: theme.spacing(1),
            border: () => {
                let color
                if (theme.palette.type === 'dark') {
                    color = '#228B22'
                } else {
                    color = '#00FF7F'
                }

                return `1px solid ${color}`
            },
            borderRadius: '8px'
        },
        expired: {},
        expiredLabel: {
            color: theme.palette.error.main
        }
    }
})

export default function Account(props: AccountProps) {
    const classes = useStyles()
    const { edit } = props
    return (
        <Paper className={classes.paper} elevation={0}>
            <Typography variant='h5'>
                <Box component='span' fontWeight='500'>
                    Акаунт
                </Box>
            </Typography>
            <Divider />
            <Box mt={1}>
                {!edit && <AccountViewMode />}
                {edit && <AccountEditMode />}
                {/* {props.onlyClient && (
                    <>
                        <Box mt={1}>
                            <Divider />
                        </Box>
                        <CompleteAccount />
                    </>
                )} */}
            </Box>
        </Paper>
    )
}
