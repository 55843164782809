import { useQueryHandler } from 'shared/hooks/useQueryHandler'
import { useSnackbarContext } from 'context/SnackbarContext'

type Props = {
    onChangeSearch: (query: PollSearchParams) => void
}

export type PollSearchParams = {
    page: number
    question: string
    expiration: string
    votes: string
    order: string
    type: string
}

const defaultSearchParams = {
    page: '1',
    order: 'most-voted',
    expiration: 'active',
    question: '',
    votes: '',
    type: ''
}

export default function useQueryService(
    onChangeSearch: (query: PollSearchParams) => void,
    onClearSearch: () => void
) {
    const [
        urlSearchParams,
        updateHistory,
        getQueryOrDefault,
        deleteQueries,
        getQuery,
        location
    ] = useQueryHandler(({ init, noSearchParams }) => {
        const query = {
            order: getQueryOrDefault('order'),
            expiration: getQueryOrDefault('expiration'),
            page: parseInt(getQueryOrDefault('page')),
            question: getQueryOrDefault('question'),
            votes: getQueryOrDefault('votes'),
            type: getQueryOrDefault('type')
        }

        const onlyDefaultSearchParams =
            query.expiration === defaultSearchParams.expiration &&
            query.page === +defaultSearchParams.page &&
            query.order === defaultSearchParams.order &&
            query.question === defaultSearchParams.question

        if (!init && !noSearchParams) {
            onChangeSearch({
                order: query.order,
                expiration: query.expiration,
                page: query.page,
                question: query.question,
                votes: query.votes,
                type: query.type
            })
        }

        if (!init && noSearchParams) {
            onClearSearch()
        }

        // User pressed search route from search page, so we need to reload the page like.
        // Its the only way for noSearchParams and onlyDefaultSearchParams to be true - pressing the search route from search page.
        if (!init && noSearchParams && onlyDefaultSearchParams) {
            onChangeSearch({
                order: query.order,
                expiration: query.expiration,
                page: query.page,
                question: query.question,
                votes: query.votes,
                type: query.type
            })
        }
    }, defaultSearchParams)

    const query = {
        order: getQueryOrDefault('order'),
        expiration: getQueryOrDefault('expiration'),
        page: parseInt(getQueryOrDefault('page')),
        question: getQueryOrDefault('question'),
        votes: getQueryOrDefault('votes'),
        type: getQueryOrDefault('type')
    }

    const isExpirationDefault =
        query.expiration === defaultSearchParams.expiration
    const isPageDefault = query.page === +defaultSearchParams.page
    const isOrderDefault = query.order === defaultSearchParams.order
    const isQuestionDefault = query.question === defaultSearchParams.question

    const changeOrder = (value: string) => {
        urlSearchParams.set('order', value)
        urlSearchParams.set('page', '1')
        updateHistory({ method: 'push' })
    }

    const changePage = (value: number) => {
        urlSearchParams.set('page', value.toString())
        updateHistory({ method: 'push' })
    }

    const changeExpiration = (value: string) => {
        urlSearchParams.set('expiration', value)
        urlSearchParams.set('page', '1')
        updateHistory({ method: 'push' })
    }

    const changeType = (value: string) => {
        const currType = getQueryOrDefault('type')

        if (value !== currType) {
            urlSearchParams.delete('page')
            urlSearchParams.delete('expiration')
            urlSearchParams.set('type', value)
            updateHistory({ method: 'push' })
        } else {
            urlSearchParams.delete('page')
            urlSearchParams.delete('question')
            urlSearchParams.delete('page')
            urlSearchParams.delete('expiration')
            urlSearchParams.delete('type')
            updateHistory({ method: 'push' })
        }
    }

    const changeQuestion = (value: string) => {
        if (value !== '') {
            urlSearchParams.set('question', value)
            urlSearchParams.set('page', '1')
            updateHistory({ method: 'push' })
        } else if (query.question !== '' && value === '') {
            urlSearchParams.delete('question')
            urlSearchParams.set('page', '1')
            updateHistory({ method: 'push' })
        }
    }

    return {
        changeOrder,
        changePage,
        changeExpiration,
        changeQuestion,
        clearQueries: deleteQueries,
        changeType,
        query,
        location,
        onlyDefaultSearchParams:
            isExpirationDefault &&
            isPageDefault &&
            isOrderDefault &&
            isQuestionDefault
    }
}
