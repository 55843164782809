import Paths from 'pages/Paths'
import { PageMetaDataProps } from 'shared/components/PageMetaData'

export default function useMetaData(): PageMetaDataProps {
    return {
        h1: 'Регистрация',
        title: 'Регистрация | Anketi.bg',
        metas: [
            {
                name: 'description',
                content:
                    'Регистрирай безплатен акаунт или влез чрез социална мрежа'
            },
            {
                name: 'og:url',
                content: `https://anketi.bg${Paths.register()}`
            },
            {
                name: 'og:title',
                content: 'Регистрация | Anketi.bg'
            },
            {
                name: 'keywords',
                content: 'регистрация'
            }
        ],
        links: [
            {
                rel: 'canonical',
                href: `https://anketi.bg${Paths.register()}`
            }
        ]
    }
}
