import { useState } from 'react'
import PollDto from 'shared/types/PollDto'

export default function usePollModal() {
    const [poll, setPoll] = useState<PollDto | null>(null)

    const close = () => {
        setPoll(null)
    }

    const open = (research: PollDto) => {
        setPoll(research)
    }

    return {
        active: poll !== null,
        poll,
        close,
        open
    }
}
