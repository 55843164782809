import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
    NavLink as RouterLink,
    useHistory,
    useLocation
} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { IconButton } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    link: {
        color:
            theme.palette.type === 'dark'
                ? theme.palette.primary.light
                : theme.palette.primary.dark
    },
    decoration: {
        textDecoration: 'underline'
    },
    button: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0)
    }
}))

type Props = {
    path: string
    icon?: React.ReactNode
}

export default function NavLink(props: Props) {
    const classes = useStyles()
    const location = useLocation()

    const history = useHistory()

    const onClick = (e: any) => {
        e.preventDefault()
        history.push(props.path)
    }

    return (
        <IconButton
            size={'medium'}
            href={props.path}
            onClick={onClick}
            component='a'
            className={classes.button}
        >
            {props.icon}
        </IconButton>
    )
}
