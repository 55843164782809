import { useAuthContext } from 'context/AuthContext'
import Axios from 'axios'
import { useAxiosContext } from 'context/AxiosContext'
import QueryBuilder from './QueryBuilder'
import RequestCancelError from './RequestCancelError'
import useCancelation from './useCancelRequests'

export type PollsApiReturnType = ReturnType<typeof usePollsApi>

export default function usePollsApi() {
    const http = useAxiosContext()
    const getByIdRequests = useCancelation()

    const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(
        navigator.userAgent
    )
    const authCtx = useAuthContext()

    const getOthers = async (pollId: string) => {
        if (authCtx.getAuthenticated()) {
            const res = await http.client.get(
                `/polls/get-others-authenticated/${pollId}`
            )
            return res.data
        } else {
            if (!isBot) {
                const res = await http.client.get(`/polls/get-others/${pollId}`)
                return res.data
            } else {
                return {
                    entities: [],
                    totalCount: 0
                }
            }
        }
    }

    const getRandomPoll = async () => {
        try {
            getByIdRequests.cancelAll()

            if (authCtx.getAuthenticated()) {
                const res = await http.client.get(
                    `/polls/get-random-authenticated`
                )

                return res.data
            } else {
                const res = await http.client.get(`/polls/get-random`)

                return res.data
            }
        } catch (e) {
            if (Axios.isCancel(e)) {
                throw new RequestCancelError('get-random-poll')
            } else {
                throw e
            }
        }
    }

    const getById = async (pollId: string) => {
        try {
            getByIdRequests.cancelAll()

            const builder = new QueryBuilder()

            builder.addSimple('pollId', pollId)

            const source = getByIdRequests.addSource()

            if (authCtx.getAuthenticated()) {
                const res = await http.client.get(
                    `/polls/authenticated/${pollId}${builder.query.value}`,
                    {
                        cancelToken: source.token
                    }
                )

                return res.data
            } else {
                const res = await http.client.get(
                    `/polls/${pollId}${builder.query.value}`,
                    {
                        cancelToken: source.token
                    }
                )

                return res.data
            }
        } catch (e) {
            if (Axios.isCancel(e)) {
                throw new RequestCancelError('get-poll-by-id')
            } else {
                throw e
            }
        }
    }

    return {
        getById,
        getRandomPoll,
        cancelGetById: getByIdRequests.cancelAll,
        getOthers
    }
}
