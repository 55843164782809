import { Box, Paper } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Paths from 'pages/Paths'
import React from 'react'
import { useHistory } from 'react-router-dom'
import LoadingIndicator from 'shared/components/LoadingIndicator'
import PollVotersCount from 'shared/components/PollVotersCount'
import OtherPollDto from 'shared/types/OtherPollDto'

const useStyles = makeStyles((theme) => ({
    pollBackground: {
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        opacity: '0.5',
        width: '100%',
        height: '100%',
        borderRadius: '8px'
    },
    paper: {
        position: 'relative',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        border: () => {
            let color
            if (theme.palette.type === 'dark') {
                color = '#228B22'
            } else {
                color = '#00FF7F'
            }

            return `1px solid ${color}`
        },
        borderRadius: '8px'
    }
}))

export type OtherPollsState = {
    privatePoll: boolean
    isPublished: boolean
    polls: OtherPollDto[] | null
}

type Props = OtherPollsState

export default function View(props: Props) {
    if (!props.isPublished) {
        return null
    }

    return (
        <Box position='relative' width='100%' minHeight={'400px'}>
            {!props.polls && (
                <LoadingIndicator loading={true} top={40} size={40} />
            )}
            {props.polls && <PollsRows polls={props.polls} />}
            {!props.polls && <Box width='100px' height='60px' />}
        </Box>
    )
}

type PollRowsProps = {
    polls: OtherPollDto[]
}

const PollsRows = (props: PollRowsProps) => {
    const classes = useStyles()

    const history = useHistory()

    const onClick = (id: string) => {
        history.push(Paths.poll(id))
    }

    if (props.polls) {
        const polls = props.polls.map((poll, index) => {
            return (
                <Box
                    position='relative'
                    margin='0 auto'
                    maxWidth={'500px'}
                    marginBottom={1}
                    key={index}
                >
                    <Box className={classes.pollBackground} />
                    <Paper
                        elevation={0}
                        className={classes.paper}
                        onClick={() => {
                            onClick(poll.id)
                        }}
                    >
                        <Box px={2}>
                            <PollVotersCount votersCount={poll.votersCount} />
                            <Typography variant='h6'>
                                {poll.question}
                            </Typography>
                        </Box>
                    </Paper>
                </Box>
            )
        })

        return <>{polls}</>
    }

    return null
}
