import { TypographyVariant } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import React from 'react'

export type SelectMenuOption = {
    value: string
    label: string
}
export type SelectMenuProps = {
    options: SelectMenuOption[]
    value: string
    disabled?: boolean
    fullWidth?: boolean
    variant?: 'standard' | 'outlined' | 'filled'
    id?: string
    typoVariant?: TypographyVariant
    label?: string
    enableUnderline?: boolean
    prmaryTextColor?: boolean
    onChange: (value: string) => void
}

export default function SelectMenu(props: SelectMenuProps) {
    const _onChange = (e: React.ChangeEvent<any>, child: React.ReactNode) => {
        props.onChange((e as any).target?.value)
    }

    const typoVariant = props.typoVariant || 'body1'

    const items = []

    for (const option of props.options) {
        items.push(
            <MenuItem key={option.value} value={option.value}>
                <Box display='flex' justifyContent='center' alignItems='center'>
                    <Typography
                        variant={typoVariant}
                        component={'span'}
                        color={
                            props.prmaryTextColor && !props.disabled
                                ? 'textPrimary'
                                : 'textSecondary'
                        }
                    >
                        {option.label}
                    </Typography>
                </Box>
            </MenuItem>
        )
    }

    return (
        <FormControl fullWidth={props.fullWidth} variant={props.variant}>
            {props.label && (
                <InputLabel>
                    <Typography variant='body1' component='span'>
                        {props.label}
                    </Typography>
                </InputLabel>
            )}
            <Select
                id={props.id}
                disabled={props.disabled}
                fullWidth={props.fullWidth}
                variant={props.variant}
                value={props.value}
                input={
                    props.enableUnderline ? undefined : (
                        <Input disableUnderline />
                    )
                }
                onChange={_onChange}
            >
                {items}
            </Select>
        </FormControl>
    )
}
