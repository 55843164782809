import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useState } from 'react'
import { useThemeContext } from 'context/ThemeContext'
import Brightness2Icon from '@material-ui/icons/Brightness2'
import WbSunnyIcon from '@material-ui/icons/WbSunny'
import AcUnitIcon from '@material-ui/icons/AcUnit'
import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        color: theme.palette.type === 'dark' ? 'yellow' : 'blue'
    },
    label: {
        visibility: 'hidden'
    },
    lightIcon: {
        fontSize: '30px',
        color: 'yellow'
    },
    darkIcon: {
        fontSize: '30px',
        color: 'blue'
    }
}))

type Props = {}

export default function ThemeButton(props: Props) {
    const classes = useStyles()
    const {
        isDarkTheme,
        setDarkThemeActive,
        isSeasonalTheme,
        setSeasonalThemeActive
    } = useThemeContext()

    const [index, setIndex] = useState(isDarkTheme ? 0 : 1)

    const onClickTheme = () => {
        if (index === 1) {
            setDarkThemeActive(true)
        } else if (index === 0) {
            setDarkThemeActive(false)
        }
        if (index >= 1) {
            setIndex(0)
        } else {
            setIndex((val) => val + 1)
        }
    }

    return (
        <>
            <IconButton
                onClick={onClickTheme}
                className={classes.wrapper}
                id='theme_button'
                aria-label='Theme button'
                aria-labelledby='theme_button_label'
            >
                {index === 0 && <WbSunnyIcon className={classes.lightIcon} />}
                {index === 1 && (
                    <Brightness2Icon className={classes.darkIcon} />
                )}
            </IconButton>
        </>
    )
}
