import React from 'react'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
    wrapper: {},
    key: {
        color: theme.palette.text.secondary
    },
    value: {
        marginTop: '0.3px',
        color: theme.palette.text.secondary
    }
}))

type Props = {
    label: string
    value: number | null
}

export default function ActivityField(props: Props) {
    const classes = useStyles()

    const val = props.value === null ? '--' : props.value

    return (
        <Box display='flex'>
            <Typography variant='body2' className={classes.key}>
                {props.label}&nbsp;
            </Typography>
            <Typography variant='body2' className={classes.value}>
                {val}
            </Typography>
        </Box>
    )
}
