import React, { useEffect, useState } from 'react'
import { Paper, Typography, makeStyles } from '@material-ui/core'
import SelectMenu from 'shared/components/SelectMenu'
import { ageOptions, educationOptions, locationOptions } from '../constants'
import { useDevice } from 'shared/hooks/useDevice'

type Props = {
    onSave: (age: string, location: string, education: string) => void
    age: string | null
    location: string | null
    education: string | null
    authenticated: boolean
    ageChangeAllowed: boolean
    locationChangeAllowed: boolean
    educationChangeAllowed: boolean
    hint: string
    hintColor: 'primary' | 'secondary'
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'inline-flex',

        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        flexDirection: 'column',
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        margin: 'auto',
        border: () => {
            let color
            if (theme.palette.type === 'dark') {
                color = '#228B22'
            } else {
                color = '#00FF7F'
            }

            return `1px solid ${color}`
        }
    },
    titleWrapper: {},
    selectMenusWrapper: {
        width: '100%',
        display: 'flex',
        marginTop: theme.spacing(0),
        justifyContent: 'space-between'
    },
    buttonWrapper: {
        marginTop: theme.spacing(1),
        textAlign: 'right'
    },
    selectWrapper: {},
    menusAndButton: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    titleTypo: {
        [theme.breakpoints.up('md')]: {
            fontSize: '1.6rem'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1.4rem'
        }
    }
}))

export default function DemographicsForm(props: Props) {
    const classes = useStyles()

    const [age, setAge] = useState<string>(props.age || 'unspecified')
    const [location, setLocation] = useState<string>(
        props.location || 'unspecified'
    )
    const [education, setEducation] = useState<string>(
        props.education || 'unspecified'
    )

    const onChangeAge = (value: string) => {
        setAge(value)
        props.onSave(value, location, education)
    }

    const onChangeLocation = (value: string) => {
        setLocation(value)
        props.onSave(age, value, education)
    }

    const onChangeEducation = (value: string) => {
        setEducation(value)
        props.onSave(age, location, value)
    }

    const selectDisabled = !props.authenticated
    const buttonDisabled = !props.authenticated

    return (
        <Paper elevation={0} className={classes.wrapper} component={'form'}>
            <div className={classes.titleWrapper}>
                <Typography className={classes.titleTypo}>
                    <strong>Вашата демография</strong>
                </Typography>
            </div>
            {/* <Divider /> */}
            <div className={classes.menusAndButton}>
                <div className={classes.selectMenusWrapper}>
                    <div className={classes.selectWrapper}>
                        <SelectMenu
                            value={age}
                            onChange={onChangeAge}
                            options={ageOptions}
                            disabled={selectDisabled || !props.ageChangeAllowed}
                            typoVariant='body1'
                            prmaryTextColor
                        />
                    </div>
                    <div className={classes.selectWrapper}>
                        <SelectMenu
                            value={location}
                            onChange={onChangeLocation}
                            options={locationOptions}
                            typoVariant='body1'
                            disabled={
                                selectDisabled || !props.locationChangeAllowed
                            }
                            prmaryTextColor
                        />
                    </div>
                    <div className={classes.selectWrapper}>
                        <SelectMenu
                            value={education}
                            onChange={onChangeEducation}
                            options={educationOptions}
                            typoVariant='body1'
                            disabled={
                                selectDisabled || !props.educationChangeAllowed
                            }
                            prmaryTextColor
                        />
                    </div>
                </div>
            </div>
            <Typography
                variant='caption'
                align='center'
                color={props.hintColor}
            >
                {props.hint}
            </Typography>
        </Paper>
    )
}
