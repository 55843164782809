export function transform(data: any) {
    if (data === null || data === undefined || data.length === 0) {
        return []
    }

    const transformedData: any[] = []

    const groups = Object.keys(data[0]).slice(1)

    groups.forEach((group) => {
        const transformedItem: any = { name: demographyLabel(group) }

        data.forEach((item: any) => {
            const optionValue = item.name
            const votesCount = item[group]
            transformedItem[optionValue] = votesCount
        })

        transformedData.push(transformedItem)
    })

    return transformedData
}

export function demographyLabel(group: string) {
    switch (group) {
        case 'unspecified':
            return 'Неуказали'
        case 'lessThan18':
            return 'Под 18'
        case 'age18to25':
            return '18-25'
        case 'age26to35':
            return '26-35'
        case 'age36to45':
            return '36-45'
        case 'age46to55':
            return '46-55'
        case 'moreThan55':
            return 'Над 55'
        case 'primary':
            return 'Основно'
        case 'secondary':
            return 'Средно'
        case 'higher':
            return 'Висше'
        case 'inBulgaria':
            return 'България'
        case 'notInBulgaria':
            return 'Чужбина'

        default:
            return group
    }
}
