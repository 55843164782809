export default class MathF {
    static getRandomInt(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    static getPagesCount(totalCount: number, perPage: number) {
        return Math.ceil(totalCount / perPage)
    }

    static calcPercentage(part: number, amount: number) {
        if (amount === 0 || part === 0) {
            return 0
        }

        const perc = (part / amount) * 100

        return perc
    }
}
