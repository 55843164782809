import React from 'react'
import { Divider, makeStyles } from '@material-ui/core'
import PollControl from 'shared/components/PollControl'
import PollInfoCollapse from 'shared/components/PollInfoCollapse'
import PollDto from 'shared/types/PollDto'
import PollTitle from 'shared/components/PollTitle'

type Style = {
    addBottomDivider?: boolean
}

export type PollState = {
    poll: PollDto
    optionError: string
    savingOption: boolean
    voting: boolean
    connectModalOpened: boolean
}

export type PollProps = {
    onSaveOption: (pollId: string, optionValue: string) => void
    onVoteForOption: (pollId: string, optionId: string) => void
    onVoteForRating: (pollId: string, rating: number) => void
    onClearOptionError: () => void
} & PollState &
    Style

const useStyles = makeStyles((theme) => {
    return {
        padding: {
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(2)
            }
        },
        dividerWrap: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        }
    }
})

const Poll = (props: PollProps) => {
    const classes = useStyles()
    return (
        <>
            <div className={classes.padding}>
                <PollInfoCollapse {...props.poll} />
                <PollTitle text={props.poll.question} />
                <PollControl {...props} />
                {props.addBottomDivider && (
                    <div className={classes.dividerWrap}>
                        <Divider />
                    </div>
                )}
            </div>
        </>
    )
}

export default Poll
