import Box from '@material-ui/core/Box'
import React, { useEffect } from 'react'
import { Button, Typography, makeStyles } from '@material-ui/core'
import { useSnackbarContext } from 'context/SnackbarContext'
import ViewModeField from '../ViewModeField'

type Props = {
    userId: string
}

const useStyles = makeStyles((theme) => {
    return {
        paper: {
            padding: theme.spacing(1),
            border: () => {
                let color
                if (theme.palette.type === 'dark') {
                    color = '#228B22'
                } else {
                    color = '#00FF7F'
                }

                return `1px solid ${color}`
            },
            borderRadius: '8px'
        }
    }
})

export default function Identification(props: Props) {
    const classes = useStyles()

    const snackbarCtx = useSnackbarContext()

    const copyToClipboard = () => {
        const textarea = document.createElement('textarea')
        textarea.value = `${props.userId}`
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
        snackbarCtx.open('Копирано')
    }

    return (
        <Box
            display='flex'
            width='100%'
            justifyContent={'space-between'}
            className={classes.paper}
        >
            <ViewModeField
                disabled={true}
                label={'Идентификация(ID)'}
                value={props.userId}
            />
            <Box textAlign={'right'}>
                <Button
                    variant='text'
                    color='primary'
                    size='small'
                    onClick={copyToClipboard}
                >
                    Копирай
                </Button>
            </Box>
        </Box>
    )
}
