import React from 'react'
import SaveIcon from '@material-ui/icons/Save'
import CloseIcon from '@material-ui/icons/Close'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

type Props = {
    onSave: () => void
    onClose: () => void
    disabled?: boolean
    disabledSave?: boolean
}

export function EditControls(props: Props) {
    return (
        <Box display='flex' justifyContent='space-around' width='100%'>
            <Button
                disabled={props.disabled || props.disabledSave}
                startIcon={<SaveIcon />}
                variant='text'
                onClick={props.onSave}
                size='small'
            >
                Запази
            </Button>
            <Button
                disabled={props.disabled}
                startIcon={<CloseIcon />}
                variant='text'
                onClick={props.onClose}
                size='small'
            >
                Затвори
            </Button>
        </Box>
    )
}
