import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core'
import { useSnackbarContext } from 'context/SnackbarContext'
import Paths from 'pages/Paths'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton
} from 'react-share'
import Link from 'shared/components/Link'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        maxWidth: '800px',
        margin: '0 auto',
        minHeight: '150px'
    },
    buttonWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

type Props = {
    id: string
}

export default function Share(props: Props) {
    const classes = useStyles()

    const snackbarCtx = useSnackbarContext()

    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const id = urlParams.get('id') || urlParams.get('state') || ''

    const copyToClipboard = () => {
        const textarea = document.createElement('textarea')
        textarea.value = `https://anketi.bg/spodelyane/anketi/${props.id}`
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
        snackbarCtx.open('Копирано')
    }

    return (
        <Box>
            <Grid
                container
                justifyContent='space-between'
                spacing={2}
                className={classes.wrapper}
            >
                <Grid item xs={4} sm={2}>
                    <div className={classes.buttonWrapper}>
                        <FacebookShareButton
                            url={`https://anketi.bg/spodelyane/anketi/${props.id}`}
                        >
                            <FacebookIcon style={{ borderRadius: '50%' }} />
                        </FacebookShareButton>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2}>
                    <div className={classes.buttonWrapper}>
                        <TwitterShareButton
                            url={`https://anketi.bg/spodelyane/anketi/${props.id}`}
                        >
                            <TwitterIcon style={{ borderRadius: '50%' }} />
                        </TwitterShareButton>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2}>
                    <div className={classes.buttonWrapper}>
                        <TelegramShareButton
                            url={`https://anketi.bg/spodelyane/anketi/${props.id}`}
                        >
                            <TelegramIcon style={{ borderRadius: '50%' }} />
                        </TelegramShareButton>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2}>
                    <div className={classes.buttonWrapper}>
                        <FacebookMessengerShareButton
                            appId={process.env.REACT_APP_FB_ID || ''}
                            url={`https://anketi.bg/spodelyane/anketi/${props.id}`}
                        >
                            <FacebookMessengerIcon
                                style={{ borderRadius: '50%' }}
                            />
                        </FacebookMessengerShareButton>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2}>
                    <div className={classes.buttonWrapper}>
                        <RedditShareButton
                            url={`https://anketi.bg/spodelyane/anketi/${props.id}`}
                        >
                            <RedditIcon style={{ borderRadius: '50%' }} />
                        </RedditShareButton>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2}>
                    <div className={classes.buttonWrapper}>
                        <LinkedinShareButton
                            url={`https://anketi.bg/spodelyane/anketi/${props.id}`}
                        >
                            <LinkedinIcon style={{ borderRadius: '50%' }} />
                        </LinkedinShareButton>
                    </div>
                </Grid>
            </Grid>
            <Box
                bgcolor={'black'}
                borderRadius={'4px'}
                maxWidth={'500px'}
                color='white'
                margin='auto'
                p={2}
            >
                <Typography variant='h5' component='div'>
                    Линк за споделяне
                </Typography>
                <Box my={2}>
                    <Typography>
                        <strong>
                            https://anketi.bg/spodelyane/anketi/{props.id}
                        </strong>
                    </Typography>
                </Box>
                <Box textAlign={'right'}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={copyToClipboard}
                    >
                        Копирай
                    </Button>
                </Box>
            </Box>
            <Box
                mt={2}
                bgcolor={'white'}
                borderRadius={'4px'}
                maxWidth={'500px'}
                color='white'
                margin='auto'
                p={2}
            >
                <Link
                    label={'Ембедване на анкета'}
                    to={Paths.embedEditor(id)}
                    variant='h6'
                />
            </Box>
        </Box>
    )
}
